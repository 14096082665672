//TO DO: add tracking data to headers such as UserAgent and App version info
import { CapacitorHttp, HttpHeaders, HttpOptions, HttpResponse } from "@capacitor/core";
import { getBearerToken, validateBearerToken } from "./OAuth";
import { DeviceInfo } from "@capacitor/device";
import { ErrorCodes, ErrorInfo } from "./ErrorHelper";
import { getApiOptionsData, getApiSession } from "./ApiOptionsHelper";
import { getDeviceInfo } from "./Device";
import { ApiResponse } from "../models/api/ApiResponse";
import { setRequestedURL } from "./UrlHelper";
import { initializeAbpSession } from "../api/abpLogin";

export enum HttpMethods {
    GET, POST, PUT, DELETE
}

export enum LoginType {
    Portal, EAMCore
}

export const getPreBootstrapConfig = async () => {
    try {
        const response = await fetch('/preBootstrapConfig.json');
        if (!response.ok) {
            console.log('Failed to load Pre Bootstrap configuration');
        }
        const preBootstrapConfig = await response.json();
        return preBootstrapConfig;
    } catch (error) {
        console.log('Error fetching pre bootstrap config:', error);
        return null;
    }
}

export const sendRequestWithOptions = async (httpMethod: HttpMethods, options: HttpOptions): Promise<ApiResponse> => {
    try {
        //check if abp session needs revalidation
        if (globalThis.abpRevalidateSession) {
            initializeAbpSession();
            globalThis.abpRevalidateSession = false;
        }
        //verify bearer token validty before making Api
        const isValid = await verifyTokenAndSession();
        if (!isValid) {
            console.log('Bearer token or Session license is invalid');
            throw new ErrorInfo(ErrorCodes.User_Validation_Failed, 'Bearer token or Session license is invalid, try relogin');
        }

        let httpResponse: HttpResponse;
        switch (httpMethod) {
            case HttpMethods.GET: {
                httpResponse = await CapacitorHttp.get(options);
                break;
            }
            case HttpMethods.POST: {
                httpResponse = await CapacitorHttp.post(options);
                break;
            }
            case HttpMethods.PUT: {
                httpResponse = await CapacitorHttp.put(options);
                break;
            }
            case HttpMethods.DELETE: {
                httpResponse = await CapacitorHttp.delete(options);
                break;
            }
        }
        return getApiResponse(httpResponse);
    } catch (error) {
        return getErrorResponse(error);
    }
}

export const sendRequest = async (httpMethod: HttpMethods, endpoint: string, data?: any, httpParams?: any, anonymous: boolean = false, loginType: LoginType = LoginType.EAMCore): Promise<ApiResponse> => {
    try {
        //check if abp session needs revalidation
        if (!anonymous && globalThis.abpRevalidateSession) {
            initializeAbpSession();
            globalThis.abpRevalidateSession = false;
        }

        //verify bearer token validity before making API request if it's not an anonymous API call.
        if (!anonymous) {
            const isValid = await verifyTokenAndSession();
            if (!isValid) {
                console.log('Bearer token or Session license is invalid');
                throw new ErrorInfo(ErrorCodes.User_Validation_Failed, 'Bearer token or Session license is invalid, try relogin');
            }
        }

        let httpResponse: HttpResponse;
        switch (httpMethod) {
            case HttpMethods.GET: {
                const options = {
                    headers: await getApiRequestHeaders(),
                    url: await getRequestApiUrl(endpoint, loginType),
                    params: httpParams
                };
                httpResponse = await CapacitorHttp.get(options);
                break;
            }
            case HttpMethods.POST: {
                const options: HttpOptions = {
                    headers: await getApiRequestHeaders(),
                    url: await getRequestApiUrl(endpoint, loginType),
                    data: data,
                    params: httpParams
                };
                httpResponse = await CapacitorHttp.post(options);
                break;
            }
            case HttpMethods.PUT: {
                const options: HttpOptions = {
                    headers: await getApiRequestHeaders(),
                    url: await getRequestApiUrl(endpoint, loginType),
                    data: data,
                    params: httpParams
                };
                httpResponse = await CapacitorHttp.put(options);
                break;
            }
            case HttpMethods.DELETE: {
                const options: HttpOptions = {
                    headers: await getApiRequestHeaders(),
                    url: await getRequestApiUrl(endpoint, loginType),
                    params: httpParams
                };
                httpResponse = await CapacitorHttp.delete(options);
                break;
            }
        }

        return getApiResponse(httpResponse);
    } catch (error) {
        return getErrorResponse(error);
    }
}

export async function getRequestApiUrl(endpoint: string, loginType: LoginType = LoginType.EAMCore): Promise<string> {
    //If the login type is portal, please make sure to pass the entire endpoint.
    if (loginType == LoginType.Portal) return endpoint;
    const apiSession = await getApiSession();
    const preBootstrapConfig = await getPreBootstrapConfig();
    if (apiSession.BackendApiUrl === null) {
        apiSession.BackendApiUrl = (await getApiOptionsData()).Applications[0].BackendApiUrl;
    }
    let apiUrl = (apiSession.BackendApiUrl !== null) ? apiSession.BackendApiUrl : preBootstrapConfig.API_BASE_ADDRESS;
    if (endpoint !== null) {
        apiUrl += encodeURI(endpoint);
    }
    return apiUrl; //TODO: return base address or throw exception?
}

export const getApiRequestHeaders = async (): Promise<HttpHeaders> => {
    return Promise.all([getBearerToken(), getApiSession(), createDeviceHeader()]).then((values) => {
        const bearerToken = values[0];
        const apiSession = values[1];
        const deviceHeader = values[2];
        return {
            authorization: `Bearer ${bearerToken}`,
            "x-tw-plant": apiSession?.PlantCode ?? '',
            "x-tw-database": apiSession?.DatabaseName ?? '',
            "x-tw-licsession-id": apiSession?.LicenseSessionId ?? '',
            "content-type": "application/json",
            "x-tw-device-agent": deviceHeader,
        };
    })
};

export function encodeParam(queryParam: string): string {
    try {
        if (!!queryParam) {
            return encodeURIComponent(queryParam.replaceAll("%", "%25"));
        }
    }
    catch (e) { console.log(e); }
    return queryParam;
}

export function decodeParam(queryParam: string): string {
    try {
        let p = (!!queryParam) ? queryParam.replace(/\+/g, " ") : "";
        return decodeURIComponent(p);
    }
    catch (e) { console.log(e); }
    return queryParam;
}

const getApiResponse = (response: HttpResponse) => {
    const apiResponse: ApiResponse = {
        data: response.status === 200 ? response.data : processError(response.data),
        isError: response.status !== 200,
        headers: response.headers,
        status: response.status,
        url: response.url
    };
    return apiResponse;
};

const getErrorResponse = (error: any) => {
    const errorResponse: ApiResponse = {
        data: processError(error),
        isError: true,
        status: 0
    };
    return errorResponse;
};

const processError = (errorData: any) => {
    //SessionError:true when abp session has expired
    if (!!errorData) {
        if (errorData.MessageList && errorData.MessageList.length > 0) {
            return errorData.MessageList.map((message: any) => message.Text);
        }
        else if (errorData.includes("SessionError:true")) {
            //abp session revalidation needed
            globalThis.abpRevalidateSession = true;
            return "Your session has expired. Please try again.";
        } else if ((!!errorData.Message && errorData.Message.includes("relogin")) || errorData.includes("relogin")) {
            return "Your session has expired. Please re-login.";
        }
        else {
            return !!errorData.Message ? errorData.Message : errorData;
        }
    }
};

const verifyTokenAndSession = async () => {
    //check bearer token validity
    const isTokenValid = await validateBearerToken();
    if (!isTokenValid) {
        console.log('Bearer Token is invalid, redirecting to login');
        //store the requested url before relogin
        await setRequestedURL(window.location.href);
        //redirect to b2c login
        window.location.replace("/");
    }
    // return isSessionValid;
    return isTokenValid; //skip checking session
}

const createDeviceHeader = async (): Promise<string> => {
    const deviceInfo: DeviceInfo = await getDeviceInfo();
    return `EAM Mobile/1.0 (platform=${deviceInfo?.platform}|os=${deviceInfo?.operatingSystem}|osVersion=${deviceInfo?.osVersion}|model=${deviceInfo?.model})`;
}
