import { IonLabel } from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setRequestedURL } from "../util/UrlHelper";
import { setQRPlantDB } from "../util/ApiOptionsHelper";
import { setAuthTypePreference, _iamKey } from "../util/Preferences";
const QRCodeService: React.FC = () => {

    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        console.log('URL Params: ', urlParams.toString());
        const iamParam = urlParams.get('IAM');
        iamParam && setAuthTypePreference(_iamKey, iamParam);
        updateApiOptions(urlParams).then(() => {
            //equipment
            if (urlParams.has('Equipment')) {
                const equipmentId = urlParams.get('Equipment');
                //redirect to equipment overview page
                const redirectUrl = `/equipment/${equipmentId}`;
                console.log('redirectUrl: ', redirectUrl);

                setRequestedURL(redirectUrl);
                history.push(redirectUrl);
                return;
            }
            //default path
            window.location.replace("/");
        });
    }, []);

    const updateApiOptions = async (urlParams: URLSearchParams) => {
        const ld = urlParams.get("LD");
        console.log('QRCodeService - LD: ', ld);

        if (!!ld) {
            //decode base64 string
            const plantDbString = atob(ld);
            console.log('QRCodeService - plantDbString: ', plantDbString);
            if (!!plantDbString) {
                const plantDbParams = new URLSearchParams(plantDbString);
                const dbName = plantDbParams.get("database");
                const plantCode = plantDbParams.get("plant");
                if (!!plantCode && !!dbName) {
                    await setQRPlantDB({ PlantCode: plantCode, DBName: dbName });
                }
            }
        }
    };

    return (
        <>
            (!!routeurl ? {
                <IonLabel>QR Code Service : App</IonLabel>
            } : {
                <IonLabel>QR Code Service : Web</IonLabel>
            });
        </>
    );
};

export default QRCodeService;