import {
  IonContent,
  IonPage,
} from '@ionic/react';
import React from 'react';
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../util/AppInsights";

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding">
        Page not found.
      </IonContent>
    </IonPage>
  );
};

export default  withAITracking(reactPlugin,NotFoundPage, "NotFoundPage");
