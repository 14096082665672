import {
  IonButton,
  IonIcon,
  useIonAlert,
  useIonRouter,
  useIonViewDidLeave,
} from "@ionic/react";
import { alarmOutline, alarmSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import { get, set } from "../util/Preferences";
import TimeEntry from "../pages/TimeEntry";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";

export let finalTime = 0;
let timerSet = false;

interface StartStopProps {
  timeAccess?: boolean;
}

const StartStopComponent: React.FC<StartStopProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { timeAccess } = props;
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  let [timer, setTimer] = useState<any>();
  let [hours, setHours] = useState<number>();
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);

  interface WorkOrderTimer {
    workOrderNumber: string;
    startTime: number;
  }

  const onModalCancel = () => {
    setIsModalOpen(false);
  };

  const onModalConfirm = () => {
    setIsModalOpen(false);
  };

  const getWorkOrder = async () => {
    set("WorkOrder", displayWo);
  };

  const startTimer = () => {
    console.log("Timer Started");
    getWorkOrder();
    let startingTime: WorkOrderTimer = {
      workOrderNumber: displayWo,
      startTime: Date.now(),
    };
    set("StartTime", startingTime);
    setTimer(startingTime);
  };

  const stopTimer = () => {
    presentAlert({
      header:
        translatedMessages["SubmitTime"]?.MessageText ||
        "Do you want to submit your time?",
      buttons: [
        {
          text: translations["lbl_btn_no"] || "No",
          role: "cancel",
        },
        {
          text: translations["lbl_btn_yes"] || "Yes",
          role: "confirm",
          handler: async () => {
            timerSet = false;
            let oldTime = await timer;
            let newTime: WorkOrderTimer = {
              workOrderNumber: oldTime.workOrderNumber,
              startTime: Date.now(),
            };
            set("StartTime", newTime);
            let endTime = newTime.startTime;
            // ellapsed time in seconds
            let timeInMS = Math.abs(endTime - oldTime.startTime);
            console.log("Final time (seconds): ", timeInMS / 1000);
            // seconds to minutes
            let timeInMin = Math.floor(timeInMS / 1000 / 60);
            // minutes to hours, rounded up to next quarter hour
            finalTime = ((Math.ceil(timeInMin / 15) * 15) % 60) / 60;
            if (finalTime === 0) {
              finalTime += 0.25;
            }
            setHours(finalTime);
            // Reset state of timer
            set("StartTime", "");
            setTimer(undefined);
            // Navigate to time entry page
            if (oldTime.workOrderNumber !== displayWo) {
              router.push(`/workorder/${oldTime.workOrderNumber}`);
            }
            console.log("Work Order: ", displayWo);
            setIsModalOpen(true);
          },
        },
      ],
    });
  };

  const setStartorStop = () => {
    if (timer) {
      stopTimer();
    } else {
      startTimer();
    }
  };

  useEffect(() => {
    if (timerSet === true) {
      setTimer(get("StartTime"));
    }
  }, []);

  useIonViewDidLeave(() => {
    if (timer) {
      timerSet = true;
    }
  }, [timer]);

  return (
    <>
      <IonButton
        fill="clear"
        slot="end"
        disabled={!timeAccess}
        onClick={() => setStartorStop()}
      >
        <IonIcon icon={timer ? alarmSharp : alarmOutline}></IonIcon>
      </IonButton>
      {finalTime > 0 && (
        <TimeEntry
          isOpen={isModalOpen}
          cancel={onModalCancel}
          confirm={onModalConfirm}
          finalTime={hours}
        />
      )}
    </>
  );
};

export default StartStopComponent;
