import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import "./WOApprovalModal.css";
import { Requisition, WorkOrderMetadata } from "../models/workorders/WorkOrder";
import { processRequisitions } from "../api/Requisitions";
import { checkmarkCircleOutline } from "ionicons/icons";
import ReqApprovalLogActivity from "./ReqApprovalLogActivity";

interface ReqModalProps {
  isOpen: boolean;
  cancel: () => void;
  approve: (status: string) => void;
  review: (status: string) => void;
  reject: (status: string) => void;
  cardHeader: string;
  metadata: WorkOrderMetadata;
  requisition: Requisition[];
  setRequisition: (requisition: Requisition[]) => void;
  nextReviewer: string;
  setNextReviewer: (nextReviewer: string) => void;
  setShowLoading: (showLoading: boolean) => void;
}

interface ValidValues {
  Code: string;
  Description: string;
}

const ReqApprovalModal: React.FC<ReqModalProps> = (props) => {
  const {
    isOpen,
    cancel,
    approve,
    review,
    reject,
    cardHeader,
    metadata,
    requisition,
    setRequisition,
    nextReviewer,
    setNextReviewer,
    setShowLoading,
  } = props;
  const { translations } = useContext(TranslationsContext);
  const [comments, setComments] = useState<string>("");
  const [reviewerList, setReviewerList] = useState<ValidValues[]>([]);
  const [showLogActivity, setShowLogActivity] = useState(false);
  const [lineNum, setLineNum] = useState<number>(0);
  const approveAction = "approve";
  const reviewAction = "review";
  const rejectAction = "reject";

  const validateCheckedList = async (action: string) => {
    const checkedList = requisition.filter((card) => {
      return card.checked;
    });
    if (checkedList.length === 0) {
      return false;
    }
    for (const card of checkedList) {
      processRequisition(action, nextReviewer, card.LineNum, cardHeader);
    }
  };

  const checkEmpty = () => {
    const checkedList = requisition.filter((card) => {
      return card.checked;
    });
    if (checkedList.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const processRequisition = async (
    action: string,
    nextReviewer: string,
    reqLine: number,
    requisition: string
  ) => {
    setShowLoading(true);
    const requisitionData = [];
    const data = {
      Action: action,
      Comments: comments,
      NextReviewer: nextReviewer,
      ReqLine: reqLine,
      Requisition: requisition,
    };
    requisitionData.push(data);
    processRequisitions(requisitionData).then((response) => {
      handleStatusMessage(
        action,
        response.data?.ReqInfoResult[0]?.MessageList[0]?.Text
      );
    });
  };

  const handleStatusMessage = async (action: string, status: string) => {
    if (action === approveAction) {
      return approve(status);
    }
    return action === reviewAction ? review(status) : reject(status);
  };

  const getReviewerList = async () => {
    setReviewerList(metadata?.fields["in_req_line.next_reviewer"]?.ValidValues);
  };

  const onSelect = (checked: boolean, lineNum: number) => {
    const newArray = requisition.map((card) => {
      if (card.LineNum === lineNum) {
        return {
          ...card,
          checked: checked,
        };
      }
      return card;
    });
    setRequisition(newArray);
  };

  const onSelectAllClick = () => {
    onSelectAll(requisition.map((card) => card.LineNum));
  };

  const onSelectAll = async (card: number[]) => {
    const newArray = requisition.map((entry) => {
      return { ...entry, checked: card.includes(entry.LineNum) };
    });
    setRequisition(newArray);
  };

  useEffect(() => {
    getReviewerList();
  }, []);

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {translations["lbl_mob_req_approval"] || "Requisition Approval"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">&nbsp;{cardHeader}</h3>
              </IonText>
            </IonCol>
            <IonCol size="auto">
              <IonButton fill="clear" size="small" onClick={onSelectAllClick}>
                <IonIcon
                  slot="start"
                  icon={checkmarkCircleOutline}
                  size="small"
                />
                {translations["lbl_btn_selAll"] || "Select All"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {requisition.map((card, index) => {
          const onCardSelect = (checked: boolean) => {
            onSelect(checked, card.LineNum);
          };
          return (
            <IonCard key={index}>
              <IonCardHeader>
                <IonCardTitle class="card-title">
                  <IonCheckbox
                    checked={card.checked}
                    onClick={() => {
                      onCardSelect(!card.checked);
                    }}
                  ></IonCheckbox>
                  &nbsp;&nbsp;{card.LineNum}
                </IonCardTitle>
                <IonCardSubtitle>{card.ItemDesc}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["in_req_line.quantity_requested"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.Qty}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["in_req_line.unit_cost"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.UnitCost}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields[
                        "in_v_req_computed_columns.cf_extended_cost"
                      ]?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.ExtCost}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol></IonCol>
                  <IonCol size="auto">
                    <IonButton
                      fill="clear"
                      size="small"
                      onClick={() => {
                        setLineNum(card.LineNum);
                        setShowLogActivity(true);
                      }}
                    >
                      {translations["lbl_log_activity"] || "Log Activity"}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          );
        })}
        <IonGrid class="comment-title">
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">
                  {translations["lbl_incyclecountbatch_comment"] || "Comment"}
                </h3>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonCard>
          <IonTextarea
            class="approval-textarea"
            placeholder={translations["lbl_enter comments"] || "Enter Comments"}
            autoGrow={true}
            onIonChange={(ev) => {
              setComments(String(ev.detail.value));
            }}
          ></IonTextarea>
        </IonCard>
        <IonItem>
          <IonLabel>
            {translations["lbl_inreqline_nextreviewer"] || "Next Reviewer"}
          </IonLabel>
          <IonSelect
            interface="alert"
            value={nextReviewer}
            onIonChange={(ev) => {
              setNextReviewer(ev.detail.value);
            }}
          >
            {reviewerList.map((card, index) => {
              return <IonSelectOption key={index}>{card.Code}</IonSelectOption>;
            })}
          </IonSelect>
        </IonItem>
        <IonButton
          expand="block"
          disabled={!checkEmpty()}
          onClick={() => validateCheckedList(approveAction)}
        >
          {translations["lbl_approve"] || "Approve"}
        </IonButton>
        <IonButton
          fill="outline"
          disabled={!checkEmpty()}
          expand="block"
          onClick={() => validateCheckedList(reviewAction)}
        >
          {translations["lbl_review"] || "Review"}
        </IonButton>
        <IonButton
          fill="outline"
          disabled={!checkEmpty()}
          color="danger"
          expand="block"
          onClick={() => validateCheckedList(rejectAction)}
        >
          {translations["lbl_reject"] || "Reject"}
        </IonButton>
        <ReqApprovalLogActivity
          isOpen={showLogActivity}
          dismiss={() => setShowLogActivity(false)}
          setShowLoading={setShowLoading}
          lineNum={lineNum}
          requisition={requisition}
        />
      </IonContent>
    </IonModal>
  );
};

export default ReqApprovalModal;
