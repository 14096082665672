import React, { useContext, useEffect, useState } from "react";
import { IonLabel, IonIcon, IonItem } from "@ionic/react";
import { cloudOffline } from "ionicons/icons";
import { Network } from "@capacitor/network";
import { TranslationMessagesContext } from "../util/Translations";

const OnlineStatus: React.FC = () => {
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [online, setOnline] = useState(true);

  useEffect(() => {
    //retrieve the network status on component load
    Network.getStatus().then((status) => {
      setOnline(status.connected);
      console.log("Online Status: " + status.connected);
    });
    //add event listeners for network status changes
    Network.addListener("networkStatusChange", (status) => {
      setOnline(status.connected);
      console.log("Online Status: " + status.connected);
    });
  }, [online]);

  return (
    <IonItem hidden={online} color="danger">
      <IonLabel>
        {translatedMessages["Offline"]?.MessageText ||
          "Warning: you are currently offline"}
      </IonLabel>
      <IonIcon icon={cloudOffline} />
    </IonItem>
  );
};

export default OnlineStatus;
