import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "ItemLocationBins";

export interface GetBinsOfItemStoreLocationParams {
  item: string;
  storesLocation: string;
}

export const getBinsOfItemStoreLocation = async (
  params: GetBinsOfItemStoreLocationParams
) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetBinsOfItemStoreLocation`,
    undefined,
    params
  );
};