import { useEffect, useState } from "react";
import { Requisition, WorkOrderMetadata } from "../models/workorders/WorkOrder";
import { getColumnsWithGroup } from "../api/Windows";
import "./WOApprovalModal.css";
import ApprovalLogActivityComp from "./ApprovalLogActivityComp";

interface LogActivityDisplay {
  isOpen: boolean;
  dismiss: () => void;
  setShowLoading: (showLoading: boolean) => void;
  lineNum: number;
  requisition: Requisition[];
}

const ReqApprovalLogActivity: React.FC<LogActivityDisplay> = (props) => {
  const { isOpen, dismiss, setShowLoading, lineNum, requisition } = props;
  const [metadata, setMetadata] = useState<WorkOrderMetadata>();
  const [logActivity, setLogActivity] = useState<Requisition>();

  const logActivityMetadata = async () => {
    setShowLoading(true);
    const data = {
      WindowName: "mob_wo_approvals_log",
      IncludeValidValues: true,
    };
    return getColumnsWithGroup(data).then((response) => {
      setMetadata(response.data);
      setShowLoading(false);
    });
  };

  const getReqLogActivity = async () => {
    let correctActivity = requisition.find((line) => {
      return line.LineNum === lineNum;
    });
    setLogActivity(correctActivity);
  };

  useEffect(() => {
    logActivityMetadata();
    getReqLogActivity();
  }, [lineNum]);

  return (
    <>
      {metadata && logActivity && (
        <ApprovalLogActivityComp
          isOpen={isOpen}
          dismiss={dismiss}
          activity={logActivity.lstMobileApprovalRequisitionLineComments}
          metadata={metadata}
        />
      )}
    </>
  );
};

export default ReqApprovalLogActivity;
