import { useContext, useState } from "react";
import { getEmployeesLiteList } from "../api/Employees";
import { Employee } from "../models/employees/Employees";
import {
  ItemListEntry,
  retrieveAccountSelections,
} from "../pages/MaterialIssue";
import {
  BinData,
  IssueResponseData,
  ItemLocationsGetDef,
  PlannedListItem,
} from "../models/materialissue/MaterialIssue";
import { getBinsOfItemStoreLocation } from "../api/ItemLocationBins";
import { getUserId } from "../util/UserHelper";
import { useStoresManager } from "./useStoresManager";
import { decodeParam } from "../util/ApiHelper";
import { TranslationsContext } from "../util/Translations";
import { useParams } from "react-router";
import { getItemLocations } from "../api/ItemLocations";
import { getInventoryControl } from "../api/PlantSetups";
import { useIonAlert } from "@ionic/react";

export function useIssueReturnManager() {
  const { workOrderId, requisitionId, accountSelections } = useParams<{
    workOrderId: string;
    requisitionId: string;
    accountSelections: string;
  }>();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeId, setEmployeeId] = useState<Employee>();
  const [storeLocation, setStoreLocation] = useState<string>();
  const [itemList, setItemList] = useState<ItemListEntry[]>([]);
  const [dateCharged, setDateCharged] = useState<string>(
    new Date().toISOString()
  );
  const [searchOpen, setSearchOpen] = useState(false);
  const displayWo = decodeParam(workOrderId);
  const displayReq = decodeParam(requisitionId);
  const accountObject = retrieveAccountSelections(accountSelections);
  const { translations } = useContext(TranslationsContext);
  const [showLoading, setShowLoading] = useState(false);
  const [negativeInvCtrlVal, setNegativeInvCtrlVal] = useState<string>();
  const [presentAlert] = useIonAlert();
  const { getDefaultStoreLocation } = useStoresManager();

  const setupDetails = async () => {
    const [employees, userId, defaultStore, negativeQtyMessage] =
      await Promise.all([
        getEmployees(),
        getUserId(),
        getDefaultStoreLocation(),
        getNegativeQtyOnHandMessage(),
      ]);
    setEmployees(employees || []);
    const employee = employees.find((employee) => {
      return employee.EmployeeId === userId;
    });
    employee && setEmployeeId(employee);
    defaultStore && setStoreLocation(defaultStore);
    setNegativeInvCtrlVal(negativeQtyMessage);
  };

  const getEmployees = async () => {
    const data = {
      PropertyList: ["EmployeeName", "EmployeeId"],
      Filter: "",
    };
    const response = await getEmployeesLiteList(data);
    return response.data as Employee[];
  };

  const getBinData = async (
    item: string,
    storesLocation: string
  ): Promise<BinData[]> => {
    if (!item || !storesLocation) {
      return [];
    }
    const body = {
      item,
      storesLocation,
    };
    const response = await getBinsOfItemStoreLocation(body);
    return response.data || [];
  };

  const getDefaultTransactionQuantity = (
    item: PlannedListItem,
    binData: BinData[]
  ) => {
    const binDataWithQuantity = binData.map((bin) => {
      if (bin.Bin === item.Bin) {
        return { ...bin, TransactionQuantity: item.LineQuantityRequested };
      }
      return bin;
    });
    return binDataWithQuantity;
  };

  const getNegativeQtyOnHandMessage = async () => {
    const response = await getInventoryControl();
    return response.data?.getNegativeQtyOnHandMessage as string;
  };

  const addItem = async (item: string) => {
    if (!storeLocation) {
      return;
    }
    const params = {
      bin: true,
      item,
      storesLocation: storeLocation,
    };
    setShowLoading(true);
    const response = await getItemLocations(params);
    if (response.isError) {
      presentAlert({
        message: response.data || `${translations["lbl_error"] || "Error"}`,
        buttons: [`${translations["lbl_btn_ok"] || "OK"}`],
      });
    } else {
      const newList = [...itemList, parseNewItem(response.data)];
      setItemList(newList);
    }
    setShowLoading(false);
  };

  const onItemAdd = async (newItem: string) => {
    const alreadyAdded = itemList.find((entry) => {
      return entry.item?.Item === newItem;
    });
    if (!alreadyAdded) {
      await addItem(newItem);
    }
    setSearchOpen(false);
  };

  const onItemSelect = (checked: boolean, item: string, reqLine: number) => {
    const newArray = itemList.map((entry) => {
      if (entry.item.Item === item && entry.item.RequisitionLine === reqLine) {
        return {
          ...entry,
          checked: checked,
        };
      }
      return entry;
    });
    setItemList(newArray);
  };

  const onSelectAll = (bins: string[]) => {
    const newArray = itemList.map((entry) => {
      return { ...entry, checked: bins.includes(entry.item?.Bin) };
    });
    setItemList(newArray);
  };

  const parseNewItem = (item: ItemLocationsGetDef): ItemListEntry => {
    const newPlannedItem: PlannedListItem = {
      Bin:
        item.Bin?.find((bin) => {
          return bin.BinType === "Primary";
        })?.Bin || "",
      BinQuantity: item.QtyOnHand,
      BinType: "",
      DefaultIssueQuantity: "",
      DeliveryId: 0,
      IsRepairable: item.RepairableFlag,
      IsSerialized: item.SerialTrackingFlag,
      Item: item.Item,
      ItemDescription: item.Description,
      Requisition: "",
      RequisitionLine: 0,
      StoresLocation: item.StoresLocation,
      LocationQuantityOnHand: item.QtyOnHand,
      LocationQuantityAvailable: item.QtyOnHand,
      LocationUnitCost: item.UnitCost,
      LineQuantityRequested: item.QtyInReceiving,
      LineQuantityReserved: item.QtyReserved,
      LineQuantityIssued: item.QtyIssuedOutInTransit,
      LineQuantityReturned: item.QtyTransferDueOut,
      UnitOfMeasure: item.UnitOfIssue,
      BinList: [],
      SerialIds: "",
    };
    return {
      item: newPlannedItem,
      checked: true,
      binData: item.Bin,
      issued: false,
      isAdhoc: true,
      comments: "",
    };
  };

  const onBinAmountChange = (item: string, bin: string, amount: number, reqLine: number) => {
    if (!globalThis.fromReturn && amount !== 0) {
      const newItemList = itemList.map((entry) => {
        if (entry.item?.Item === item && entry.item?.RequisitionLine === reqLine) {
          const newBinList = entry.binData?.map((binData) => {
            // empty bin value represents the total/main entry
            if (!bin) {
              return {
                ...binData,
                TransactionQuantity: binData.Bin === entry.item.Bin ? amount : 0,
              };
            } else if (binData.Bin === bin) {
              return { ...binData, TransactionQuantity: amount };
            }
            return binData;
          });
          return { ...entry, binData: newBinList, checked: true };
        }
        return entry;
      });
      setItemList(newItemList);
    }
  };

  const onResponse = (data: IssueResponseData[], newData: ItemListEntry[]) => {
    for (const response of data) {
      newData = applyStatus(response, newData);
    }
    setItemList(newData);
  };

  const getResponseErrorMessages = (response: IssueResponseData) => {
    if (response.MessageList.length === 0) {
      return undefined;
    }
    return response.MessageList.map((message) => {
      return message.Text;
    }).join(". ");
  };

  const applyStatus = (
    response: IssueResponseData,
    itemList: ItemListEntry[]
  ) => {
    const newItemList = itemList.map((entry) => {
      const isMaterialIssueId = entry.binData.find((bin) => {
        return (bin.ObjectId === response.MaterialTransactionId && entry.item?.RequisitionLine === response.ReqLine);
      });
      if (isMaterialIssueId) {
        return {
          ...entry,
          issued: response.Success,
          errorMessage: getResponseErrorMessages(response),
        };
      }
      return entry;
    });
    return newItemList;
  };

  const onComment = (comments: string, item: string) => {
    const newList = itemList.map((entry) => {
      if (entry.item?.Item === item) {
        return { ...entry, comments: comments };
      }
      return entry;
    });
    setItemList(newList);
  };

  return {
    displayWo,
    displayReq,
    accountObject,
    translations,
    showLoading,
    employees,
    employeeId,
    storeLocation,
    itemList,
    dateCharged,
    searchOpen,
    negativeInvCtrlVal,
    setShowLoading,
    setEmployeeId,
    setStoreLocation,
    setItemList,
    setDateCharged,
    setSearchOpen,
    setupDetails,
    getBinData,
    getDefaultTransactionQuantity,
    onItemSelect,
    onSelectAll,
    onBinAmountChange,
    onResponse,
    onComment,
    onItemAdd,
  };
}
