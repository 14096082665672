import React, { useEffect, useState } from 'react';
import { IonPage, IonLoading, IonContent, IonLabel } from "@ionic/react";
import { abpLogout } from "../api/abpLogin";
import { getApiSession } from "../util/ApiOptionsHelper";

const EndSession: React.FC = () => {
    const [labelText, setLabelText] = useState("Your session is being terminated...");

    useEffect(() => {
        endAbpSession();
    }, []);

    async function endAbpSession() {
        try {
            const apiSession = await getApiSession();
            if (!apiSession || !apiSession.LicenseSessionId) {
                handeSessionExpireFailure();
                return;
            }
            const response = await abpLogout();
            if (response.status === 200) 
                window.location.href = 'sessionend.html';
            else
                handeSessionExpireFailure();
        } catch (error) {
            handeSessionExpireFailure(error);
        }
    }

    function handeSessionExpireFailure(error?: any) {
        setLabelText("Failed to terminate session. Please try again.");
        console.error("ABP Logout failed:", error);
        setTimeout(() => {
            window.location.href = '/home';
        }, 3000);
    }

    return (
        <IonPage>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={true}
                backdropDismiss={false}
                duration={5000}
            />
            <IonContent className="ion-padding">
                <IonLabel>{labelText}</IonLabel>
            </IonContent>
        </IonPage>
    );
};

export default EndSession;
