import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            if (!event.url.startsWith("com.aptean.eam://oauth/auth") &&
                !event.url.startsWith("msauth.com.aptean.eam://auth")) {
                const slug = event.url.split('.com').pop();
                console.log("App URL Listner - SLUG: ", slug);
                if (slug) {
                    history.push(slug);
                }
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return (
        <>
        </>
    );
};

export default AppUrlListener;