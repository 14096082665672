import React, { useEffect } from 'react';
import { clearAuthData, signout, signoutPortal } from "../util/OAuth";
import { abpLogout } from "../api/abpLogin";
import { getApiSession } from "../util/ApiOptionsHelper";
const FrontChannelLogout: React.FC = () => {
    useEffect(() => {
        logout();
    }, []);

    async function logout() {
        try {
            globalThis.relogin = false;
            const apiSession = await getApiSession();
            if (apiSession && apiSession.LicenseSessionId) {
                await abpLogout();
            }
            if (!globalThis.relogin) {
                await signoutPortal();
            }
            localStorage.setItem('frontchannellogout', 'true');
            await clearAuthData();
        } catch (error) {
            console.error("Logout failed:", error);
        }
    }

    return (
        <div>
            <p>Logging out...</p>
        </div>
    );
};

export default FrontChannelLogout;