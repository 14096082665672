import { useEffect, useState } from "react";
import {
  WorkOrderActivity,
  WorkOrderMetadata,
} from "../models/workorders/WorkOrder";
import { getColumnsWithGroup } from "../api/Windows";
import "./WOApprovalModal.css";
import ApprovalLogActivityComp from "./ApprovalLogActivityComp";

interface LogActivityDisplay {
  isOpen: boolean;
  dismiss: () => void;
  setShowLoading: (showLoading: boolean) => void;
  woActivity: WorkOrderActivity[];
  description: string;
}

const WOApprovalLogActivity: React.FC<LogActivityDisplay> = (props) => {
  const { isOpen, dismiss, setShowLoading, woActivity } = props;
  const [metadata, setMetadata] = useState<WorkOrderMetadata>();

  const logActivityMetadata = async () => {
    setShowLoading(true);
    const data = {
      WindowName: "mob_wo_approvals_log",
      IncludeValidValues: true,
    };
    return getColumnsWithGroup(data).then((response) => {
      setMetadata(response.data);
      setShowLoading(false);
    });
  };

  useEffect(() => {
    logActivityMetadata();
  }, []);

  return (
    <>
      {metadata && (
        <ApprovalLogActivityComp
          isOpen={isOpen}
          dismiss={dismiss}
          activity={woActivity}
          metadata={metadata}
        />
      )}
    </>
  );
};

export default WOApprovalLogActivity;
