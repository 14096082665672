import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "Requisitions";

interface RequisitionData {
  requisition: string;
  delivery: boolean;
  lines: boolean;
}

interface ProcessRequisitionData {
  Action: string;
  Comments?: string;
  NextReviewer: string;
  ReqLine: number;
  Requisition: string;
}

export const findRequisitions = async (searchTerm: string) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Find`,
    undefined,
    { searchTerm: searchTerm }
  );
};

export const getRequisition = async (data: RequisitionData) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetRequisition`,
    undefined,
    {
      requisition: data.requisition,
      delivery: data.delivery,
      lines: data.lines,
    }
  );
};

export const processRequisitions = async (data: ProcessRequisitionData[]) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/ProcessRequisitions`,
    data
  );
};
