import { useContext, useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonLoading,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { TranslationsContext } from "../util/Translations";
import { getUserSetupData, saveUserValue } from "../api/UserSetups";
import { GetUserSetupDataType } from "../models/usersetups/UserSetups";
const DefaultStoreLocation: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const [showLoading, setShowLoading] = useState(false);
  const [storeLocations, setStoreLocations] = useState<string[]>();
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [loadingDefault, setLoadingDefault] = useState(true);

  useEffect(() => {
    getFields();
  }, []);

  const getSetupData = async () => {
    const response = await getUserSetupData();
    return response.data as GetUserSetupDataType;
  };

  const getFields = async () => {
    setShowLoading(true);
    setLoadingDefault(true);
    const response = await getSetupData();
    if (response.UserLocations) {
      setStoreLocations(response.UserLocations);
    }
    if (response.UserSetupValues?.find) {
      const defaultLocation = response.UserSetupValues.find((setupValue) => {
        return setupValue.UserParameter === "stores_location";
      });
      setCurrentLocation(defaultLocation?.UserValue);
    }
    setLoadingDefault(false);
    setShowLoading(false);
  };

  const onLocationChange = async (store: string) => {
    const data = {
      UserParameter: "stores_location",
      UserSection: "Inventory",
      UserValue: store,
    };
    setShowLoading(true);
    await saveUserValue(data);
    setShowLoading(false);
  };

  const handleStoreSelect = (store?: string) => {
    if (store && !loadingDefault) {
      setCurrentLocation(store);
      onLocationChange(store);
    }
  };

  return (
    <IonItem>
      <IonLoading isOpen={showLoading} />
      <IonLabel position="stacked">
        {translations["lbl_def_str_loc"] || "Default Store Location"}
      </IonLabel>
      <IonSelect
        interface="alert"
        value={currentLocation}
        onIonChange={(ev) => {
          handleStoreSelect(ev.detail.value);
        }}
      >
        {storeLocations?.map((store) => {
          return (
            <IonSelectOption key={store} value={store}>
              {store}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};
export default DefaultStoreLocation;
