import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { help, settings } from "ionicons/icons";
import { Redirect, Route } from "react-router-dom";
import Settings from "./Settings";
import Support from "./Support";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../util/AppInsights";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

const SettingsTabs: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  return (
    <IonTabs>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{translations["lbl_settings"] || "Settings"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonRouterOutlet id="settings">
        <Route exact path="/Settings">
          <Redirect to="/Settings/settings" />
        </Route>
        <Route path="/Settings/settings">
          <Settings />
        </Route>
        <Route path="/Settings/support">
          <Support />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="settings" href="/Settings/settings">
          <IonIcon icon={settings} />
          <IonLabel>{translations["lbl_settings"] || "Settings"}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="support" href="/Settings/support">
          <IonIcon icon={help} />
          <IonLabel>{translations["lbl_support"] || "Support"}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default  withAITracking(reactPlugin,SettingsTabs, "SettingsTabs");
