import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { useContext } from "react";
import { ThemeContext } from "../util/ThemeManager";
import { ThemePreference } from "../models/common/ThemePreference";
import { TranslationsContext } from "../util/Translations";

const ThemeSelector: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { themePreference, setThemePreference } = useContext(ThemeContext);

  return (
    <IonItem>
      <IonLabel position="stacked">{`${
        translations["lbl_theme"] || "Theme"
      }:`}</IonLabel>
      <IonSelect
        interface="popover"
        value={themePreference}
        onIonChange={(event) => {
          if (!setThemePreference) return;
          setThemePreference(event.detail.value);
        }}
      >
        <IonSelectOption value={ThemePreference.default}>
          {translations["lbl_system_default"] || "System Default"}
        </IonSelectOption>
        <IonSelectOption value={ThemePreference.light}>
          {translations["lbl_light"] || "Light"}
        </IonSelectOption>
        <IonSelectOption value={ThemePreference.dark}>
          {translations["lbl_dark"] || "Dark"}
        </IonSelectOption>
      </IonSelect>
    </IonItem>
  );
};

export default ThemeSelector;
