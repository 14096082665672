import { IVersionInfo } from "./IVersionInfo";
import appData from "./app-info.json";
import { IAppInfo } from "./IAppInfo";
import { EAMError, ErrorHandler } from "../util/ErrorHandler";
import { ErrorCodes } from "../util/ErrorHelper";

/**
 * Class which returns the application information
 */
export class AppInfo implements IAppInfo {
  private appInfo: typeof appData;

  constructor(info?: typeof appData) {
    this.appInfo = info ?? this.setAppData();
  }

  getBuildInfo(): IVersionInfo {
    return this.appInfo.versionInfo;
  }

  private setAppData(): typeof appData {
    try {
      return appData;
    } catch (e: any) {
      //fail gracefully since we are on mobile
      let er = new EAMError(ErrorCodes.Application_Info_Retrieval_Failed, e);
      er.message = "Application Info Retrieval Failed";
      ErrorHandler.handleError(er, false);
      return {
        versionInfo: {
          build: 0,
          major: 0,
          minor: 0,
          patch: 0,
          date: 0,
        },
      };
    }
  }
}
