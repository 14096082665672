import { IonPage } from "@ionic/react";
import PageHeader from "../components/PageHeader";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import SingleQueryPage from "../components/SingleQueryPage";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

const MyInspections: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  return (
    <IonPage>
      <PageHeader title={translations["lbl_my_inspections"] || "My Inspections"} />
      <SingleQueryPage queryName="My Inspections" />
    </IonPage>
  );
};

export default withAITracking(reactPlugin, MyInspections, "My Inspections");
