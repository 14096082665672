import { IonPage } from "@ionic/react";
import React, { useContext } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import PageHeader from "../components/PageHeader";
import EquipmentSearch from "../components/EquipmentSearch";
import { TranslationsContext } from "../util/Translations";

const Equipment: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  return (
    <IonPage>
      <PageHeader
        title={translations["lbl_category_equipment"] || "Equipment"}
      />
      <EquipmentSearch />
    </IonPage>
  );
};

export default withAITracking(reactPlugin, Equipment, "Equipment");
