import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "InspectionResults";

export const getInspectionResults = async (filterString: string) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/Get`,
        {},
        {
            filter: filterString
        }
    );
};

export interface GetInspectionResultsFullHierarchyParams {
    inspectionId: string;
    resultId: string;
};

export const getInspectionResultsFullHierarchy = async (params: GetInspectionResultsFullHierarchyParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetFullHierarchy`,
        undefined,
        {
            inspectionId: params.inspectionId,
            resultId: params.resultId,
        }
    );
};

export const completeInspectionResults = async (data: any) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/Complete`,
        data,
        {}
    );
};
