import { useQueryExec } from "../hooks/useQueryExec";
import { IonItem, IonLabel } from "@ionic/react";
import DefaultQueries from "./DefaultQueries";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

const DefaultInventoryQuery: React.FC = () => {
  const { getQueryList, queryList } = useQueryExec("Item", "Item", "item");
  const querySection = "QW--Item";
  const { translations } = useContext(TranslationsContext);

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">
          {`${
            translations["lbl_def_item_query"] || "Default Inventory Query"
          }:`}
        </IonLabel>
        <DefaultQueries
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
        />
      </IonItem>
    </>
  );
};
export default DefaultInventoryQuery;
