import {RemarkPrimaryKey} from "./RemarkPrimaryKey";
import {RemarkSubmissionData} from "./RemarkSubmissionData";

/**
 * A object representing an EAM remark/comment api request submission data
 */
export class RemarkSubmission{
    constructor(dateCreated:string, timeCreated:string) {
        if (!dateCreated || !timeCreated) {
            throw new Error('Invalid dataCreated or timeCreated parameters');
        }
        this.PrimaryKey = new RemarkPrimaryKey(dateCreated, timeCreated);
    }

    /**
     * The primary key of the remark to be updated/created
     */
    public PrimaryKey:RemarkPrimaryKey;
    /**
     * The remarks to be created
     */
    public readonly Updates: RemarkSubmissionData[] = [];
}