import {IRemarkPrimaryKey} from "./IRemarkPrimaryKey";

/**
 * A object representing an EAM remark/comment compound primary key
 */
export class RemarkPrimaryKey implements IRemarkPrimaryKey {
    /**
     * A string representing the date the remark was created.
     * <comment>This date must be the same as the work order created date</comment>
     */
    readonly DateCreated: string
    /**
     * A string representing the time the remark was created.
     * <comment>This time must be the same as the work order created time</comment>
     */
    readonly TimeCreated: string

    /**
     * Creates a new instance RemarkPrimaryKey
     * @param dateCreated The work order created date
     * @param timeCreated The work order created time
     */
    constructor(dateCreated: string, timeCreated: string) {
        if (!dateCreated || !timeCreated) {
            throw new Error('Invalid dateCreated or timeCreated parameters')
        }
        this.DateCreated = dateCreated;
        this.TimeCreated = timeCreated;
    }
}