import { IonItem, IonInput, IonIcon } from "@ionic/react";
import { useContext } from "react";
import { filterCircleOutline, filterCircleSharp } from "ionicons/icons";
import { QueryResult } from "../hooks/useQueryExec";
import { TranslationsContext } from "../util/Translations";

interface FilterCardData {
  hideFilter?: boolean;
  queryRows: QueryResult[];
  resultsFilter: string;
  filterMethod?: (query: string) => Promise<void>;
}

const CardFilter: React.FC<FilterCardData> = (props) => {
  const { hideFilter, queryRows, filterMethod, resultsFilter } = props;
  const { translations } = useContext(TranslationsContext);
  
  const onFilterChange = async (event: any) => {
    if (filterMethod) { filterMethod(String(event.target.value))};
  };

  return (
    <IonItem hidden={!!hideFilter || queryRows.length === 0}>
      <IonInput
        type="text"
        clearInput={true}
        debounce={800}
        class="ion-text-begin"
        placeholder={translations["lbl_filter"] || "Filter"}
        onIonChange={(ev) => onFilterChange(ev)}
        value={resultsFilter}
      ></IonInput>
      <IonIcon
        slot="end"
        ios={filterCircleOutline}
        md={filterCircleSharp}
      ></IonIcon>
    </IonItem>
  );
};

export default CardFilter;
