import {
  AnalysisCode,
  CascadeValue,
  CompletionFieldDef,
  ValidValue,
  WorkOrderEvents,
} from "../models/analysiscodes/AnalysisCodes";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import { getEvents, updateEvents } from "./Events";
import { getColumnsWithGroup, getCompleteColumnDetailList } from "./Windows";
import { getCascadeValues } from "./Validations";
import { getOptionByIdWithCORP } from "./TabwareOptions";

export const getCCAFieldDefinitions = async (equipment?: string) => {
  const data = {
    WindowName: "mob_completion_cca",
    IncludeValidValues: "true",
    IncludeValidValuesExceptions: [],
    ValidValueFilters: {
      Equipment: equipment || "",
      Plant: await getCurrentPlant(),
    },
  }
  const response = await getColumnsWithGroup(data);
  const componentField: CompletionFieldDef | undefined =
    response.data?.fields?.["eq_events_completion.component"];
  const conditionField: CompletionFieldDef | undefined =
    response.data?.fields?.["eq_events_completion.condition"];
  const actionField: CompletionFieldDef | undefined =
    response.data?.fields?.["eq_events_completion.action"];
  return {
    componentField,
    conditionField,
    actionField,
  };
};

export const getMappingSource = async () => {
  const response = await getOptionByIdWithCORP("CCAMappingSource");
  const relationship = response.data?.PlantValue || response.data?.DefaultValue;
  return relationship as string | undefined;
};

export const getCCACascadeValues = async () => {
  const response = await getCascadeValues();
  const cascadeValues: CascadeValue[] = response.data;
  const componentCascadeValues =
    cascadeValues?.filter((validValue) => {
      return validValue.ParentField === "EquipmentClass";
    }) || [];
  const conditionCascadeValues =
    cascadeValues?.filter((validValue) => {
      return validValue.ParentField === "Component";
    }) || [];
  const actionCascadeValues =
    cascadeValues?.filter((validValue) => {
      return validValue.ParentField === "Condition";
    }) || [];
  return {
    componentCascadeValues,
    conditionCascadeValues,
    actionCascadeValues,
  };
};

export const getCompleteColumnDetails = async (equipment?: string) => {
  const response = await getCompleteColumnDetailList({
    TableName: "eq_events_completion",
    IncludeValidValues: "true"
  });
  const component = response.data?.Component?.ValidValues as ValidValue[];
  const condition = response.data?.Condition?.ValidValues as ValidValue[];
  const action = response.data?.Action?.ValidValues as ValidValue[];
  return {
    component: component,
    condition: condition,
    action: action,
  };
};

export const getExistingCodes = async (
  dateCreated: string,
  timeCreated: string
) => {
  const response = await getEvents({
    comment: false,
    completion: true,
    dateCreated: dateCreated,
    timeCreated: timeCreated,
  });
  return response.data?.Completion as AnalysisCode[] | undefined;
};

export const submitCodes = async (
  codes: AnalysisCode[],
  event?: WorkOrderEvents
) => {
  const data = {
    RequestObject: {
      ...event,
      Completion: codes,
    },
  };
  return await updateEvents(data);
};
