import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";
import "./SingleQueryPage.css";
import { useQueryExec } from "../hooks/useQueryExec";
import WorkOrderResults from "./WorkOrderResults";
interface SingleQueryPageProps {
  queryName: string;
}

const SingleQueryPage: React.FC<SingleQueryPageProps> = (props) => {
  const { queryName } = props;
  const {
    showLoading,
    setShowLoading,
    queryRows,
    executeSearch,
    errorState,
    totalCount,
    getNoOfFieldsToDisplay,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Work Order", "Work Order", "cf_work_order", true);

  const [inRefresh, setInRefresh] = useState(false);
  const [searchYn, setSearchYn] = useState(false);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setInRefresh(true);
    event.detail.complete();
  }

  useEffect(() => {
    setShowLoading(true);
    getNoOfFieldsToDisplay("EqNoOfFieldsToDisplay").finally(() => {
      setSearchYn(true);
      setShowLoading(false);
    });
  }, [queryName]);

  useEffect(() => {
    if (inRefresh) {
      setShowLoading(true);
      executeSearch(queryName).finally(() => {
        setInRefresh(false);
        setShowLoading(false);
      });
    }
  }, [inRefresh]);

  return (
    <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        duration={5000}
        onDidDismiss={() => setShowLoading(false)}
      />
      <WorkOrderResults
        searchYn={searchYn}
        setSearchYn={setSearchYn}
        hideFilter={true}
        queryName={queryName}
        queryRows={queryRows}
        executeSearch={executeSearch}
        getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
        errorState={errorState}
        totalCount={totalCount}
        showLoading={showLoading}
        setShowLoading={setShowLoading}
        filterMethod={execFilter}
        filterString={resultsFilter}
        filterRows={filterRows}
      />
    </IonContent>
  );
};

export default SingleQueryPage;
