import { IonFooter, IonLabel, IonToolbar } from "@ionic/react";
import { QueryResult, maxResults } from "../hooks/useQueryExec";

const SearchFooter: React.FC<{totalRows: number, filterRows: QueryResult[]}> = (props) => {
    const {totalRows, filterRows} = props;
    return (
        <IonFooter translucent={true} hidden={totalRows === 0}>
            <IonToolbar>
                <IonLabel style={{ paddingLeft: 8 }}>Displaying {Math.min(filterRows.length, maxResults)} of {totalRows}</IonLabel>
            </IonToolbar>
        </IonFooter>
    )
};

export default SearchFooter;