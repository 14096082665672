import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonAlert,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeParam } from "../util/ApiHelper";
import { getTimeEntryAccess, getUserId } from "../util/UserHelper";
import "./TableListItem.css";
import "./LaborActivityTab.css";
import { add } from "ionicons/icons";
import TimeEntry from "../pages/TimeEntry";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { deleteLabor, getLaborsByWorkOrder } from "../api/Labors";
import { getColumnsWithGroup } from "../api/Windows";

const LaborActivityTab: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const [refreshState, setRefreshState] = useState(false); // Used to trigger the after log deletion so that the tab refreshes
  let [employee, setEmployee] = useState<string>("");
  const getEmployee = async () => {
    return await getUserId().then((response) => {
      if (response !== undefined) {
        setEmployee(response!);
      }
    });
  };
  const [laborLogs, setLaborLogs] = useState<any[]>([]);
  const [laborLogsErrorState, setLaborLogsErrorState] = useState(false);
  const [laborLogFields, setLaborLogFields] = useState<any[]>([]);
  const [laborLogFieldsErrorState, setLaborLogFieldsErrorState] =
    useState(false);

  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [commentModalText, setCommentModalText] = useState("");

  const [actionSheetVisible, setActionSheetVisible] = useState(false);
  const [actionSheetLaborLog, setActionSheetLaborLog] = useState<any>("");
  const [presentAlert] = useIonAlert();
  const [objectID, setObjectID] = useState<string>("");
  const [pathId, setPathId] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [inRefresh, setInRefresh] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [timeAccess, setTimeAccess] = useState(false);

  const getTimeAccess = async () => {
    const userAccess = await getTimeEntryAccess();
    setTimeAccess(userAccess);
  };

  const onModalCancel = () => {
    setIsModalOpen(false);
  };
  const onModalConfirm = () => {
    setIsModalOpen(false);
    laborLogQuery();
    setRefreshState(true);
  };

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setInRefresh(true);
    event.detail.complete();
  }

  const createRequestData = async () => {
    const data = {
      IncludeValidValues: "false",
      IncludeValidValuesExceptions: [],
      IsReadOnly: "true",
      ValidValueFilters: [],
      WindowName: "mob_wo_labor",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: "",
        WoTask: "  ",
        WoSubtask: "  ",
      },
    };
    return data;
  };

  const laborLogQuery = async () => {
    setShowLoading(true);
    // Get work order's labor activity
    await getLaborsByWorkOrder({
      woNumber: displayWo,
      appName: "Mobile",
      translations: true,
    }).then((response) => {
      if (response.status !== 200) {
        setLaborLogsErrorState(true);
      } else if (response.data) {
        let data = response.data;
        let query = [] as any[];
        for (let i = data.length - 1; i >= 0; i--) {
          query.push(data[i]);
        }
        setLaborLogs(query);
      } else {
        setLaborLogs([]);
      }
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
      setRefreshState(false);
    });
  };

  useEffect(() => {
    getEmployee();
    setLaborLogFields([]);
    setLaborLogFieldsErrorState(false);
    setActionSheetVisible(false);
    setActionSheetLaborLog("");
    getTimeAccess();

    // Get groups
    createRequestData().then((data) => {
      getColumnsWithGroup(data).then((response) => {
        if (response.status !== 200) {
          setLaborLogFieldsErrorState(true);
        } else if (response.data) {
          let data = response.data;
          if (data.fields && Object.keys(data.fields).length > 0) {
            let fields = [] as any[];
            const keys = Object.keys(data.fields);
            keys.forEach((key) => {
              if (key.includes("wo_log_labor.") && key !== "wo_log_labor.wo_base") {
                fields.push(data.fields[key]);
              }
            });
            fields.sort((a, b) => {
              return Number(a.FieldOrder) - Number(b.FieldOrder);
            });
            setLaborLogFields(fields);
          }
        }
      });
    });

    setLaborLogs([]);
    setLaborLogsErrorState(false);
    laborLogQuery();
  }, [workOrderId, employee, inRefresh, refreshState]);

  if (laborLogsErrorState) {
    return (
      <>
        <IonLabel color="danger">
          {translatedMessages["ErrFetchingWOLabor"]?.MessageText ||
            "Error fetching work order labor activity"}
        </IonLabel>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </>
    );
  }

  if (laborLogFieldsErrorState) {
    return (
      <IonLabel color="danger">
        {translatedMessages["ErrFetchingLabor"]?.MessageText ||
          "Error fetching the labor activity fields"}
      </IonLabel>
    );
  }

  const deleteLaborLog = async () => {
    let data = {
      RequestObject: actionSheetLaborLog,
      Confirmation: {},
    };
    setShowLoading(true);
    await deleteLabor(data).then((response) => {
      if (
        response.status !== 200 ||
        (response.data && response.data.Success === false)
      ) {
        setShowLoading(false);
        presentAlert({
          message:
            translatedMessages["ErrDel"]?.MessageText || "Deletion failed",
          buttons: [
            {
              text: translations["lbl_btn_ok"] || "OK",
              handler: () => {
                setActionSheetVisible(false);
                setActionSheetLaborLog("");
              },
            },
          ],
        });
      } else if (response.data && response.data.Success === true) {
        setShowLoading(false);
        presentAlert({
          message: `${
            translatedMessages["TimeEntryDeleteSuccess"]?.MessageText ||
            "Time Entry deleted successfully."
          }`,
          buttons: [
            {
              text: translations["lbl_btn_ok"] || "OK",
              handler: () => {
                setActionSheetVisible(false);
                setActionSheetLaborLog("");
                setRefreshState(true);
              },
            },
          ],
        });
      }
    });
  };

  const createGridColumn = (laborLog: any, laborField: any) => {
    let label = laborField.TranslatedIdText;
    let value = laborLog[laborField.PropertyName];
    const handleComments = () => {
      return (
        <IonInput
          class="list-item-field-input"
          readonly
          value={
            laborField.NetType === "DateTime"
              ? new Date(value).toLocaleDateString()
              : value
          }
        ></IonInput>
      );
    };

    return (
      <IonCol size="12" sizeMd="6">
        <IonItem class="list-item-grid-col-item" lines="none">
          <>
            <IonLabel class="list-item-field-label">{label}</IonLabel>
            {handleComments()}
          </>
        </IonItem>
      </IonCol>
    );
  };

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {(objectID || buttonClicked) && (
        <TimeEntry
          isOpen={isModalOpen}
          cancel={onModalCancel}
          confirm={onModalConfirm}
          objectID={objectID}
          pathId=""
        />
      )}
      {laborLogs.length === 0 ? (
        <IonLabel
          hidden={showLoading}
          color={"warning"}
          style={{ padding: "16px", display: "inline-block" }}
        >
          {translatedMessages["NoResultsFound"]?.MessageText ||
            "No results found."}
        </IonLabel>
      ) : (
        <IonList lines="full" class="table-list">
          {laborLogs.map((laborLog: any, index) => {
            return (
              <IonItem
                lines="full"
                class="list-item"
                key={`${index}-${laborLog}`}
              >
                <IonGrid
                  class="list-item-grid"
                  key={index}
                  onClick={() => {
                    setActionSheetLaborLog(laborLog);
                    setActionSheetVisible(true);
                  }}
                >
                  {laborLogFields.map((laborField: any, index: number, arr) => {
                    // Skip the odd numbers to avoid duplicate columns
                    if (index % 2 === 1) {
                      return <></>;
                    }
                    //skip comments column
                    if (laborField.PropertyName === "Comments") {
                      return <></>;
                    }
                    return (
                      <IonRow
                        key={`${index}-${laborField}-${arr}`}
                        class="table-list-item-row"
                      >
                        {createGridColumn(laborLog, laborField)}
                        {index + 1 !== arr.length &&
                          createGridColumn(laborLog, arr[index + 1])}
                      </IonRow>
                    );
                  })}
                </IonGrid>
              </IonItem>
            );
          })}
        </IonList>
      )}
      <IonModal isOpen={commentModalVisible}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {translations["lbl_category_comments"] || "Comments"}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setCommentModalVisible(false);
                  setCommentModalText("");
                }}
              >
                {translations["lbl_btn_close"] || "Close"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <IonTextarea
            class="labor-textarea"
            readonly
            autoGrow
            value={commentModalText}
          ></IonTextarea>
        </IonContent>
      </IonModal>
      <IonActionSheet
        isOpen={actionSheetVisible}
        onDidDismiss={() => {
          setActionSheetVisible(false);
          setActionSheetLaborLog("");
        }}
        buttons={[
          {
            text: translations["lbl_category_comments"] || "Comments",
            cssClass:
              actionSheetLaborLog.Comments === null
                ? "disable-action-sheet-button"
                : "",
            role: "view",
            handler() {
              setCommentModalText(actionSheetLaborLog.Comments);
              setCommentModalVisible(true);
            },
          },
          {
            text: translations["lbl_btn_edit"] || "Edit",
            handler: () => {
              setObjectID(actionSheetLaborLog.ObjectId);
              setIsModalOpen(true);
            },
          },
          {
            text: translations["lbl_btn_delete"] || "Delete",
            role: "destructive",
            handler: () => {
              presentAlert({
                message:
                  translatedMessages["DeleteLabor"]?.MessageText ||
                  "Are you sure you want to delete this labor log?",
                buttons: [
                  {
                    text: translations["lbl_btn_cancel"] || "Cancel",
                    role: "cancel",
                  },
                  {
                    text: translations["lbl_btn_delete"] || "Delete",
                    role: "destructive",
                    handler: () => {
                      deleteLaborLog();
                    },
                  },
                ],
              });
            },
          },
          {
            text: translations["lbl_btn_cancel"] || "Cancel",
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton
          disabled={!timeAccess}
          onClick={() => {
            setButtonClicked(true);
            setObjectID("");
            setIsModalOpen(true);
            setPathId("")
          }}
        >
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default LaborActivityTab;
