import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "InspectionDefinitions";

export interface GetInspectionDefFullHierarchyParams {
    inspectionId: string;
    includeWoDetails?: boolean;
    includeEqDetails?: boolean;
};

export const getInspectionDefFullHierarchy = async (params: GetInspectionDefFullHierarchyParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetFullHierarchy`,
        undefined,
        {
            inspectionId: params.inspectionId,
            includeWoDetails: params.includeWoDetails ? "true" : "false",
            includeEqDetails: params.includeEqDetails ? "true" : "false"
        }
    );
};