import { useContext, useEffect, useState } from "react";
import { IonLoading, IonSelect, IonSelectOption } from "@ionic/react";
import { getUserSetupValues, putUserSetupValue } from "../api/UserSetups";
import { TranslationsContext } from "../util/Translations";

export interface QueryParams {
  getQueryList: () => void;
  queryList: string[];
  querySection: string;
}

const DefaultWOQuery: React.FC<QueryParams> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { getQueryList, queryList, querySection } = props;
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [loadingDefault, setLoadingDefault] = useState(true);
  const startup = "startup query";

  const retrieveDefaultQuery = async () => {
    setShowLoading(true);
    setLoadingDefault(true);
    const data = {
      Section: querySection,
      ParameterList: [startup],
    };
    const response = await getUserSetupValues(data);
    if (
      response.data &&
      response.data[startup] &&
      response.data[startup].includes("--")
    ) {
      let query = response.data[startup].split("--");
      setCurrentQuery(query[1]);
    } else {
      setCurrentQuery(response.data[startup]);
    }
    setLoadingDefault(false);
    setShowLoading(false);
  };

  const changeDefaultQuery = async (query: string) => {
    setShowLoading(true);
    let httpParams = {
      userParameter: startup,
      userSection: querySection,
      userValue:
        query && query.includes("Public--") ? "Public--" + query : query,
    };
    await putUserSetupValue(httpParams);
    setShowLoading(false);
  };

  const handleQuerySelect = (queryName?: string) => {
    if (queryName && !loadingDefault) {
      setCurrentQuery(queryName);
      changeDefaultQuery(queryName);
    }
  };

  useEffect(() => {
    setShowLoading(true);
    Promise.all([getQueryList(), retrieveDefaultQuery()]).finally(() => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonSelect
        interface="alert"
        placeholder={translations["lbl_btn_select"] || "Select"}
        value={currentQuery}
        onIonChange={(ev) => {
          handleQuerySelect(ev.detail.value);
        }}
      >
        {queryList.map((qryName, index) => {
          return (
            <IonSelectOption key={`${index}-${qryName}`} value={qryName}>
              {qryName}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </>
  );
};
export default DefaultWOQuery;
