import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonModal,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { maxResults, useQueryExec } from "../hooks/useQueryExec";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { useContext } from "react";
import QueryFilter from "./QueryFilter";
import QueryResults from "./QueryResults";

interface FilterModalProps {
  isOpen: boolean;
  moduleName: string;
  cancel: () => void;
  confirm: (selectedItem: string) => void;
}

const FilterModal: React.FC<FilterModalProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { isOpen, moduleName, cancel, confirm } = props;
  let querySection = `QW--${moduleName}`;
  let toastTranslation =
    moduleName !== ""
      ? Object.keys(translations)[
          Object.values(translations).indexOf(moduleName)
        ]
      : "";

  const getKeyFieldName = (moduleName: string) => {
    if (moduleName === "Work Order") {
      return "cf_work_order";
    }
    if (moduleName === "Resource") {
      return "employee_id";
    }
    return moduleName.toLowerCase();
  };

  const {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    queryRows,
    executeSearch,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    getNoOfFieldsToDisplay,
    showLoading,
    setShowLoading,
    showToast,
    setShowToast,
    searchYn,
    setSearchYn,
    queryName,
    criteriaParams,
    confirmSearch,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec(moduleName, moduleName, getKeyFieldName(moduleName));

  const onCardSelect = (cardHeaderVal: string) => {
    confirm(cardHeaderVal);
  };

  return (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={cancel}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>{moduleName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={2000}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={
            translatedMessages["DisplayFirst"]?.MessageText.replace(
              "@1",
              maxResults
            )
              .replace(
                "@2",
                translations[toastTranslation] || moduleName.toLowerCase()
              )
              .replace("@3", totalCount) ||
            `Displaying the first ${maxResults} requisitions of ${totalCount}.`
          }
          duration={2000}
          position="top"
        />
        <QueryFilter
          setShowLoading={setShowLoading}
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
          getFilterParams={getFilterParams}
          filterParams={filterParams}
          setParameter={setParameter}
          queryCriteria={queryCriteria}
          getQueryCriteria={getQueryCriteria}
          searchCallback={confirmSearch}
          filterModalInvalid={true}
        />
        <QueryResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          queryParams={filterParams}
          criteria={criteriaParams}
          queryRows={queryRows}
          filterParams={filterParams}
          setParameter={setParameter}
          executeSearch={executeSearch}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          cardClick={onCardSelect}
          filterRows={filterRows}
          filterMethod={execFilter}
          filterString={resultsFilter}
        />
      </IonContent>
    </IonModal>
  );
};

export default FilterModal;
