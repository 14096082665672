import { HttpMethods, sendRequest } from "../util/ApiHelper"

const CONTROLLER_NAME = "CycleCountBatchs";

export interface CountEntryRequestData {
    AssignedTo: any;
    BatchId: number;
    Bin: string;
    Comments: string;
    CountId: number;
    CountResult: number;
    CountedBy: any;
    CountedQty: string;
    DatetimeCounted: string;
    Description: string;
    GroupNumber: number;
    Item: string;
    NextCountAssignedTo: any;
    NextCountDate: string;
    ObjectId: string;
    Plant: string;
    RowVersion: any;
    SerialTrackingFlag: boolean;
    SourceTableName: string;
    StoresLocation: string;
    SuperseededFlag: boolean;
    SystemQty: number;
    Variance: number;
    expanded?: boolean;
    commentModal?: boolean;
}

export interface CountEntryOfItemData {
    RequestObject: CountEntryRequestData[],
    Confirmation: any;
}

export const countEntryOfItems = async (data: CountEntryOfItemData) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/CountEntryofItems`,
        data
    )
}