import {
  IonCard,
  IonCardContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import {
  getFieldsByGroup,
  WorkOrderGroup,
  WorkOrder,
  formatData,
  getWorkOrderGroups,
  WorkOrderMetadata,
  getAllFields,
} from "../models/workorders/WorkOrder";
import { decodeParam } from "../util/ApiHelper";
import OnlineStatus from "../components/OnlineStatus";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import ApiError from "../components/ApiError";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import {
  WorkOrderParams,
  getWorkOrder,
  getUdvList,
  getCustomElementColumnDetailList,
} from "../api/WorkOrders";
import { TranslationMessagesContext } from "../util/Translations";

const WorkOrderDetails: React.FC = () => {
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  let isRequester = useLocation().pathname.includes("requester");
  const [groups, setGroups] = useState<WorkOrderGroup[]>([]);
  const [metadata, setMetadata] = useState<WorkOrderMetadata>();
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [woToSerial, setWoToSerial] = useState<string>();
  const EAMDefaultDate = "0001-01-01T00:00:00";
  const [errorData, setErrorData] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const [customValues, setCustomValues] = useState<any>([]);
  const [customValErrorState, setCustomValErrorState] = useState(false);
  const [customFields, setCustomFields] = useState<any>({});
  const [customFieldsErrorState, setCustomFieldsErrorState] = useState(false);

  const metadataQuery = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: isRequester ? "mob_requester_wo_nb" : "mob_wo_nb",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: workOrder?.WoBase,
        WoTask: workOrder?.WoTask,
        WoSubtask: workOrder?.WoSubtask,
      },
    };
    getColumnsWithGroup(data).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
      } else {
        setMetadata(response.data);
        setGroups(getWorkOrderGroups(response.data));
      }
    });
  };

  const workOrderQuery = async () => {
    const woParams: WorkOrderParams = {
      woNumber: displayWo,
      equipmentMaster : true
    };
    getWorkOrder(woParams).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
      } else {
        setWorkOrder(response.data);
        if (response.data.WriteWoToSerialFlag === true) {
          setWoToSerial("yes");
        } else {
          setWoToSerial("no");
        }
      }
    });
  };

  const customValuesQuery = () => {
    return getUdvList({ woNumber: displayWo }).then((response) => {
      if (response.status !== 200) {
        setCustomValues([]);
        setCustomValErrorState(true);
      } else if (response.data) {
        setCustomValues(response.data);
        setCustomValErrorState(false);
      }
    });
  };

  const customFieldsQuery = () => {
    return getCustomElementColumnDetailList().then((response) => {
      if (response.status !== 200) {
        setCustomFields({});
        setCustomFieldsErrorState(true);
      } else if (response.data) {
        setCustomFields(response.data);
        setCustomFieldsErrorState(false);
      }
    });
  };

  useEffect(() => {
    setShowLoading(true);
    // Reset data when switching work orders.
    setGroups([]);
    setMetadata(undefined);
    //setWorkOrder(undefined);
    // Reset custom vals when switching work orders
    setCustomValues([]);
    setCustomValErrorState(false);

    setCustomFields({});
    setCustomFieldsErrorState(false);

    // Show loading symbol
    setShowLoading(true);

    // Run api calls to get metadata and work order.
    metadataQuery().then(() => {
      workOrderQuery().then(() => {
        // Get Custom values info
        customValuesQuery().then(() => {
          // Get custom fields info
          customFieldsQuery().then(() => {
            // Hide loading symbol once all the calls are done
            setShowLoading(false);
          });
        });
      });
    });
  }, [workOrderId, woToSerial]);

  let customFieldsValues = [] as any[];

  if (Object.keys(customFields).length > 0) {
    customFieldsValues = Object.values(customFields);
  }

  return (
    <>
      <IonHeader>
        <OnlineStatus />
      </IonHeader>
      <ApiError errorData={errorData} />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <>
        {!!metadata &&
          !!workOrder &&
          groups.length > 0 &&
          groups.map((group) => {
            const fields = getFieldsByGroup(metadata, group.GroupName);
            return (
              fields.length > 0 && (
                <IonCard key={group.GroupName + String(group.GroupOrder)}>
                  <IonCardContent>
                    <IonList lines="full">
                      {fields.map((field) => {
                        //The PropertyName of the field is a key of the work order.
                        switch (field.TableName) {
                          case 'eq_locations':
                            if (field.PropertyName === 'Description') {
                              field.PropertyName = 'EquipmentDescription';
                            }
                            break;
                          case 'eq_serial':
                            if (field.PropertyName === 'Description') {
                              field.PropertyName = 'SerialDescription';
                            }
                            break;
                          default:
                            break;
                        }
                        const data =
                          workOrder[field.PropertyName as keyof WorkOrder];
                        return (
                          <IonItem key={field.IdText}>
                            <IonLabel>
                              <p>{field.TranslatedIdText}</p>
                              {field.NetType === "DateTime" &&
                              data === EAMDefaultDate ? (
                                <></>
                              ) : field.PropertyName ===
                                "WriteWoToSerialFlag" ? (
                                <h3>{woToSerial}</h3>
                              ) : (
                                <h3>{formatData(data, field)}</h3>
                              )}
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              )
            );
          })}
        {/* In the case where there are no groups, show all fields on one card. */}
        {!!metadata && !!workOrder && groups.length === 0 && (
          <IonCard>
            <IonCardContent>
              <IonList lines="full">
                {getAllFields(metadata).map((field) => {
                  const data = workOrder[field.PropertyName as keyof WorkOrder];
                  return (
                    <IonItem key={field.IdText}>
                      <IonLabel>
                        <p>{field.TranslatedIdText}</p>
                        {field.NetType === "DateTime" &&
                        data === EAMDefaultDate ? (
                          <></>
                        ) : field.PropertyName === "WriteWoToSerialFlag" ? (
                          <h3>{woToSerial}</h3>
                        ) : (
                          <h3>{formatData(data, field)}</h3>
                        )}
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            </IonCardContent>
          </IonCard>
        )}
        {/* Card containing custom fields */}
        <IonCard
          hidden={
            (!customFieldsErrorState || !customValErrorState) &&
            (!customFieldsValues || customFieldsValues.length < 1)
          }
        >
          <IonCardContent>
            {customFieldsErrorState ? (
              <IonText
                color={"danger"}
                style={{ padding: "16px", display: "inline-block" }}
              >
                {translatedMessages["ErrCustomFields"]?.MessageText ||
                  "Error retrieving custom fields"}
              </IonText>
            ) : (
              <IonList>
                {customValErrorState && (
                  <IonItem lines="none" key={`custom-tab-error-item`}>
                    <IonText color={"danger"} key={"custom-tab-error-text"}>
                      {translatedMessages["ErrCustomValues"]?.MessageText ||
                        "Error fetching custom values"}
                    </IonText>
                  </IonItem>
                )}
                {customFieldsValues
                  .sort((a, b) => {
                    return (
                      customFieldsValues.indexOf(a) -
                      customFieldsValues.indexOf(b)
                    );
                  })
                  .map((customField) => {
                    let valueOfField = "" as string | number;
                    if (customValues && customValues.length > 0) {
                      let matchingValue = customValues.filter((val: any) => {
                        return val.UdId === customField.UdId;
                      });
                      if (matchingValue.length > 0) {
                        valueOfField =
                          matchingValue[0][customField.PropertyName];
                      }
                    }
                    return (
                      <IonItem
                        lines="full"
                        key={`custom-field-${customField.IdText}`}
                      >
                        <IonLabel
                          key={`custom-field-${customField.IdText}-input-label`}
                        >
                          <p>{customField.TranslatedIdText}</p>
                          <h6>
                            {customField.PropertyName === "DateValue" &&
                            valueOfField !== ""
                              ? new Date(valueOfField).toLocaleDateString()
                              : String(valueOfField)}
                          </h6>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonList>
            )}
          </IonCardContent>
        </IonCard>
      </>
    </>
  );
};

export default withAITracking(
  reactPlugin,
  WorkOrderDetails,
  "WorkOrderDetails"
);
