import { Preferences } from '@capacitor/preferences';
import {ThemePreference} from "../models/common/ThemePreference";

export const set = async (key: string, value: any) => {
  await Preferences.set({
    key: key,
    value: JSON.stringify(value)
  });
};

export const setAuthTypePreference = async (key: string, value: string) => {
  await Preferences.set({
    key: key,
    value: value
  });
};

export const getAuthTypePreference = async (key: string) => {
  const value = await Preferences.get({ key: key });
  if (!value) return undefined;
  return value;
};


export const get = async (key: string) => {
  const { value } = await Preferences.get({ key: key });

  if (!value) {
    return undefined;   // Change this to some sort of error later
  }
  return JSON.parse(value);
};

export const remove = async (key: string) => {
  await Preferences.remove({ key: key });
};

// Will remove EVERYTHING from Preferences
export const clearAll = async () => {
  await Preferences.clear();
};

export const getThemePreference = async () => {
  const theme = await Preferences.get({ key: _themeKey });
  if(theme != null && theme.value != null) {
    return JSON.parse(theme.value) as ThemePreference;
  }
  return ThemePreference.default;
}

export const setThemePreference = async (themePreference: ThemePreference) => {
  return await Preferences.set({ key: _themeKey, value: JSON.stringify(themePreference) });
}

// These are the keys used to store data in Preferences
const _themeKey = "themePreference";
export const _iamKey = "IAM";
