import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { alertCircle } from "ionicons/icons";
import { useEffect, useState } from "react";

interface ApiErrorProps {
    errorData: any;
    showRelogin?: boolean;
};

const ApiError: React.FC<ApiErrorProps> = (props) => {
    const { errorData, showRelogin } = props;
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        setErrorText(errorData);
    }, [errorData]);

    const onReLogin = async () => {
        setErrorText("");
        //revalidate user session
        window.location.href = "/";
    };

    return (!!errorText ? (
        <>
            <IonItem color="danger">
                <IonIcon icon={alertCircle}></IonIcon>
                <IonLabel class="ion-text-wrap">{errorText?.toString()}</IonLabel>
                <IonButton onClick={onReLogin} hidden={!showRelogin}>Retry Login</IonButton>
            </IonItem>
        </>
    ) : (
        <></>
    ));
};

export default ApiError;