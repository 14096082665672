import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonInput, IonButton, IonPage, IonGrid, IonRow, IonCol, IonLoading, useIonAlert, IonToolbar, IonTitle, IonHeader } from '@ionic/react';
import { getUserIdentityProvider } from "../api/IAM";
import { _iamKey, setAuthTypePreference, getAuthTypePreference } from '../util/Preferences';
import { TranslationMessagesContext, TranslationsContext } from "../util/Translations";

const EmailForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [showLoading, setShowLoading] = useState(false);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { translations } = useContext(TranslationsContext);
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  useEffect(() => {
    checkIAMQueryParam();
    checkIAMCacheExists();
  }, []);


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) return;
    setShowLoading(true);
    let response = await getUserIdentityProvider(email);
    if (!response.data?.success) {
      setShowLoading(false);
      const strings: string[] = response.data?.messageList?.map(
        (message: { text: string }) => {
          return message.text;
        }
      );
      presentAlert({
        message: strings.join(" "),
        buttons: [
          {
            text: translations["lbl_btn_ok"] || "OK"
          },
        ],
      });
      return;
    }
    let iamType = response?.data?.user?.iamProvider === 1 ? 'ApteanIAM' : 'B2C';
    //This will handle taking the user through B2C/IAM authentication.
    setAuthTypePreference(_iamKey, iamType);
    history.push(`/AuthCallback?email=${encodeURIComponent(email)}`);
  };

  const checkIAMQueryParam = () => {
    let url = new URL(window.location.href),
      searchParams = new URLSearchParams(url.search),
      iamParam = searchParams.get('IAM');

    if (iamParam) {
      setAuthTypePreference(_iamKey, iamParam);
      window.location.href = "/AuthCallback";
    }
  };

  const checkIAMCacheExists = () => {
    getAuthTypePreference(_iamKey).then(iamType => {
      let iamValue = iamType?.value;
      if (iamValue) {
        console.log('Entered authback after logout');
        window.location.href = "/AuthCallback";
      }
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>EAM Mobile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={`${translatedMessages["ValUser"]?.MessageText || "Validating user"
          }...`}
        duration={15000}
      />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" size-md="6">
              <form onSubmit={handleSubmit}>
                <IonInput
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onIonChange={(ev) => {
                    setEmail(ev.detail.value || '');
                  }}
                  style={{ marginBottom: '20px' }}
                />
                <IonButton expand="block" type="submit">{translations["lbl_btn_next"] || "Next"}</IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>

  );
};

export default EmailForm;
