import {
  IonBadge,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  RefresherEventDetail,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import { appsSharp } from "ionicons/icons";
import WorkOrderActionBar from "../components/WorkOrderActionBar";
import "./WorkOrderOverview.css";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import PageHeader from "../components/PageHeader";
import { TranslationsContext } from "../util/Translations";
import { WorkOrder } from "../models/workorders/WorkOrder";
import {
  getCCAAccess,
  getInspectionAccess,
  getIssueFromTechAccess,
  getMeterReadingAccess,
  getRemarksAccess,
  getReturnFromTechAccess,
  getTimeEntryAccess,
} from "../util/UserHelper";
import {
  WorkOrderParams,
  getLinkedDocInfoList,
  getWorkOrder,
} from "../api/WorkOrders";
import { getMeters } from "../api/Meters";
import ApiError from "../components/ApiError";
import WOContent from "../components/WOContent";

const WorkOrderOverview: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [orderDate, setOrderDate] = useState<any>();
  const [orderTime, setOrderTime] = useState<any>();
  const [permitsCount, setPermitsCount] = useState(0);
  const [resourcesCount, setResourcesCount] = useState(0);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [laborCount, setLaborCount] = useState(0);
  const [meterCount, setMeterCount] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [equipSerial, setEquipSerial] = useState<string>("");
  const [isSerial, setIsSerial] = useState<string>("");
  const [actionSheetVisible, setActionSheetVisible] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [actionSheetSections, setActionSheetSections] = useState({
    Comment: false,
    TimeEntry: false,
    Meter: false,
    Inspections: false,
    CCA: false,
    IssueMaterial: false,
    ReturnMaterial: false,
  });
  const [altBackUrl, setAltBackUrl] = useState<string>();

  let inRefresh = false;

  const history = useHistory();

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    inRefresh = true;
    loadData();
    event?.detail.complete();
    inRefresh = false;
    setRefreshState(false);
  };

  useEffect(() => {
    let prevState = history.location.state as any;
    if (
      !!prevState?.prevUrl &&
      prevState?.prevUrl.includes("quick-work-order") &&
      prevState?.prevUrl.includes("create")
    ) {
      setAltBackUrl("/quick-work-order/equipment");
    } else if (!!altBackUrl) {
      setAltBackUrl(undefined);
    }
    setShowLoading(true);
    loadData().then(() => {
      setShowLoading(false);
      setRefreshState(false);
    });
  }, [workOrderId, refreshState, equipSerial]);

  const loadData = async () => {
    loadWoDocuments();
    await loadWoDetails();
    if (equipSerial && isSerial) {
      await loadWoMeters();
    }
    loadCompWizardSections();
  };

  const loadWoDetails = async () => {
    const woParams: WorkOrderParams = {
      woNumber: displayWo,
      equipmentMaster: true,
      permits: true,
      resources: true,
      comment: true,
      events: true,
      inspectionDefinition: false,
      labor: true,
    };
    //init all counts
    setPermitsCount(0);
    setResourcesCount(0);
    setLaborCount(0);
    await getWorkOrder(woParams).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
      } else {
        setWorkOrder(response.data);
        if (!!response.data.Labor) {
          setLaborCount(response.data.Labor.length);
        }
        if (response.data.Events) {
          setOrderDate(response.data.Events?.DateCreated);
          setOrderTime(response.data.Events?.TimeCreated);
        }
        if (!!response.data.Permits) {
          setPermitsCount(response.data.Permits.length);
        }
        if (!!response.data.Resources) {
          setResourcesCount(response.data.Resources.length);
        }
        setEquipSerial(response.data.Equipment);
        if (response.data.WriteWoToSerialFlag === true) {
          setIsSerial("s");
        } else {
          setIsSerial("e");
        }
      }
    });
  };

  const loadWoDocuments = async () => {
    setDocumentsCount(0);
    await getLinkedDocInfoList(displayWo).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
      } else if (response.data) {
        setDocumentsCount(response.data.length);
      }
    });
  };

  const loadWoMeters = async () => {
    setMeterCount(0);
    const meterData = {
      equipSerialId: equipSerial,
      equipSerialInd: isSerial,
    };
    await getMeters(meterData).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
      } else if (response.data) {
        setMeterCount(response.data?.length);
      }
    });
  };

  const loadCompWizardSections = async () => {
    const enabledItems = {
      Comment: false,
      TimeEntry: false,
      Meter: false,
      Inspections: false,
      CCA: false,
      IssueMaterial: false,
      ReturnMaterial: false,
    };
    enabledItems.Comment = await getRemarksAccess();
    enabledItems.TimeEntry = await getTimeEntryAccess();
    enabledItems.Meter = await getMeterReadingAccess();
    enabledItems.Inspections = await getInspectionAccess();
    enabledItems.CCA = await getCCAAccess();
    enabledItems.IssueMaterial = await getIssueFromTechAccess();
    enabledItems.ReturnMaterial = await getReturnFromTechAccess();

    setActionSheetSections(enabledItems);
  };

  return (
    <IonPage>
      <PageHeader
        title={displayWo}
        showTimer={true}
        timeAccess={actionSheetSections.TimeEntry}
        showBack={true}
        altBackUrl={altBackUrl}
      />
      <ApiError errorData={errorData} />
      <IonContent fullscreen>
        <WOContent
          handleRefresh={handleRefresh}
          inRefresh={inRefresh}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          workOrder={workOrder}
        />
        <IonList lines="full">
          {/* Add a routerLink prop to these IonItems to route to the proper component */}
          <IonItem
            routerLink={`/workorder/${encodeParam(displayWo)}/details`}
            detail
          >
            {translations["lbl_grp_wo_details"] || "Work Order Details"}
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/long-description`}
          >
            <IonLabel>
              {translations["lbl_grp_long_description"] || "Long Description"}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/resources`}
          >
            <IonLabel>{translations["lbl_resources"] || "Resources"}</IonLabel>
            <IonBadge slot="end">
              {resourcesCount > 0 ? resourcesCount : ""}
            </IonBadge>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/instructions`}
          >
            <IonLabel>
              {translations["lbl_category_instructions"] || "Instructions"}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/permits`}
          >
            <IonLabel>
              {translations["lbl_category_permits"] || "Permits"}
            </IonLabel>
            <IonBadge slot="end">
              {permitsCount > 0 ? permitsCount : ""}
            </IonBadge>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            disabled={!actionSheetSections.Comment}
            routerLink={`/workorder/${encodeParam(
              displayWo
            )}/remarks/${encodeParam(orderDate)}/${encodeParam(orderTime)}`}
          >
            <IonLabel>
              {translations["lbl_category_comments"] || "Comments"}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/documents`}
          >
            <IonLabel>{translations["lbl_documents"] || "Documents"}</IonLabel>
            <IonBadge slot="end">
              {documentsCount > 0 ? documentsCount : ""}
            </IonBadge>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/labor-activity`}
          >
            <IonLabel>
              {translations["lbl_category_laboractivity"] || "Labor Activity"}
            </IonLabel>
            <IonBadge slot="end">{laborCount > 0 ? laborCount : ""}</IonBadge>
          </IonItem>
          <IonItem
            disabled={meterCount === 0 || !actionSheetSections.Meter}
            button
            detail
            lines="full"
            routerLink={`/workorder/${encodeParam(displayWo)}/meters`}
          >
            <IonLabel>{translations["lbl_category_meter"] || "Meter"}</IonLabel>
            <IonBadge slot="end">
              {meterCount > 0 && actionSheetSections.Meter ? meterCount : ""}
            </IonBadge>
          </IonItem>
          <IonItem
            button
            detail
            disabled={!actionSheetSections.CCA}
            routerLink={`/workorder/${encodeParam(displayWo)}/analysiscodes`}
          >
            <IonLabel>
              {translations["lbl_analysis_codes"] || "Analysis Codes"}
            </IonLabel>
          </IonItem>
        </IonList>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton
            onClick={() => {
              setActionSheetVisible(true);
            }}
          >
            <IonIcon icon={appsSharp}></IonIcon>
          </IonFabButton>
        </IonFab>
        <WorkOrderActionBar
          isOpen={actionSheetVisible}
          woNumber={displayWo}
          woDate={orderDate}
          woTime={orderTime}
          inspectionId={workOrder?.InspectionId}
          dismiss={() => setActionSheetVisible(false)}
          openLoading={() => setShowLoading(true)}
          closeLoading={() => setShowLoading(false)}
          refreshScreen={() => setRefreshState(true)}
          meterCount={meterCount}
          sectionDisplay={actionSheetSections}
        />
      </IonContent>
    </IonPage>
  );
};

export default withAITracking(
  reactPlugin,
  WorkOrderOverview,
  "WorkOrderOverview"
);
