import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonBackButton,
  IonButtons,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useLocation, useParams } from "react-router-dom";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import "./TabPage.css";

interface TabPageProps {
  content?: any;
  title?: string;
  altHeaderTitle?: string;
  route?: string;
}

const TabPage: React.FC<TabPageProps> = (props) => {
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const location = useLocation();
  const { title, content, altHeaderTitle } = props;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`${location.pathname.includes("requester") ? "/requester" : ""}/workorder/${encodeParam(displayWo)}`}
              icon={chevronBackOutline}
            />
          </IonButtons>
          <IonTitle class="tab-page-title">
            {altHeaderTitle ? altHeaderTitle : displayWo} - {title || ""}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>{content}</IonContent>
    </IonPage>
  );
};

export default TabPage;
