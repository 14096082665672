import {
  IonActionSheet,
  IonCard,
  IonCardContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { add } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getExistingCodes,
  getCCAFieldDefinitions,
  getMappingSource,
  getCompleteColumnDetails,
  submitCodes,
} from "../api/AnalysisCodes";
import {
  AnalysisCode,
  CompletionFieldDef,
  ValidValue,
  WorkOrderEvents,
} from "../models/analysiscodes/AnalysisCodes";
import { decodeParam } from "../util/ApiHelper";
import "./AnalysisCodes.css";
import AnalysisCodesModal from "./AnalysisCodesModal";
import { ApiResponse } from "../models/api/ApiResponse";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { WorkOrder } from "../models/workorders/WorkOrder";
import { getWorkOrder } from "../api/WorkOrders";
import ApiError from "./ApiError";

const AnalysisCodes: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  const displayWo = decodeParam(workOrderId);
  const [present] = useIonToast();
  const [analysisCodes, setAnalysisCodes] = useState<AnalysisCode[]>([]);
  const [analysisCodeForEdit, setAnalysisCodeForEdit] =
    useState<AnalysisCode>();
  const [selectedCode, setSelectedCode] = useState<AnalysisCode>();
  const [componentField, setComponentField] = useState<CompletionFieldDef>();
  const [conditionField, setConditionField] = useState<CompletionFieldDef>();
  const [actionField, setActionField] = useState<CompletionFieldDef>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [workOrderEvents, setWorkOrderEvents] = useState<WorkOrderEvents>();
  const [componentValues, setComponentValues] = useState<ValidValue[]>([]);
  const [conditionValues, setConditionValues] = useState<ValidValue[]>([]);
  const [actionValues, setActionValues] = useState<ValidValue[]>([]);
  const [errorData, setErrorData] = useState("");
  const [actionSheetVisible, setActionSheetVisible] = useState(false);
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (displayWo) {
      setup(displayWo);
    }
  }, [displayWo]);

  const setup = async (workOrderId: string) => {
    setShowLoading(true);

    const woResponse = await getWorkOrder({
      woNumber: workOrderId,
      events: true,
      equipmentMaster: true,
    });
    if (woResponse.isError) {
      setErrorData(woResponse.data);
    } else {
      const workOrder = woResponse.data as WorkOrder;
      const events = workOrder?.Events;
      setWorkOrderEvents(events);

      const fields = await getCCAFieldDefinitions(workOrder?.Equipment);
      !!fields?.componentField && setComponentField(fields.componentField);
      !!fields?.conditionField && setConditionField(fields.conditionField);
      !!fields?.actionField && setActionField(fields.actionField);

      const mappingSource = await getMappingSource();
      const isNewRelationship =
        !!mappingSource && mappingSource === "NewRelationship";

      let componentValues = fields?.componentField?.ValidValues;
      let conditionValues = fields?.conditionField?.ValidValues;
      let actionValues = fields?.actionField?.ValidValues;
      if (!isNewRelationship && workOrder?.EquipmentClass) {
        const oldRelColumns = await getCompleteColumnDetails(
          workOrder?.Equipment
        );
        componentValues = oldRelColumns?.component;
        conditionValues = oldRelColumns?.condition;
        actionValues = oldRelColumns?.action;
      }
      setComponentValues(componentValues || []);
      setConditionValues(conditionValues || []);
      setActionValues(actionValues || []);

      if (events?.DateCreated && events?.TimeCreated) {
        const existingCodes = await getExistingCodes(
          events.DateCreated,
          events.TimeCreated
        );
        !!existingCodes && setAnalysisCodes(existingCodes);
      }
    }
    setShowLoading(false);
  };

  const getCodes = async () => {
    const dateCreated = workOrderEvents?.DateCreated;
    const timeCreated = workOrderEvents?.TimeCreated;
    if (dateCreated && timeCreated) {
      setShowLoading(true);
      const existingCodes = await getExistingCodes(dateCreated, timeCreated);
      setAnalysisCodes(existingCodes || []);
      setShowLoading(false);
    }
  };

  const onAdd = () => {
    setAnalysisCodeForEdit(undefined);
    setIsModalOpen(true);
  };

  const onEdit = (code?: AnalysisCode) => {
    if (!code) {
      return;
    }
    setAnalysisCodeForEdit(code);
    setIsModalOpen(true);
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setAnalysisCodeForEdit(undefined);
  };

  const onDelete = async (codeToDelete?: AnalysisCode) => {
    if (!codeToDelete) {
      return;
    }
    const newArray = analysisCodes.filter((code) => {
      return (
        code.Component !== codeToDelete.Component ||
        code.Condition !== codeToDelete.Condition ||
        code.Action !== codeToDelete.Action
      );
    });
    await submitCodes(newArray, workOrderEvents);
    await getCodes();
  };

  const onSave = async (analysisCode?: AnalysisCode) => {
    if (!analysisCode) {
      return;
    }
    let payload: AnalysisCode[];
    if (!analysisCodeForEdit) {
      payload = [
        ...analysisCodes,
        {
          ...analysisCode,
          TimeCreated: workOrderEvents?.TimeCreated,
          DateCreated: workOrderEvents?.DateCreated,
          Plant: workOrderEvents?.Plant,
        },
      ];
    } else {
      payload = analysisCodes.map((code) => {
        if (
          code.Component === analysisCodeForEdit?.Component &&
          code.Condition === analysisCodeForEdit?.Condition &&
          code.Action === analysisCodeForEdit?.Action
        ) {
          return analysisCode;
        }
        return code;
      });
    }
    setShowLoading(true);
    const response = await submitCodes(payload, workOrderEvents);
    setShowLoading(false);
    if (!response.data.Success) {
      handleApiFailResponse(response);
    } else {
      await getCodes();
      setIsModalOpen(false);
      setAnalysisCodeForEdit(undefined);
    }
  };

  const handleApiFailResponse = (response: ApiResponse) => {
    if (!response.data?.Success) {
      const strings: string[] = response.data?.MessageList?.map(
        (message: { Text: string }) => {
          return message.Text;
        }
      );
      present({ message: strings.join(" "), color: "danger", duration: 5000 });
    }
  };

  const getValidValueDetails = (codes: ValidValue[], code: string) => {
    return codes.find((value) => {
      return value.Code === code;
    });
  };
  const getDescription = (
    code: string,
    field: "component" | "condition" | "action"
  ) => {
    let codes: ValidValue[];
    switch (field) {
      case "component":
        codes = componentValues;
        break;
      case "condition":
        codes = conditionValues;
        break;
      case "action":
        codes = actionValues;
        break;
      default:
        codes = [];
        break;
    }
    const validValue = getValidValueDetails(codes, code);
    if (validValue?.Description) {
      return ` - ${validValue?.Description}`;
    }
    return "";
  };

  return (
    <>
      <ApiError errorData={errorData} />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonFab slot="fixed" horizontal="end" vertical="bottom">
        <IonFabButton onClick={onAdd}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      {analysisCodes.map((code, index) => {
        return (
          <IonCard
            key={`${displayWo}-analysis-code-${index}`}
            onClick={() => {
              setSelectedCode(code);
              setActionSheetVisible(true);
            }}
          >
            <IonCardContent class="analysis-code-card-content">
              <IonList lines="full">
                {code.Component && (
                  <IonItem>
                    <IonLabel>
                      <p>{componentField?.TranslatedIdText || "Component"}</p>
                      <h6>
                        {code.Component}
                        {getDescription(code.Component, "component")}
                      </h6>
                    </IonLabel>
                  </IonItem>
                )}
                {code.Condition && (
                  <IonItem>
                    <IonLabel>
                      <p>{conditionField?.TranslatedIdText || "Condition"}</p>
                      <h6>
                        {code.Condition}
                        {getDescription(code.Condition, "condition")}
                      </h6>
                    </IonLabel>
                  </IonItem>
                )}
                {code.Action && (
                  <IonItem>
                    <IonLabel>
                      <p>{actionField?.TranslatedIdText || "Action"}</p>
                      <h6>
                        {code.Action}
                        {getDescription(code.Action, "action")}
                      </h6>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonCardContent>
          </IonCard>
        );
      })}
      <IonActionSheet
        isOpen={actionSheetVisible}
        onDidDismiss={() => {
          setSelectedCode(undefined);
          setActionSheetVisible(false);
        }}
        buttons={[
          {
            text: translations["lbl_btn_edit"] || "Edit",
            handler: () => {
              onEdit(selectedCode);
            },
          },
          {
            text: translations["lbl_btn_delete"] || "Delete",
            role: "destructive",
            handler: () => {
              presentAlert({
                message:
                  translatedMessages["ConfirmDelete"]?.MessageText.replace(
                    "@1",
                    selectedCode?.Component
                  ) ||
                  `Are you sure you want to delete ${selectedCode?.Component}?`,
                buttons: [
                  {
                    text: translations["lbl_btn_cancel"] || "Cancel",
                    role: "cancel",
                  },
                  {
                    text: translations["lbl_btn_delete"] || "Delete",
                    role: "destructive",
                    handler: () => {
                      onDelete(selectedCode);
                    },
                  },
                ],
              });
            },
          },
          {
            text: translations["lbl_btn_cancel"] || "Cancel",
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <AnalysisCodesModal
        isOpen={isModalOpen}
        analysisCodeForEdit={analysisCodeForEdit}
        analysisCodes={analysisCodes}
        onCancel={onCancel}
        onSave={onSave}
      />
    </>
  );
};

export default AnalysisCodes;
