import {
  IonButton,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  useIonRouter,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import CardExpandableHeader from "../components/CardExpandableHeader";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import { getColumnsWithGroup } from "../api/Windows";
import { encodeParam } from "../util/ApiHelper";
import { StoresProps } from "../models/stores/Stores";

const StoresAccount: React.FC<StoresProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { expanded, setExpanded, storesTab } = props;
  const router = useIonRouter();
  const [metadataError, setMetadataError] = useState(false);
  const [metadata, setMetadata] = useState<any[]>([]);
  const [values, setValues] = useState<any>({});
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const createRequestData = async () => {
    const data = {
      IncludeValidValues: true,
      IncludeValidValuesExceptions: [],
      IsReadOnly: true,
      ValidValueFilters: [],
      WindowName: "mob_inventory_issue_acct",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: "",
        WoTask: "  ",
        WoSubtask: "  ",
      },
    };
    return data;
  };

  useEffect(() => {
    createRequestData().then((data) => {
      getColumnsWithGroup(data).then((response) => {
        if (response.status !== 200) {
          setMetadataError(true);
        } else if (response.data) {
          // Set metadata and values
          let fields = [] as any[];
          let values = {} as any;
          let keys = Object.keys(response.data.fields);
          keys.forEach((key) => {
            fields.push(response.data.fields[key]);
            let propName = response.data.fields[key].PropertyName;
            values[propName] = "";
          });
          fields.sort((a, b) => {
            return Number(a.FieldOrder) - Number(b.FieldOrder);
          });
          setMetadata(fields);
          setValues(values);
        }
      });
    });
  }, []);

  const onIssueClick = () => {
    let issueDetails = "";
    let keyVals = Object.entries(values);
    keyVals.forEach((innerArray) => {
      if (
        innerArray.length > 1 &&
        innerArray[1] !== "" &&
        innerArray[1] !== null &&
        innerArray[1] !== undefined
      ) {
        issueDetails = `${issueDetails.length > 0 ? `${issueDetails}+` : ""}${
          innerArray[0]
        }+${innerArray[1]}`;
      }
    });
    router.push(
      `/Stores/Accounts/${encodeParam(issueDetails)}/${storesTab}`
    );
  };

  return (
    <IonCard>
      <CardExpandableHeader
        expanded={expanded}
        onExpandClick={handleExpand}
        title={translations["lbl_account"] || "Account"}
      />
      <IonCardContent hidden={!expanded} style={{ paddingTop: 0 }}>
        {metadataError && (
          <IonLabel color="danger">
            {translatedMessages["ErrRetrievingFields"]?.MessageText ||
              "Error retrieving the fields for the form"}
          </IonLabel>
        )}
        <IonList>
          {metadata.map((field, index) => {
            let currVal = values[field.PropertyName];
            let selectVal = field.ValidValues?.find((validVal: any) => {
              return validVal.Code === currVal;
            });
            return (
              <IonItem key={`stores-account-${index}`}>
                <IonLabel position="floating">
                  {field.TranslatedIdText}
                </IonLabel>
                <IonSelect
                  interface="popover"
                  value={currVal}
                  selectedText={!!selectVal ? selectVal.Description : ""}
                  placeholder={translations["lbl_btn_select"] || "Select"}
                  onIonChange={(ev: any) => {
                    let temp = { ...values };
                    temp[field.PropertyName] = ev.detail.value;
                    setValues(temp);
                    field.PropertyName !== "Project" &&
                      setButtonDisabled(false); //Enable search only when an Account/Area/Department is selected.
                  }}
                >
                  {field.ValidValues?.map((value: any) => {
                    return (
                      <IonSelectOption key={value.Code} value={value.Code}>
                        {value.Description}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            );
          })}
          <IonButton
            color="primary"
            disabled={buttonDisabled}
            expand="full"
            onClick={onIssueClick}
          >
            {translations["lbl_btn_next"] || "Next"}
          </IonButton>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default StoresAccount;
