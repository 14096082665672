import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonViewDidLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import {
  GetColumnsWithGroupField,
  GetColumnsWithGroupResponse,
} from "../models/windows/GetColumnsWithGroup";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { transferBin } from "../api/Materials";
import { useStoresManager } from "../hooks/useStoresManager";
import {
  barcodeOutline,
  barcodeSharp,
  chevronBackOutline,
} from "ionicons/icons";
import {
  performBarCodeShimProcess,
  findObjectByElementType,
} from "../util/BarCodeHelper";
import { useBarcodeManager } from "../hooks/useBarcodeManager";

const BinTransfer: React.FC = () => {
  const {
    item,
    description,
    unitOfIssue,
    binInfo,
    results,
    multiWarehouseCheck,
    storeLocation,
    itemFromInventory,
    setStoreLocation,
    checkMultiWarehouseOption,
    getDefaultStoreLocation,
    onStoreLocationSelect,
    getGeneralInfo,
    getSearchParamters,
    handleItemClick,
    resetFields,
  } = useStoresManager();
  const [presentAlert] = useIonAlert();
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [showLoading, setShowLoading] = useState(false);
  const { scanText, setScanText, scanClick } = useBarcodeManager();
  const [storeLocationField, setStoreLocationField] =
    useState<GetColumnsWithGroupField>();
  const [itemField, setItemField] = useState<GetColumnsWithGroupField>();
  const [binTransferColumns, setBinTransferColumns] =
    useState<GetColumnsWithGroupResponse>();
  const [sourceBin, setSourceBin] = useState<string>("");
  const [targetBin, setTargetBin] = useState<string>("");
  const [quantity, setQuantity] = useState<number>();
  const [itemVal, setItemVal] = useState<string>("");
  const [storesLocationList, setStoresLocationList] = useState<any[]>([]);

  const getBinTransferColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_bin_adjust",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };

  const setup = async () => {
    setShowLoading(true);
    const binTransferColumns = await getBinTransferColumns();
    const defaultStore = await getDefaultStoreLocation();
    defaultStore && setStoreLocation(defaultStore);
    checkMultiWarehouseOption();
    setBinTransferColumns(binTransferColumns);
    setShowLoading(false);
  };

  async function onScanTextEnter(ev: any): Promise<void> {
    let res = String(ev.target.value);
    setItemVal(res);
    if (itemVal === res) return;
    const barCodeFieldData = findObjectByElementType(
      binTransferColumns?.fields
    );
    const barcodeResult = await performBarCodeShimProcess(
      barCodeFieldData,
      res
    );
    if (barcodeResult) {
      if (barcodeResult.NoMatch) {
        setItemVal(res);
        setScanText(res);
        return;
      }
      let capturingGroups = barcodeResult.CapturingGroupValues;
      if (capturingGroups?.Item || barcodeResult.FirstCapturingGroup) {
        res = capturingGroups?.Item || barcodeResult.FirstCapturingGroup;
        setItemVal(res);
        let storesLocation = capturingGroups?.StoresLocation;
        if (storesLocation) {
          let storesLocationFilter = storesLocationList.filter(
            (x) => x.Code === storesLocation
          );
          if (storesLocationFilter.length > 0) {
            setStoreLocation(storesLocation);
          } else {
            presentAlert(
              `User doesn't have access to the stores location ${storesLocation}.`
            );
            setScanText("");
            setItemVal("");
            return;
          }
        }
      }
    }
    setScanText(res);
    handleItemClick(res);
  }
  const saveTransferBin = async () => {
    const data = {
      Item: item,
      SourceBin: sourceBin,
      StoresLocation: storeLocation,
      TargetBin: targetBin,
      TransactionQuantity: Number(quantity),
    };
    transferBin(data).then((response) => {
      if (response.data.Success === true) {
        presentAlert({
          header: translatedMessages["BinTransferSuccess"]?.MessageText.replace(
            "@1",
            item
          )
            .replace("@2", sourceBin)
            .replace("@3", targetBin)
            .replace("@4", quantity),
          buttons: [
            {
              text: `${translations["lbl_btn_ok"] || "OK"}`,
            },
          ],
        });
        resetFields();
        setSourceBin("");
        setTargetBin("");
        setScanText("");
        setQuantity(undefined);
      } else {
        presentAlert({
          header: response.data.MessageList[0].Text,
          buttons: [
            {
              text: `${translations["lbl_btn_ok"] || "OK"}`,
            },
          ],
        });
      }
    });
  };

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (binTransferColumns) {
      const storeLocationField: GetColumnsWithGroupField =
        binTransferColumns?.fields?.["in_log_material.stores_location"];
      storeLocationField && setStoreLocationField(storeLocationField);
      // Default to only store location
      if (
        storeLocationField?.ValidValues &&
        storeLocationField.ValidValues.length === 1
      ) {
        const store = storeLocationField.ValidValues[0];
        setStoreLocation(store.Code);
        setShowLoading(false);
      }
      if (storeLocationField && storeLocationField.ValidValues)
        setStoresLocationList(storeLocationField.ValidValues);
      const itemField = binTransferColumns?.fields?.["in_log_material.item"];
      itemField && setItemField(itemField);
      if (itemFromInventory) {
        getGeneralInfo(itemFromInventory);
      }
    }
  }, [
    storeLocation,
    binTransferColumns,
    itemFromInventory,
    description,
    unitOfIssue,
  ]);

  useIonViewDidLeave(() => {
    resetFields();
    setSourceBin("");
    setTargetBin("");
    setScanText("");
    setQuantity(undefined);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {itemFromInventory ? (
              <IonButton routerDirection="back" routerLink="/Inventory">
                <IonIcon slot="icon-only" icon={chevronBackOutline} />
              </IonButton>
            ) : (
              <IonMenuButton />
            )}
          </IonButtons>
          <IonTitle>
            {translations["lbl_mob_bintransfer"] || "Bin Transfer"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={
                !sourceBin || !targetBin || !quantity || sourceBin === targetBin
              }
              onClick={() => saveTransferBin()}
            >
              {translations["lbl_btn_transfer"] || "Transfer"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonContent>
        <IonItem lines="full">
          <IonLabel>
            <h1>{translations["lbl_item_search"] || "Item Search"}</h1>
          </IonLabel>
        </IonItem>
        {!!multiWarehouseCheck && multiWarehouseCheck === true && (
          <IonItem>
            <IonLabel>{storeLocationField?.TranslatedIdText}*</IonLabel>
            <IonSelect
              value={storeLocation}
              onIonChange={(ev) => {
                onStoreLocationSelect(ev.detail.value);
              }}
              interface="popover"
            >
              {storeLocationField?.ValidValues?.map((value) => {
                return (
                  <IonSelectOption
                    value={value.Code}
                    key={`store-location-${value.Code}-${value.Description}`}
                  >
                    {value.Code}
                    {value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        )}
        <IonItem>
          <IonLabel>{itemField?.TranslatedIdText}*</IonLabel>
          {itemFromInventory ? (
            <IonInput
              class="ion-text-end"
              value={itemFromInventory}
              readonly
            ></IonInput>
          ) : (
            <IonInput
              class="ion-text-end"
              value={scanText}
              placeholder={`${
                translations["lbl_search_item"] || "Search Item"
              }...`}
              debounce={1500}
              onIonChange={(ev) => {
                if (ev.detail.value === undefined || ev.detail.value === null)
                  return;
                setScanText(ev.detail.value);
                getSearchParamters(ev.detail.value);
                onScanTextEnter(ev);
              }}
            ></IonInput>
          )}
          <IonIcon
            slot="end"
            ios={barcodeOutline}
            md={barcodeSharp}
            onClick={() => scanClick()}
          ></IonIcon>
        </IonItem>
        {results.length > 0 && (
          <IonList lines="full">
            {results.map((result, index) => {
              return (
                <IonItem
                  button
                  key={index}
                  onClick={() => {
                    handleItemClick(result.item);
                  }}
                >
                  <IonLabel>{`${result.item} - ${result.description}`}</IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        )}
        <IonItem lines="full" hidden={!description && !unitOfIssue}>
          <h2>{`${
            translations["lbl_mob_transferitem"] || "Transfer Item"
          }: ${item} - ${description}`}</h2>
        </IonItem>
        <IonItem hidden={!description && !unitOfIssue}>
          <IonLabel>{translations["lbl_mob_frombin"] || "From Bin"}*</IonLabel>
          <IonSelect
            interface="popover"
            value={sourceBin}
            onIonChange={(ev) => {
              setSourceBin(ev.detail.value);
            }}
          >
            {binInfo?.map((value, index) => {
              return (
                <IonSelectOption value={value.Bin} key={index}>
                  {value.Bin} - {value.QtyOnHand}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        <IonItem hidden={!description && !unitOfIssue}>
          <IonLabel>{translations["lbl_mob_tobin"] || "To Bin"}*</IonLabel>
          <IonSelect
            interface="popover"
            value={targetBin}
            onIonChange={(ev) => {
              setTargetBin(ev.detail.value);
            }}
          >
            {binInfo?.map((value, index) => {
              return (
                <IonSelectOption value={value.Bin} key={index}>
                  {value.Bin} - {value.QtyOnHand}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        <IonItem hidden={!description && !unitOfIssue}>
          <IonLabel>
            {translations["lbl_mob_qtytransfer"] || "Qty to Transfer"}*
          </IonLabel>
          <IonInput
            class="ion-text-end"
            value={quantity}
            inputMode="decimal"
            type="number"
            placeholder={translations["lbl_mob_quantity"] || "Enter Quantity"}
            onIonInput={(ev) => {
              setQuantity(Number(ev.target.value));
            }}
          ></IonInput>
        </IonItem>
        <IonItem hidden={!description && !unitOfIssue}>
          <IonLabel>
            {translations["lbl_initemissuebinview_unitofissue"] ||
              "Unit of Issue"}
          </IonLabel>
          <IonText slot="end">{unitOfIssue}</IonText>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default BinTransfer;
