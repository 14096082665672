import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonList,
  IonLoading,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import React, { useContext, useRef, useState } from "react";
import QueryFilter from "./QueryFilter";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import {
  CriteriaParameter,
  FilterParam,
  maxResults,
  useQueryExec,
} from "../hooks/useQueryExec";
import WorkOrderResults from "./WorkOrderResults";
import CardExpandableHeader from "./CardExpandableHeader";
import BarcodeScannerButton from "./BarcodeScannerButton";
import { StoresProps } from "../models/stores/Stores";
import { findWorkOrder } from "../api/WorkOrders";
import StoresPopover from "./StoresPopover";
import SearchFooter from "./SearchFooter";
import { encodeParam } from "../util/ApiHelper";

const StoresWorkOrder: React.FC<StoresProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { expanded, setExpanded, storesTab } = props;
  const {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    queryRows,
    setQueryRows,
    executeSearch,
    getPriorityCodes,
    getStatusCodes,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    getNoOfFieldsToDisplay,
    showLoading,
    setShowLoading,
    showToast,
    setShowToast,
    execFilter,
    filterRows,
    resultsFilter,
  } = useQueryExec("Work Order", "Work Order", "cf_work_order");
  const [searchYn, setSearchYn] = useState(false);
  const [queryName, setQueryName] = useState("");
  const [criteriaParams, setCriteriaParams] = useState<CriteriaParameter[]>();
  const popover = useRef<HTMLIonPopoverElement>(null);
  const router = useIonRouter();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const storesRoute = true;
  const [presentAlert] = useIonAlert();
  const querySection = "QW--Work Order";

  const confirmSearch = async (
    currentQuery: string,
    filterParams?: FilterParam[],
    parameters?: CriteriaParameter[]
  ) => {
    setQueryRows([]);
    setCriteriaParams(parameters);
    setQueryName(currentQuery);
    setSearchYn(true);
    handleOpen();
  };

  const handleExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleWorkOrderSearch = (e: CustomEvent) => {
    let newVal = e.detail.value;
    setSearchValue(newVal);
    if (newVal.length > 2) {
      findWorkOrder(newVal).then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            popover.current!.event = e;
            setPopoverOpen(true);
          } else if (popoverOpen) {
            setPopoverOpen(false);
          }
          let woSearchArray = [];
          for (let search of response.data) {
            woSearchArray.push(search.WoNumber);
          }
          setSearchResults(woSearchArray);
        }
      });
    } else {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    }
  };

  const onBarcodeScan = (text?: string) => {
    if (text) {
      router.push(`/workorder/${encodeParam(text)}/${storesTab}material`);
    } else {
      presentAlert({
        message: "Work Order could not be found",
        buttons: ["OK"],
      });
    }
  };

  const ResultsModal = (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>{translations["lbl_WorkOrders"] || "Work Orders"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <QueryFilter
          setShowLoading={setShowLoading}
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
          getFilterParams={getFilterParams}
          filterParams={filterParams}
          setParameter={setParameter}
          queryCriteria={queryCriteria}
          getQueryCriteria={getQueryCriteria}
          searchCallback={confirmSearch}
        />
        <WorkOrderResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          queryParams={filterParams}
          criteria={criteriaParams}
          queryRows={queryRows}
          setParameter={setParameter}
          executeSearch={executeSearch}
          getPriorityCodes={getPriorityCodes}
          getStatusCodes={getStatusCodes}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          onQueryRowClick={handleClose}
          filterMethod={execFilter}
          filterRows={filterRows}
          filterString={resultsFilter}
          storesRoute={storesRoute}
          storesTab={storesTab}
        />
      </IonContent>
      <SearchFooter totalRows={totalCount} filterRows={filterRows} />
    </IonModal>
  );

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />

      {ResultsModal}

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={
          translatedMessages["DisplayFirst"]?.MessageText.replace(
            "@1",
            maxResults
          )
            .replace("@2", translations["lbl_WorkOrders"] || "work orders")
            .replace("@3", totalCount) ||
          `Displaying the first ${maxResults} work orders of ${totalCount}.`
        }
        duration={2000}
        position="top"
      />

      <IonCard>
        <CardExpandableHeader
          expanded={expanded}
          onExpandClick={handleExpand}
          title={translations["lbl_btn_work_order"] || "Work Order"}
        />
        <IonCardContent hidden={!expanded} style={{ paddingTop: 0 }}>
          <IonList>
            <IonSearchbar
              placeholder={
                translations["lbl_mob_enterworkorder"] ||
                "Enter Work Order Number"
              }
              showClearButton="focus"
              inputMode="text"
              type="text"
              id="req-searchbar"
              value={searchValue}
              onIonChange={handleWorkOrderSearch}
              debounce={500}
            ></IonSearchbar>
            <IonButton
              color="primary"
              expand="full"
              onClick={() => handleOpen()}
            >
              {translations["lbl_btn_mob_advancedsearch"] || "Advanced Search"}
            </IonButton>
            <BarcodeScannerButton
              textButton
              onScan={onBarcodeScan}
              expand="block"
            />
          </IonList>
        </IonCardContent>
      </IonCard>
      <StoresPopover
        popover={popover}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        querySection={querySection}
        storesTab={storesTab}
      />
    </>
  );
};

export default StoresWorkOrder;
