import React, { useContext } from "react";
import { IonContent, IonLoading, IonPage } from "@ionic/react";
import "./WorkOrders.css";
import { useQueryExec } from "../hooks/useQueryExec";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import PageHeader from "../components/PageHeader";
import WorkOrderResults from "../components/WorkOrderResults";
import QueryFilter from "../components/QueryFilter";
import {
  TranslationsContext,
} from "../util/Translations";
import SearchFooter from "../components/SearchFooter";

const WorkOrders: React.FC = () => {
  const { translations } = useContext(TranslationsContext);

  const {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    queryRows,
    executeSearch,
    getPriorityCodes,
    getStatusCodes,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    getNoOfFieldsToDisplay,
    showLoading,
    setShowLoading,
    searchYn,
    setSearchYn,
    queryName,
    criteriaParams,
    confirmSearch,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Work Order", "Work Order", "cf_work_order");

  const querySection = "QW--Work Order";

  return (
    <IonPage>
      <PageHeader
        title={translations["lbl_WorkOrders"] || "Work Orders"}
      ></PageHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonContent>
        <QueryFilter
          setShowLoading={setShowLoading}
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
          getFilterParams={getFilterParams}
          filterParams={filterParams}
          setParameter={setParameter}
          queryCriteria={queryCriteria}
          getQueryCriteria={getQueryCriteria}
          searchCallback={confirmSearch}
        />
        <WorkOrderResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          queryParams={filterParams}
          criteria={criteriaParams}
          queryRows={queryRows}
          setParameter={setParameter}
          executeSearch={executeSearch}
          getPriorityCodes={getPriorityCodes}
          getStatusCodes={getStatusCodes}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          filterRows={filterRows}
          filterMethod={execFilter}
          filterString={resultsFilter}
        />
      </IonContent>
      <SearchFooter totalRows={totalCount} filterRows={filterRows} />
    </IonPage>
  );
};

export default withAITracking(reactPlugin, WorkOrders, "WorkOrders");
