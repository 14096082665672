import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "Events";

export interface GetEventsParams {
  dateCreated: string;
  timeCreated: string;
  comment: boolean;
  completion: boolean;
}

export interface AddFollowUpData {
  baseWo: string;
  followUpDateCreated: string;
  followUpTimeCreated: string | null;
  followupWo: string;
  isBaseWorkOrder: boolean;
  isFollowUpWo: boolean;
}

export const getEvents = async (params: GetEventsParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Get`,
    undefined,
    {
      comment: String(params.comment),
      completion: String(params.completion),
      dateCreated: params.dateCreated,
      timeCreated: params.timeCreated,
    }
  );
};

export const updateEvents = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/UpdateEvents`,
    data
  );
};

export const putPartial = async (data: any) => {
  return await sendRequest(
    HttpMethods.PUT,
    `${CONTROLLER_NAME}/PutPartial`,
    data
  );
};

export const addFollowup = async (data: AddFollowUpData) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/AddFollowUp`,
    data
  );
};
