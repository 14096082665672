import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { getFormattedValue } from "../util/UIHelper";
import { ColumnDef } from "../hooks/useQueryExec";

interface Card {
  header: string;
  columns: ColumnDef[];
}

const CardInfo: React.FC<Card> = (props) => {
  const { header, columns } = props;
  return (
    <>
      <IonCardHeader>
        <IonCardTitle>{header}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          {[...columns]
            .filter((c,i)=>{return !c.Hidden}).sort((a, b) => {
              return a.Order - b.Order;
            })
            .map((c, i) => {
              return (
                <IonRow key={i}>
                  <IonCol>{c.Text}</IonCol>
                  <IonCol>{getFormattedValue(c.Type, c.Value)}</IonCol>
                </IonRow>
              );
            })}
        </IonGrid>
      </IonCardContent>
    </>
  );
};

export default CardInfo;
