import { IonCard, IonLabel, useIonAlert } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { FilterParam } from "../hooks/useQueryExec";
import { WorkOrder } from "../models/workorders/WorkOrder";
import { encodeParam } from "../util/ApiHelper";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import {
  checkUpdateWorkOrder,
  getWorkOrder,
  updateWorkOrder,
} from "../api/WorkOrders";
import { ResultsParamBase } from "../models/common/ResultsParamBase";
import CardInfo from "./CardInfo";
import CardFilter from "./CardFilter";

interface EquipmentResultsParams extends ResultsParamBase {
  woNumber?: string; // If in use on the work order page
  equipmentClick?: (arg0?: any) => void;
  modalProps?: {
    isOpen: boolean;
    onModalCancel: () => void;
    onModalConfirm: (equipmentId: string) => void; // get Equipment
  };
  equipmentChange?: boolean;
}

const EquipmentResults: React.FC<EquipmentResultsParams> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const {
    searchYn,
    setSearchYn,
    queryName,
    queryParams,
    criteria,
    queryRows,
    setQueryRows,
    setParameter,
    executeSearch,
    getNoOfFieldsToDisplay,
    errorState,
    totalCount,
    showLoading,
    setShowLoading,
    woNumber,
    equipmentClick,
    modalProps,
    filterRows,
    filterMethod,
    filterString,
    equipmentChange,
  } = props;
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [presentAlert] = useIonAlert();

  const setQueryParams = async (queryParams: FilterParam[]) => {
    queryParams.forEach((p) => {
      setParameter(p.ColumnName, p.ParameterValue);
    });
  };

  const beginSearch = async () => {
    if (!!searchYn) {
      setErrorMessage("");
      await executeSearch(queryName, criteria);
      setSearchExecuted(true);
      setSearchYn(false);
    }
  };

  // Functions for if equipment search is a modal
  const workOrderQuery = () => {
    if (!!woNumber) {
      getWorkOrder({
        woNumber: woNumber,
      }).then((response) => {
        setWorkOrder(response.data);
      });
    }
  };

  const checkUpdateWorkOrderQuery = (equipmentId: string) => {
    let workOrderId = encodeParam(`${woNumber}`);
    const data = {
      RequestObject: {
        ...workOrder,
        CfWorkOrder: workOrderId,
        WoNumber: `${workOrderId}-  -  `,
        Equipment: equipmentId,
      },
    };
    checkUpdateWorkOrder(data).then((response) => {
      if (response.data.Success === true) {
        updateWorkOrder(data).then((response) => {
          if (response.data.Success === true) {
            modalProps?.onModalConfirm(equipmentId);
          } else {
            setErrorMessage(response.data?.MessageList[0]?.Text);
          }
        });
      } else {
        setErrorMessage(response.data?.MessageList[0]?.Text);
      }
    });
  };

  const retrieveEquipment = (equipmentId: string) => {
    // confirm to proceed with change
    presentAlert({
      message: `${
        translatedMessages["SelectedEquip"]?.MessageText.replace(
          "@1",
          equipmentId
        ) || `You have selected equipment: ${equipmentId}`
      }.<br/>${
        translatedMessages["Continue"]?.MessageText ||
        "Do you wish to continue?"
      }`,
      backdropDismiss: false,
      buttons: [
        {
          text: translations["lbl_btn_cancel"] || "Cancel",
          role: "cancel",
        },
        {
          text: translations["lbl_btn_ok"] || "OK",
          role: "confirm",
        },
      ],
      async onDidDismiss(event) {
        if (event.detail.role === "confirm") {
          // save equipment
          checkUpdateWorkOrderQuery(
            decodeURIComponentSafe(encodeParam(equipmentId))
          );
          // Clear modal data when switching equipments
          if (setQueryRows) setQueryRows([]);
        } else {
          return;
        }
      },
    });
  };

  // if equipment contains special characters, decode them
  function decodeURIComponentSafe(equipmentId: string) {
    if (!equipmentId) {
      return equipmentId;
    }
    return decodeURIComponent(equipmentId.replaceAll("%25", "%"));
  }

  // Actual search components and search results display
  const cardClickFunction = (val: any) => {
    if (!!equipmentClick) {
      equipmentClick(val);
    } else if (!!modalProps && !!woNumber) {
      retrieveEquipment(val);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (woNumber) {
      workOrderQuery();
    }
  }, [woNumber]);

  useEffect(() => {
    setShowLoading(true);
    Promise.all([
      setQueryParams(queryParams),
      getNoOfFieldsToDisplay("EqNoOfFieldsToDisplay"),
      beginSearch(),
    ]).finally(() => {
      setShowLoading(false);
    });
  }, [searchYn]);

  return (
    <>
      <IonLabel
        hidden={!!showLoading || !searchExecuted || totalCount > 0}
        color={"warning"}
      >
        {translatedMessages["NoResultsFound"]?.MessageText ||
          "No results found."}
        {searchExecuted.valueOf()}
      </IonLabel>
      {!!queryRows && queryRows.length > 0 && (
        <>
          <CardFilter
            queryRows={queryRows}
            resultsFilter={filterString}
            filterMethod={filterMethod}
          />
          <IonLabel hidden={errorState === ""} color={"danger"}>
            {errorState}
          </IonLabel>
          <IonLabel hidden={errorMessage === ""} color={"danger"}>
            {errorMessage}
          </IonLabel>
          {filterRows.map((row) => {
            return (
              <IonCard
                key={row.HeaderRow}
                onClick={() => cardClickFunction(row.HeaderValue)}
                routerLink={
                  !equipmentChange && !equipmentClick
                    ? `Equipment/${encodeParam(row.HeaderValue)}`
                    : undefined
                }
              >
                <CardInfo header={row.HeaderValue} columns={row.Columns} />
              </IonCard>
            );
          })}
        </>
      )}
    </>
  );
};

export default withAITracking(
  reactPlugin,
  EquipmentResults,
  "EquipmentResults"
);
