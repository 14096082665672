import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "Employees";

export const getCrafts = async (employeeId: string) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/Crafts`,
        undefined,
        {
            employeeId: employeeId
        }
    );
};

export const getEmployeesLiteList = async (data: any) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/GetLiteList`,
        data
    );
};