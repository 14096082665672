import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "ValidationCodes";

export interface GetCodeValuesParams {
    tableName: string;
    fieldName: string;
};

export const getCodeValues = async (params: GetCodeValuesParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetCodeValues`,
        {},
        params
    );
};

export const getValidationCodes = async (twType: string) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/Get`,
        {},
        {
            twType: twType
        }
    );
};
