import {IRemarkSubmissionData} from "./IRemarkSubmissionData";

/**
 * Object representing a remark to be uploaded.
 */
export class RemarkSubmissionData implements IRemarkSubmissionData {
    readonly PropertyName: string = "Remarks";
    readonly TableName: string = "eq_events_comments";
    readonly ColumnName: string = "remarks";
    readonly AppendMode: boolean = true;
    readonly AppendType: string = "prefix";
    DataValue: string;
    UserDateTime: string;

    constructor(comment: string, userDateTime: string ) {
        if (!comment) {
            throw new Error("comment cannot be null or undefined");
        }
        if (!userDateTime) {
            throw new Error("userDateTime cannot be null or undefined");
        }
        this.DataValue = comment;
        this.UserDateTime = userDateTime;
    }
}