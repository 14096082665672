import React from "react";

export enum ErrorCodes {
    None = 0,
    User_Validation_Failed = 1,
    Document_Upload_Failed = 2,
    Application_Info_Retrieval_Failed = 3,
}

export class ErrorInfo {
    public ErrorCode: ErrorCodes = ErrorCodes.None;
    public Message: string | undefined;
    public Stack?: string;

    constructor(errorCode: number, message: string, stack?: string) {
        this.ErrorCode = errorCode;
        this.Message = message;
        if (stack) {
            this.Stack = stack;
        }
    }
}

interface ErrorContent {
    error: ErrorInfo | undefined;
    setError: ((error: ErrorInfo) => void) | undefined;
}

export const ErrorContext = React.createContext<ErrorContent>({
    error: {
        ErrorCode: ErrorCodes.None,
        Message: undefined,
        Stack: undefined
    },
    setError: undefined
});
