/**
 * An object representing an EAM remark/comment
 */
export class Remark {
    /**
     * A short string representing the user's avatar
     */
    avatar: string | undefined;
    /**
     * A string representing the user's name
     */
    user: string | undefined;
    /**
     * A string representing the date the remark was created.
     * <comment>This date includes the timezone. For example 12/5/2023 12:15:16:12 IST</comment>
     */
    date: string | undefined;
    /**
     * A string representing the remark/comment
     */
    comment: string | undefined;
}