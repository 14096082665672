
import { InspectionDef, InspectionResultsData, LocalInspectionsData, PointValue, getInspectionResultsData } from "../models/inspections/Inspections";
import { get, set } from "./Preferences";

const _inspectionsKey = "InspectionWorkOrders";

const getLocalInspections = async () => {
    return await get(_inspectionsKey) as LocalInspectionsData[] || [];
};

const setLocalInspections = async (inspectionArray: LocalInspectionsData[]) => {
    return await set(_inspectionsKey, inspectionArray);
}

export const getInspectionFromLocal = async (inspectionId: string, workOrderId: string) => {
    const inspections = await getLocalInspections();
    return inspections?.find((val) => {
        return val.InspectionId === inspectionId && val.WorkOrder === workOrderId;
    });
};

export const saveInspectionToLocal = async (
    woNumber: string,
    equipmentId: string,
    inspectionId: string,
    pointValues: PointValue[],
    inspectionDef: InspectionDef,
    failedFlag: boolean,
    comments?: string,
    testEquipment?: string
) => {
    const data = await getInspectionResultsData(woNumber, inspectionId, pointValues, inspectionDef, failedFlag, comments, testEquipment);
    const localInspections = await getLocalInspections();

    const newInspection:LocalInspectionsData = {
        ActionResult: {
            MessageList: [],
            Success: true,
        },
        Equipment: equipmentId,
        InspectionId: inspectionId,
        InspectionResultData: data,
        Messages: [],
        PrevStatus: "",
        Status: "InProcess",
        WorkOrder: woNumber
    };
    const updatedInspections = [...localInspections.filter((insp: LocalInspectionsData) =>
        !(insp.WorkOrder === woNumber && insp.InspectionId === inspectionId)), newInspection]

    return await setLocalInspections(updatedInspections).then(async () => {
        // Check that the inspection was saved to local
        let newlySaved = await getInspectionFromLocal(inspectionId, woNumber);
        return !!newlySaved;
    })
};

export const removeInspectionFromLocal = async (inspectionId: string, workOrderId: string) => {
    const allLocalInspections = await getLocalInspections();
    if (allLocalInspections) {
        await setLocalInspections([...allLocalInspections.filter((insp: LocalInspectionsData) =>
            !(insp.WorkOrder === workOrderId && insp.InspectionId === inspectionId))]);
    }
};