import { IonContent, IonLoading, IonPage, IonToast } from "@ionic/react";
import React, { useContext } from "react";
import { maxResults, useQueryExec } from "../hooks/useQueryExec";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import PageHeader from "../components/PageHeader";
import QueryFilter from "../components/QueryFilter";
import InventoryResults from "../components/InventoryResults";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import SearchFooter from "../components/SearchFooter";

const Inventory: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    showLoading,
    setShowLoading,
    showToast,
    setShowToast,
    queryRows,
    executeSearch,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    getNoOfFieldsToDisplay,
    searchYn,
    setSearchYn,
    queryName,
    criteriaParams,
    confirmSearch,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Item", "Item", "item");

  const querySection = "QW--Item";

  return (
    <IonPage>
      <PageHeader title={translations["lbl_item_search"] || "Item Search"} />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={
          translatedMessages["DisplayFirst"]?.MessageText.replace(
            "@1",
            maxResults
          )
            .replace("@2", translations["lbl_category_item"] || "Item")
            .replace("@3", totalCount) ||
          `Displaying the first ${maxResults} Items of ${totalCount}.`
        }
        duration={2000}
        position="top"
      />
      <IonContent>
        <QueryFilter
          setShowLoading={setShowLoading}
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
          getFilterParams={getFilterParams}
          filterParams={filterParams}
          setParameter={setParameter}
          queryCriteria={queryCriteria}
          getQueryCriteria={getQueryCriteria}
          searchCallback={confirmSearch}
        />
        <InventoryResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          queryParams={filterParams}
          criteria={criteriaParams}
          queryRows={queryRows}
          filterParams={filterParams}
          setParameter={setParameter}
          executeSearch={executeSearch}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          filterRows={filterRows}
          filterMethod={execFilter}
          filterString={resultsFilter}          
        />
      </IonContent>
      <SearchFooter totalRows={totalCount} filterRows={filterRows} />
    </IonPage>
  );
};

export default withAITracking(reactPlugin, Inventory, "Inventory");
