import { useQueryExec } from "../hooks/useQueryExec";
import { IonItem, IonLabel } from "@ionic/react";
import DefaultQueries from "./DefaultQueries";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

const DefaultEquipmentQuery: React.FC = () => {
  const { getQueryList, queryList } = useQueryExec(
    "Equipment",
    "Equipment",
    "equipment"
  );
  const querySection = "QW--Equipment";
  const { translations } = useContext(TranslationsContext);

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">
          {`${translations["lbl_def_eq_query"] || "Default Equipment Query"}:`}
        </IonLabel>
        <DefaultQueries
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
        />
      </IonItem>
    </>
  );
};
export default DefaultEquipmentQuery;
