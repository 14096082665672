import { HttpMethods, sendRequest } from "../util/ApiHelper"

const CONTROLLER_NAME = "CycleCountGroups";

interface GetDataForCycleItemParams {
    batchId: number;
}

export const getDataForCycleItem = async (params: GetDataForCycleItemParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetDataForCycleItem`,
        undefined,
        {
            batchId: String(params.batchId)
        }
    )
}