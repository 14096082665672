import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "TabwareOptions";

export const getOptionByIdWithCORP = async (optionId: string) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetOptionByIdWithCORP`,
        {},
        {
            optionId: optionId
        }
    );
};