import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { TranslationsContext } from "../util/Translations";
import { Employee } from "../models/employees/Employees";
import BarcodeScannerButton from "./BarcodeScannerButton";

interface EmployeeSearchProps {
  isOpen: boolean;
  employees: Employee[];
  onSelect: (employee: Employee) => void;
  onCancel: () => void;
  title?: string;
}

const EmployeeSearch: React.FC<EmployeeSearchProps> = (props) => {
  const { isOpen, employees, onSelect, onCancel, title } = props;
  const { translations } = useContext(TranslationsContext);
  const [filterString, setFilterString] = useState<string>();
  const [filteredItems, setFilteredItems] = useState<Employee[]>(employees);

  useEffect(() => {
    if (!filterString) {
      setFilteredItems(employees);
    } else {
      const newArray = employees.filter((employee) => {
        return (
          employee.EmployeeId.includes(filterString) ||
          employee.Properties?.EmployeeName?.includes(filterString)
        );
      });
      setFilteredItems(newArray);
    }
  }, [filterString]);

  useEffect(() => {
    setFilteredItems(employees);
  }, [employees]);

  const onScan = (text?: string) => {
    text && setFilterString(text);
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="primary" onClick={onCancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <BarcodeScannerButton onScan={onScan} />
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={filterString}
            onIonChange={(ev) => {
              setFilterString(ev.detail.value);
            }}
            debounce={250}
            placeholder={translations["lbl_btn_search"] || "Search"}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <IonList lines="full">
          {filteredItems.map((employee, index) => {
            return (
              <IonItem
                button
                key={index}
                onClick={() => {
                  onSelect(employee);
                }}
              >
                <IonLabel>
                  {`${employee.EmployeeId}`}
                  {employee.Properties?.EmployeeName &&
                    ` - ${employee.Properties?.EmployeeName}`}
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default EmployeeSearch;
