import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "ItemLocations";

export interface ItemLocationParams {
  bin: boolean;
  item: string;
  storesLocation: string;
}

export interface FindStoreItemParams {
  searchTerm: string;
  store: string;
}

export const getItemLocations = async (params: ItemLocationParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Get`,
    undefined,
    {
      bin: String(params.bin),
      item: params.item,
      storesLocation: params.storesLocation,
    }
  );
};

export const findStoreItem = async (params: FindStoreItemParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Find`,
    undefined,
    params
  );
};
