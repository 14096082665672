import {
  IonContent,
  IonItem,
  IonPage,
  IonText,
  useIonRouter,
} from "@ionic/react";
//import { useLocation } from "react-router";
import EquipmentSearch from "../components/EquipmentSearch";
import PageHeader from "../components/PageHeader";
import { encodeParam } from "../util/ApiHelper";

import "./CreateQuickWO.css";
import { TranslationsContext } from "../util/Translations";
import { useContext } from "react";
import { useLocation } from "react-router";

const QuickWorkOrder: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  let { pathname } = useLocation();
  const router = useIonRouter();
  let title = translations["lbl_equipment_search"] || "Equipment Search"; // TODO: have title change according to url?
  let pageHeader = pathname.includes("requester")
    ? "Create request" /* TODO: Get translations for this */
    : translations["lbl_category_equipmentworkorder"] || "Equipment Work Order";
  const onEquipmentClick = (equipId: string) => {
    router.push(`/Equipment/${encodeParam(equipId)}`);
  };

  return (
    <IonPage>
      <PageHeader title={pageHeader} />
      <IonContent>
        <IonItem lines="none">
          <IonText>
            <h4 className="quick-wo-title">{title}</h4>
          </IonText>
        </IonItem>
      </IonContent>
      {/*
       * TODO: Make which search is displayed vary based on type of quick work order
       * ie, equipment vs serial, depending on url.
       */}
      <EquipmentSearch equipmentClick={onEquipmentClick} />
    </IonPage>
  );
};

export default QuickWorkOrder;
