import {FieldsBase} from "../common/FieldsBase";

export interface MeterReadingsMetadata {
  Groups?: any[];
  fields?: any;
  Actions?: any[];
  IsAutoPO?: boolean;
}

export interface MeterReadingsGroup {
  TextId?: string;
  Width?: number;
  GroupOrder: number;
  Icon?: any;
  GroupName?: string;
  ParentGroup?: string;
  Groups?: MeterReadingsGroup[];
  IsRepeatable?: boolean;
  Inflatable?: boolean;
}

export interface MeterReadingsField extends FieldsBase {}

export interface MeterReadings {
  DigitalMeterFlag?: boolean;
  ElementType?: string;
  EquipSerialId?: string;
  EquipSerialInd?: string;
  EquipmentMaster?: any;
  LastReading?: string;
  LastReadingDate?: string;
  LastReadingTime?: string;
  Meter?: string;
  MeterSize?: number;
  MeterUom?: any;
  ObjectId?: string;
  Plant?: string;
  PmScheduleMeterFlag?: boolean;
  ReadingValidValues?: any;
  Readings?: any;
  RowVersion?: any;
  Serial?: any;
  SourceTableName?: string;
  Comments?: string;
}

const getFieldsFromMetadata = (metadata: MeterReadingsMetadata) => {
  const fields: MeterReadingsField[] = [];
  const metadataFields = metadata.fields;
  // Get list of keys from metadata to iterate through
  const keys = Object.keys(metadataFields);
  keys.forEach((key) => {
    const field = metadataFields[key];
    fields.push(field);
  });
  return fields;
};

export const getMeterReadingsGroups = (meterReading?: MeterReadingsMetadata) => {
  if (!meterReading) {
    return [];
  }
  const groups = (meterReading.Groups as MeterReadingsGroup[]) || [];
  return [...groups].sort((a, b) => {
    return a.GroupOrder - b.GroupOrder;
  });
};

export const getFieldsByGroup = (
  metadata: MeterReadingsMetadata,
  groupName?: string
) => {
  if (!groupName) {
    return [];
  }
  const fields = getFieldsFromMetadata(metadata).filter((field) => {
    return field.GroupName === groupName;
  });
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const getAllFields = (metadata: MeterReadingsMetadata) => {
  const fields = getFieldsFromMetadata(metadata);
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const formatData = (data: any, field: MeterReadingsField) => {
  if (!data) {
    return "";
  }
  if (field.ValidValues) {
    const validValue = field.ValidValues.find((value) => {
      return value.Code === String(data);
    });
    return !!validValue ? `${data} - ${validValue?.Description}` : data;
  }
  if (field.NetType === "DateTime") {
    const date = new Date(data);
    return date.toLocaleDateString();
  }
  if (field.PropertyName === "ReadingTime") {
    const date = new Date(data);
    return date.toLocaleTimeString();
  }
  return data;
};
