import { CriteriaParameter, ExecParam } from "../hooks/useQueryExec";
import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "QueryExecution";

export interface GetQueryCriteriaData {
    IsPublicQuery?: boolean
    ModuleName?: string;
    QueryName?: string;
    DefaultQuery?: boolean;
};

export const getQueryCriteriaData = async (data: GetQueryCriteriaData) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/GetQueryCriteriaData`,
        data
    );
};

export interface ExecuteQueryData {
    QueryName?: string;
    ModuleName?: string;
    EnableMultiPlantExecution?: boolean;
    IsPublicQuery?: boolean;
    FilterParameters?: ExecParam[];
    AskAtExecutionCriterias?: CriteriaParameter[];
};

export const executeQuery = async (data: ExecuteQueryData) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/ExecuteQuery`,
        data
    );
}