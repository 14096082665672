import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "QueryDesigner";

export interface GetListofFiltersParams {
    moduleName: string;
    includeValidValues?: boolean;
    windowName?: string;
};

export const getListofFilters = async (parameters: GetListofFiltersParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetListofFilters`,
        {},
        {
            IncludeValidValues: parameters.includeValidValues ? "true" : "false",
            moduleName: parameters.moduleName,
            windowName: parameters.windowName ?? ""
        });
};

export interface GetValueSearchDetailsParams {
    tableName: string;
    columnName: string;
};

export const getValueSearchDetails = async (params: GetValueSearchDetailsParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetValueSearchDetails`,
        {},
        params
    );
};
