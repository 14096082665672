import {Remark} from "./Remark";

/**
 * A collection of EAM Remarks/Comments
 */
export class RemarksCollection extends Array<Remark> {

    /**
     * Creates a new RemarksCollection from a string
     * @param data the string to parse
     */
    public static fromString(data: string): RemarksCollection {
        if (!data) {
            return [];
        }
        data = data.substring(0, 4).indexOf('\r\n') < 0 ? '\r\n' + data : data;
        const remarks = data.split('\r\n=====').filter((x) => x.length > 0);
        return remarks.map((rmk) => {
            const entry = rmk.trim().split('=====');
            const headerData = entry[0].split(',');

            const remark = new Remark();
            remark.avatar = headerData[0].charAt(0).toUpperCase();
            remark.user = headerData[0];
            remark.date = headerData[1]?.trim();
            remark.comment = entry[1].trim();

            return remark;
        });
    }
}