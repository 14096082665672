import React, { useContext, useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
} from "@ionic/react";
import { DeviceInfo } from "@capacitor/device";
import { ConnectionStatus, Network } from "@capacitor/network";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import { formatDateUIDate } from "../util/UIHelper";
import { getDeviceInfo } from "../util/Device";
import PageHeader from "../components/PageHeader";
import "./Support.css";
import { TranslationsContext } from "../util/Translations";
import {
  getUserDisplayName,
  getUserId,
  getUserDatabaseId,
  getUserPlantId,
} from "../util/UserHelper";
import {IVersionInfo} from "../app/IVersionInfo";
import {AppInfo} from "../app/AppInfo";

const Support: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [database, setDatabase] = useState("");
  const [plant, setPlant] = useState("");
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const [networkStatus, setNetworkStatus] = useState<ConnectionStatus>();
  const [versionInfo, setVersionInfo] = useState<IVersionInfo>();

  const getLoggedInUserName = async () => {
    return await getUserDisplayName().then((response) => {
      if (response !== undefined) {
        setUserName(response!);
      }
    });
  };

  const getLoggedInUserId = async () => {
    return await getUserId().then((response) => {
      if (response !== undefined) {
        setUserId(response!);
      }
    });
  };

  const getLoggedInDatabase = async () => {
    return await getUserDatabaseId().then((response) => {
      if (response !== undefined) {
        setDatabase(response);
      }
    });
  };

  const getLoggedInPlant = async () => {
    return await getUserPlantId().then((response) => {
      if (response !== undefined) {
        setPlant(response);
      }
    });
  };

  const getDeviceData = async () => {
    Promise.all([getDeviceInfo(), Network.getStatus()]).then((values) => {
      const appInfo = new AppInfo();
      setDeviceInfo(values[0]);
      setNetworkStatus(values[1]);
      setVersionInfo( (appInfo.getBuildInfo()));
    });
  };

  useEffect(() => {
    getLoggedInUserName();
    getLoggedInUserId();
    getLoggedInDatabase();
    getLoggedInPlant();
    getDeviceData();
  }, []);

  return (
    <IonPage>
      <PageHeader title={translations["lbl_support"] || "Support"} />
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              {translations["lbl_userinfo"] || "User Information"}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>{`${
                  translations["lbl_auauditchange_userid"] || "UserID"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {userName}
                  {` (${userId})`}
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel>{`${
                  translations["lbl_database"] || "Database"
                }:`}</IonLabel>
                <IonText class="support-ion-text">{database}</IonText>
              </IonItem>
              <IonItem>
                <IonLabel>
                  {`${translations["lbl_auauditchange_plant"] || "Plant"}:`}
                </IonLabel>
                <IonText class="support-ion-text">{plant}</IonText>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              {translations["lbl_app_info"] || "Application Information"}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>{`${
                  translations["lbl_posuppliertransmit_version"] || "Version"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {versionInfo?.major}.{versionInfo?.minor}.{versionInfo?.patch}
                  +{versionInfo?.build}
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel>
                  {`${translations["lbl_build_date"] || "Build Date"}:`}
                </IonLabel>
                <IonText class="support-ion-text">
                  {formatDateUIDate(versionInfo?.date)}
                </IonText>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              {translations["lbl_device_info"] || "Device Information"}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel class="ion-text-capitalize">{`${
                  translations["lbl_network_type"] || "Network Type"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {networkStatus?.connectionType}
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel>{`${
                  translations["lbl_network_status"] || "Network Status"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {networkStatus?.connected ? "Connected" : "Disconnected"}
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel>{`${
                  translations["lbl_device_name"] || "Device Name"
                }:`}</IonLabel>
                <IonText class="support-ion-text">{deviceInfo?.name}</IonText>
              </IonItem>
              <IonItem>
                <IonLabel class="ion-text-capitalize">{`${
                  translations["lbl_platform_name"] || "Platform Name"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {deviceInfo?.platform}
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel class="ion-text-capitalize">{`${
                  translations["lbl_os_name"] || "OS Name"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {deviceInfo?.operatingSystem}
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel>{`${
                  translations["lbl_os_version"] || "OS Version"
                }:`}</IonLabel>
                <IonText class="support-ion-text">
                  {deviceInfo?.osVersion}
                </IonText>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default withAITracking(reactPlugin, Support, "Support");
