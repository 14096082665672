import { createContext } from "react";
import {setThemePreference} from "./Preferences";
import {ThemePreference} from "../models/common/ThemePreference";



export const toggleDarkTheme = (shouldAdd: boolean) => {
  document.body.classList.toggle("dark", shouldAdd);
};

export const setTheme = (theme: ThemePreference) => {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    switch (theme) {
        case ThemePreference.dark:
            toggleDarkTheme(true);
            break;
        case ThemePreference.light:
            toggleDarkTheme(false);
            break;
        case ThemePreference.default:
        default:
            toggleDarkTheme(prefersDark.matches);
            break;
    }
    setThemePreference(theme);
}

interface ThemePreferenceContent {
  themePreference: ThemePreference | undefined;
  setThemePreference: ((theme: ThemePreference) => void) | undefined;
}

export const ThemeContext = createContext<ThemePreferenceContent>({
  themePreference: ThemePreference.default,
  setThemePreference: undefined,
});

/*
    * This function is called when the previous theme setting is being retrieved and reset.
 */
export const themeDetectionHandler = (event:MediaQueryListEvent, themePreference:ThemePreference | undefined) => {
    if (themePreference === ThemePreference.default) {
        toggleDarkTheme(event.matches);
    }
}
