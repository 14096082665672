import {
  IonButton,
  IonContent,
  IonList,
  IonLoading,
  IonPage,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import ThemeSelector from "../components/ThemeSelector";
import ApiOptionsModal from "../components/ApiOptionsModal";
import { useContext, useEffect, useState } from "react";
import { initializeAbpSession } from "../api/abpLogin";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import PageHeader from "../components/PageHeader";
import Languages from "../components/Languages";
import DefaultWOQuery from "../components/DefaultWOQuery";
import DefaultEquipmentQuery from "../components/DefaultEquipmentQuery";
import DefaultInventoryQuery from "../components/DefaultInventoryQuery";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { getUserHasAccess } from "../util/UserHelper";
import DefaultCycleCountQuery from "../components/DefaultCycleCountQuery";
import DefaultRequisitionQuery from "../components/DefaultRequisitionQuery";
import DefaultStoreLocation from "../components/DefaultStoreLocation";
import { clearSiteData } from "../util/ApiOptionsHelper";

const Settings: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [presentToast] = useIonToast();
  const [isOpen, setIsOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [relogin, setRelogin] = useState(false);
  const [languagesAccess, setLanguagesAccess] = useState(false);
  const router = useIonRouter();

  useEffect(() => {
    getUserHasAccess("w_setup_user", "Change Language").then((access) => {
      setLanguagesAccess(access);
    });
  }, []);

  const changePlantOrDatabase = () => {
    setIsOpen(true);
  };

  const confirmApiOptions = async (cancel: boolean, relogin?: boolean) => {
    setIsOpen(false);
    if (cancel) {
      presentToast({
        message:
          translatedMessages["NoChangesDBPlant"]?.MessageText ||
          "No changes to database or plant",
        duration: 1000,
        position: "top",
      });
    } else {
      setShowLoading(true);
      if (relogin) {
        setReload(false);
        setRelogin(true);
      } else {
        await initializeAbpSession();
        setRelogin(false);
        setReload(true);
      }
      setShowLoading(false);
    }
  };

  if (relogin) {
    setRelogin(false);
    globalThis.relogin = true;
    router.push("/logout");
  }

  if (reload) {
    setReload(false);
    //redirect to home
    window.location.href = "/home";
  }

  const clearSiteCache = async () => {
    setShowLoading(true);
    await clearSiteData();
    setShowLoading(false);
    presentToast({
      message:
        translatedMessages["CacheCleared"]?.MessageText || "Cache cleared",
      duration: 1000,
      position: "top",
    });
  };

  return (
    <IonPage>
      <PageHeader title={translations["lbl_settings"] || "Settings"} />
      <IonContent fullscreen>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={`${
            translations["lbl_refreshing_data"] || "Refreshing data..."
          }:`}
          duration={5000}
        />
        <IonList>
          <ThemeSelector />
          {languagesAccess && <Languages />}
          <DefaultWOQuery />
          <DefaultEquipmentQuery />
          <DefaultInventoryQuery />
          <DefaultCycleCountQuery />
          <DefaultRequisitionQuery />
          <DefaultStoreLocation />
        </IonList>
        <IonButton
          className="ion-padding"
          expand="block"
          color="medium"
          onClick={() => changePlantOrDatabase()}
        >
          {translations["lbl_change_database"] || "Change Plant/Database"}
        </IonButton>
        <IonButton
          className="ion-padding"
          expand="block"
          color="medium"
          onClick={() => {
            clearSiteCache();
            confirmApiOptions(false); // reload the page after clearing the site data
          }}
        >
          {translations["lbl_clear_ cache"] || "Clear Cache"}
        </IonButton>
        <ApiOptionsModal
          isOpen={isOpen}
          isInit={false}
          confirmCallback={confirmApiOptions}
        />
      </IonContent>
    </IonPage>
  );
};

export default withAITracking(reactPlugin, Settings, "Settings");
