import { useQueryExec } from "../hooks/useQueryExec";
import { IonItem, IonLabel } from "@ionic/react";
import DefaultQueries from "./DefaultQueries";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

const DefaultWOQuery: React.FC = () => {
  const { getQueryList, queryList } = useQueryExec(
    "Work Order",
    "Work Order",
    "cf_work_order"
  );
  const querySection = "QW--Work Order";
  const { translations } = useContext(TranslationsContext);

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">{`${
          translations["lbl_def_wo_query"] || "Default Work Order Query"
        }:`}</IonLabel>
        <DefaultQueries
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
        />
      </IonItem>
    </>
  );
};
export default DefaultWOQuery;
