// Based from these mocks: https://xd.adobe.com/view/7dff7af5-47c7-4fa4-bfa6-d661115fe118-28ac/screen/d77193ad-4c99-4892-94fa-b51d8f8604de/
import {
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import { TranslationsContext } from "../util/Translations";
import StoresWorkOrder from "../components/StoresWorkOrder";
import StoresRequisition from "../components/StoresRequisition";
import StoresAccount from "../components/StoresAccount";
import { getIssueAccess, getReturnAccess } from "../util/UserHelper";

const Stores: React.FC = () => {
  const issueMaterial = "issue";
  const issueReturn = "return";
  const { translations } = useContext(TranslationsContext);
  const [workOrderExpanded, setWorkOrderExpanded] = useState(true);
  const [requisitionExpanded, setRequisitionExpanded] = useState(false);
  const [accountExpanded, setAccountExpanded] = useState(false);
  const [storesTab, setStoresTab] = useState<string>(issueMaterial);
  const [issueAccess, setIssueAccess] = useState(false);
  const [returnAccess, setReturnAccess] = useState(false);

  const handleSegmentChange = async (storesSelect: string) => {
    setStoresTab(storesSelect);
  };

  const checkIssueAccess = async () => {
    if (await getIssueAccess()) {
      setIssueAccess(true);
    }
  };

  const checkReturnAccess = async () => {
    if (await getReturnAccess()) {
      setReturnAccess(true);
    }
  };

  useEffect(() => {
    checkIssueAccess();
    checkReturnAccess();
  }, []);

  useEffect(() => {
    if (workOrderExpanded) {
      setRequisitionExpanded(false);
      setAccountExpanded(false);
    }
  }, [workOrderExpanded]);

  useEffect(() => {
    if (requisitionExpanded) {
      setWorkOrderExpanded(false);
      setAccountExpanded(false);
    }
  }, [requisitionExpanded]);

  useEffect(() => {
    if (accountExpanded) {
      setWorkOrderExpanded(false);
      setRequisitionExpanded(false);
    }
  }, [accountExpanded]);

  return (
    <IonPage>
      <PageHeader title={translations["lbl_mob_stores"] || "Stores"} />
      <IonContent>
        <IonSegment
          value={storesTab}
          onIonChange={(ev) => {
            handleSegmentChange(String(ev.detail.value));
          }}
        >
          {issueAccess && (
            <IonSegmentButton value={issueMaterial}>
              <IonLabel>{translations["lbl_btn_issue"] || "Issue"}</IonLabel>
            </IonSegmentButton>
          )}
          {returnAccess && (
            <IonSegmentButton value={issueReturn}>
              <IonLabel>{translations["lbl_btn_return"] || "Return"}</IonLabel>
            </IonSegmentButton>
          )}
        </IonSegment>
        {/* Work Order Card */}
        <StoresWorkOrder
          expanded={workOrderExpanded}
          setExpanded={setWorkOrderExpanded}
          storesTab={storesTab}
        />
        {/* Requisition Card */}
        <StoresRequisition
          expanded={requisitionExpanded}
          setExpanded={setRequisitionExpanded}
          storesTab={storesTab}
        />
        {/* Account Type Card */}
        <StoresAccount
          expanded={accountExpanded}
          setExpanded={setAccountExpanded}
          storesTab={storesTab}
        />
      </IonContent>
    </IonPage>
  );
};

export default Stores;
