import {
  IonList,
  IonText,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonLoading,
  IonTitle,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeParam } from "../util/ApiHelper";
import { getWorkOrderPermit } from "../api/WorkOrders";
import { TranslationMessagesContext } from "../util/Translations";

const PermitsTab: React.FC = () => {
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [permits, setPermits] = useState<any[]>([]);
  const [permitsErrorState, setPermitsErrorState] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // Show loading symbol
    setShowLoading(true);
    loadWoPermits().finally(() => {
      setShowLoading(false);
    });
  },  [workOrderId]);

  const loadWoPermits = async () => {
    setPermits([]);
    setPermitsErrorState(false);
    await getWorkOrderPermit(displayWo).then((response) => {
      if (response.status !== 200) {
        setPermits([]);
        setPermitsErrorState(true);
      } else if (response.data) {
        if (response.data.CompletePermitDetails && response.data.CompletePermitDetails.length > 0) {
          var groupedPermitItems = response.data.CompletePermitDetails.reduce((grouped: any, item: any) => {
            item.permitItems.forEach((permitItem: any) => {
              if(permitItem.IsEnabled)
                (grouped[item.Category] = grouped[item.Category] || []).push(permitItem);
            });
            return grouped;
          }, {});
          setPermits(groupedPermitItems);
          setPermitsErrorState(false);
        } else {
          setPermits([]);
          setPermitsErrorState(false);
        }
      }
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
    });
  };

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      {!!permits && Object.keys(permits).length > 0 ? (
      Object.entries(permits).map(([category, permitItems], index) => {
        return (
          <div key={index}>
            <IonTitle className="permits-item-title">{category}</IonTitle>
            <IonList style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              gap: '40px'
            }}>
              {permitItems.map((item: any, index: number) => (
                <IonItem key={index} lines="none">
                  <IonCheckbox slot="start" checked disabled></IonCheckbox>
                  <IonLabel style={{ opacity: 1 }}>{item.Code}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </div>
        );
      })
      ) : (
        <IonText
          color={permitsErrorState ? "danger" : "default"}
          style={{ padding: "16px", display: "inline-block" }}
        >
          {permitsErrorState
            ? `${
                translatedMessages["ErrPermits"]?.MessageText ||
                "Error retrieving permits"
              }`
            : `${
                translatedMessages["NoResultsFound"]?.MessageText ||
                "No results found."
              }`}
        </IonText>
      )}
    </>
  );
};

export default PermitsTab;
