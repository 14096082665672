import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "MobileApproval";

export interface ProcessWorkOrderActionListData {
  RequestObject: {
    WorkOrderActionListData: {
      WoBase?: string;
      Status?: string | null;
      Comments?: string;
      Action?: string;
      NextReviewer?: string | null;
      AssignedTo?: string | null;
    }[];
  };
  Confirmation: any;
}

export interface GetReviewerData {
  plant: string;
  user: string | null;
  type: number;
}

export const changeStatus = async (data: ProcessWorkOrderActionListData) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/ProcessWorkOrderActionList`,
    data
  );
};

export const getRequisitionLines = async (reqNum: string) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetRequisitionLines`,
    undefined,
    {
      reqNum: reqNum,
    }
  );
};

export const getReviewers = async (data: GetReviewerData) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetReviewers`,
    undefined,
    {
      ...data,
      type: String(data.type),
    }
  );
};
