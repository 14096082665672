import { useQueryExec } from "../hooks/useQueryExec";
import { IonItem, IonLabel } from "@ionic/react";
import DefaultQueries from "./DefaultQueries";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

const DefaultRequisitionQuery: React.FC = () => {
  const { getQueryList, queryList } = useQueryExec(
    "Requisition",
    "Requisition",
    "requisition"
  );
  const querySection = "QW--Requisition";
  const { translations } = useContext(TranslationsContext);

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">
          {translations["lbl_mob_requisitionquery"] ||
            "Default Requisition Query"}
        </IonLabel>
        <DefaultQueries
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
        />
      </IonItem>
    </>
  );
};
export default DefaultRequisitionQuery;
