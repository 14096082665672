import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import PageHeader from "../components/PageHeader";
import {
  CriteriaParameter,
  maxResults,
  useQueryExec,
} from "../hooks/useQueryExec";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import WorkOrderResults from "../components/WorkOrderResults";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";

const OpenWorkOrders: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { pathname } = useLocation();
  const { equipId } = useParams<{ equipId: string }>(); // TODO: Set up possibility of other param names for other quick work order types
  const displayEquipId = decodeParam(equipId);
  const {
    showLoading,
    setShowLoading,
    showToast,
    setShowToast,
    executeSearch,
    queryRows,
    errorState,
    totalCount,
    getNoOfFieldsToDisplay,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Work Order", "Work Order", "cf_work_order", true);

  //to do: retrieve query name from config or settings
  const queryName: string = "SYS Open WOs for Equipment";
  const [inRefresh, setInRefresh] = useState(false);
  const [searchYn, setSearchYn] = useState(false);
  const [criteriaParams, setCriteriaParams] = useState<CriteriaParameter[]>();

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setInRefresh(true);
    event.detail.complete();
  }

  useEffect(() => {
    const criteria = [
      {
        ColumnAlias: "equipment",
        ComparisonExpression: displayEquipId,
        CriteriaSequence: 2,
        JoinID: 0,
        TableAlias: "wo_work_order",
      },
    ] as CriteriaParameter[];
    setCriteriaParams(criteria);
    setSearchYn(true);
  }, [inRefresh, displayEquipId]);

  // Establish the router link
  // Using If block to set up for other types of quick work orders later, such as serial
  let route = "";
  if (pathname.includes("requester")) {
    route = `/requester/create-request/equipment/${encodeParam(
      displayEquipId
    )}/create`;
  } else {
    route = `/quick-work-order/equipment/${encodeParam(
      displayEquipId
    )}/create`;
  }

  return (
    <IonPage>
      <PageHeader
        title={`${
          translations["lbl_category_equipment"] || "Equipment"
        } ${displayEquipId}`}
        subtitle={translations["lbl_open_work_orders"] || "Open Work Orders"}
        showBack
      />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading && !inRefresh}
          onDidDismiss={() => setShowLoading(false)}
          duration={2000}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={
            translatedMessages["DisplayFirst"]?.MessageText.replace(
              "@1",
              maxResults
            ).replace(
              "@2 of @3",
              translations["lbl_WorkOrders"] || "work orders"
            ) ||
            `Displaying the first ${maxResults} ${
              translations["lbl_WorkOrders"] || "work orders"
            }.`
          }
          duration={2000}
          position="top"
        />
        <WorkOrderResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          criteria={criteriaParams}
          queryRows={queryRows}
          executeSearch={executeSearch}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          filterMethod={execFilter}
          filterString={resultsFilter}
          filterRows={filterRows}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              fill="solid"
              routerLink={`/Equipment/${encodeParam(displayEquipId)}`}
              routerDirection="back"
            >
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              fill="solid"
              routerLink={route}
              routerDirection="forward"
            >
              {translations["lbl_btn_next"] || "Next"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default OpenWorkOrders;
