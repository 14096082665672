import { HttpMethods, sendRequest } from "../util/ApiHelper";

interface BarCodeResult {
  NoMatch?: boolean;
  FirstCapturingGroup?: string;
  CapturingGroupValues?: { [key: string]: any };
}
const elementTypeToFind = "BAR";

export async function performBarCodeShimProcess(
  barCodeFieldData: any,
  scanResult: string
) {
  let result: BarCodeResult = {};
  if (!barCodeFieldData?.BarcodeShimData) {
    result.NoMatch = true;
    return result;
  }
  const sybarCodeData = barCodeFieldData.BarcodeShimData;
  for (const shimData of sybarCodeData) {
    if (!shimData.Regexp) continue;
    const match = new RegExp(shimData.Regexp).exec(scanResult);
    if (!match) continue;
    if (shimData.Formatexp) {
      return processFormatExpression(
        match,
        shimData.Formatexp,
        shimData.Mapexp
      );
    }
    if (shimData.Sqlexp) {
      const response = await processBarCodeSQLExpression(shimData, match);
      let mapExp = shimData.mapExp ? shimData.Mapexp.split("|") : null;
      return getFormatExpressionValues(
        updateMapExpToPropertyNames(mapExp),
        response.data.BarcodeResult.split("|")
      );
    }
    result.FirstCapturingGroup = match[1];
    return result;
  }
  result.NoMatch = true;
  return result;
}

function processFormatExpression(
  match: string[],
  formatExp: string,
  mapExp: string
) {
  let result: BarCodeResult = {};
  if (!mapExp) {
    result.NoMatch = true;
    return result; // If Format exp is there and there  is no map exp return the actual input
  }
  const mapExpValues = updateMapExpToPropertyNames(mapExp.split("|")),
    formatExpRes = formatExp.replace(
      /\$(\d+)/g,
      (_, index) => match[parseInt(index, 10)] || ""
    ),
    formatExpValues = formatExpRes.split("|");
  return getFormatExpressionValues(mapExpValues, formatExpValues);
}

function updateMapExpToPropertyNames(mapExpValues: string[]) {
  if (!mapExpValues) return [];
  let propertyNamedMapExp: string[] = [];
  mapExpValues.forEach((mapExpVal) => {
    propertyNamedMapExp.push(convertToCamelCase(mapExpVal));
  });
  return propertyNamedMapExp;
}

function convertToCamelCase(propertyName: string) {
  return propertyName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
}

function getFormatExpressionValues(
  mapExpValues: string[],
  formatExpValues: string[]
) {
  let result: BarCodeResult = {};
  if (formatExpValues.length === 1 && !formatExpValues[0]) {
    result.NoMatch = true;
    return result;
  }
  if (mapExpValues.length <= 1) {
    result.FirstCapturingGroup = formatExpValues[0];
    return result;
  }
  result.CapturingGroupValues = {};
  let i = 0;
  mapExpValues.forEach((mapExpVal: any) => {
    if (result.CapturingGroupValues) {
      result.CapturingGroupValues[`${mapExpVal}`] = formatExpValues[i];
    }
    i++;
  });
  return result;
}

export function findObjectByElementType(fields: any) {
  if (!fields) {
    return null;
  }
  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      const field = fields[key];
      if (field.ElementType === elementTypeToFind) {
        return field;
      }
    }
  }
  return null;
}

export const processBarCodeSQLExpression = async (
  shimData: any,
  match: string[]
) => {
  const data = {
    WindowName: shimData.WindowName,
    TableName: shimData.TableName,
    ColumnName: shimData.ColumnName,
    Order: shimData.Order,
    SqlParameters:
      match.length === 1
        ? [match[0]]
        : match
            .slice(1)
            .filter((value) => value !== undefined && value !== null),
  };
  return await sendRequest(
    HttpMethods.POST,
    `BarCodes/ProcessBarCodeSQLExpression`,
    data
  );
};
