import React, { useEffect, useState } from "react";
import { FilterParam } from "../hooks/useQueryExec";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import { ResultsParamBase } from "../models/common/ResultsParamBase";
import QueryResults from "./QueryResults";
import InventoryActionSheet from "./InventoryActionSheet";

interface InventoryResultsParams extends ResultsParamBase {
  cardClick?: (item: string) => void;
}

const InventoryResults: React.FC<InventoryResultsParams> = (props) => {
  const {
    searchYn,
    setSearchYn,
    queryName,
    queryParams,
    criteria,
    queryRows,
    filterParams,
    setParameter,
    executeSearch,
    getNoOfFieldsToDisplay,
    errorState,
    totalCount,
    showLoading,
    setShowLoading,
    filterRows,
    filterMethod,
    filterString,
    cardClick,
  } = props;
  const [cardHeader, setCardHeader] = useState<string>("");
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [openStoresActionSheet, setOpenStoresActionSheet] = useState(false);

  const setQueryParams = async (queryParams: FilterParam[]) => {
    queryParams.forEach((p) => {
      setParameter(p.ColumnName, p.ParameterValue);
    });
  };

  const beginSearch = async () => {
    if (!!searchYn) {
      await executeSearch(queryName, criteria);
      setSearchExecuted(true);
      setSearchYn(false);
    }
  };

  const openActionSheet = async (header: string) => {
    setCardHeader(header);
    setOpenStoresActionSheet(true);
  };

  useEffect(() => {
    setShowLoading(true);
    Promise.all([
      setQueryParams(queryParams),
      getNoOfFieldsToDisplay("NoOfFieldsToDisplay"),
      beginSearch(),
    ]).finally(() => {
      setSearchExecuted(false);
      setShowLoading(false);
    });
  }, [searchYn, searchExecuted]);

  return (
    <>
      <QueryResults
        searchYn={searchYn}
        setSearchYn={setSearchYn}
        queryName={queryName}
        queryParams={filterParams}
        criteria={criteria}
        queryRows={queryRows}
        filterParams={filterParams}
        setParameter={setParameter}
        executeSearch={executeSearch}
        getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
        errorState={errorState}
        totalCount={totalCount}
        showLoading={showLoading}
        setShowLoading={setShowLoading}
        filterRows={filterRows}
        filterMethod={filterMethod}
        filterString={filterString}
        openActionSheet={openActionSheet}
        cardClick={cardClick}
      />
      {!cardClick && (
        <InventoryActionSheet
          openStoresActionSheet={openStoresActionSheet}
          setOpenStoresActionSheet={setOpenStoresActionSheet}
          cardHeader={cardHeader}
        />
      )}
    </>
  );
};

export default withAITracking(
  reactPlugin,
  InventoryResults,
  "InventoryResults"
);
