import {
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon,
    IonLoading,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import SingleQueryPage from "../components/SingleQueryPage";
import { getTimeEntryAccess, getUserId } from "../util/UserHelper";
import PageHeader from "../components/PageHeader";
import { add } from "ionicons/icons";
import TimeEntry from "../pages/TimeEntry";
import {
    TranslationsContext,
} from "../util/Translations";
import { AuthContext } from "../util/OAuth";

const TimeEntryMenu: React.FC = () => {
    const { translations } = useContext(TranslationsContext);
  const [queryName, setQueryName] = useState("");
  const { loggedIn } = useContext(AuthContext);

  const setQueryNameForTimeEntry = async () => {
      setQueryName("SYS_Weekly Time Entries");
  };
    const [refreshState, setRefreshState] = useState(false); // Used to trigger the after log deletion so that the tab refreshes
    const [objectID, setObjectID] = useState<string>("");
    const [pathId, setPathId] = useState<string>("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [inRefresh, setInRefresh] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [timeAccess, setTimeAccess] = useState(false);

    const getTimeAccess = async () => {
        const userAccess = await getTimeEntryAccess();
        setTimeAccess(userAccess);
    };

    const onModalCancel = () => {
        setIsModalOpen(false);
    };
    const onModalConfirm = () => {
        setIsModalOpen(false);
        setRefreshState(true);
    };

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setInRefresh(true);
        event.detail.complete();
    }

    useEffect(() => {
        getTimeAccess();
        setQueryNameForTimeEntry();
    },[queryName, translations, loggedIn, inRefresh, refreshState]);

    return (
        <>
            <IonPage>
                <PageHeader
                    title={translations["lbl_weekly_time_entries"] || "Weekly Time Entries"}
                ></PageHeader>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    duration={5000}
                />
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {(objectID || buttonClicked) && (
                    <TimeEntry
                        isOpen={isModalOpen}
                        cancel={onModalCancel}
                        confirm={onModalConfirm}
                        objectID={objectID}
                        pathId="FromTimeEntryMenu"
                    />
                )}
                <IonContent>
                    <SingleQueryPage queryName={queryName} />
                    <IonFab vertical="bottom" horizontal="end" slot="fixed">
                        <IonFabButton
                            disabled={!timeAccess}
                            onClick={() => {
                                setButtonClicked(true);
                                setObjectID("");
                                setPathId("FromTimeEntryMenu");
                                setIsModalOpen(true);
                            }}
                        >
                            <IonIcon icon={add}></IonIcon>
                        </IonFabButton>
                    </IonFab>
                </IonContent>
            </IonPage>
        </>
    );
};

export default TimeEntryMenu;
