import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "Windows";

export interface ColumnsWithGroupData {
    WindowName?: string;
    TableName?: string;
    ContextPKey?: any;
    IncludeValidValues?: boolean | string;
};

export const getColumnsWithGroup = async (data: ColumnsWithGroupData) => {
    return await sendRequest(HttpMethods.POST,
        `${CONTROLLER_NAME}/GetColumnsWithGroup`,
        data
    );
};

export const getCompleteColumnDetailList = async (data: ColumnsWithGroupData) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/GetCompleteColumnDetailList`,
        data
    );
};

