import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "PlantSetups";

export const getPlantCurrency = async () => {
  return await sendRequest(HttpMethods.GET, `${CONTROLLER_NAME}/PlantCurrency`);
};
export const getInventoryControl = async () => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/InventoryControl`
  );
};
