import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import "./TabPage.css";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import { useParams } from "react-router";
import { decodeParam, encodeParam } from "../util/ApiHelper";

interface TabPageProps {
  content?: any;
  title?: string;
  altHeaderTitle?: string;
  route?: string;
}

const MetersTabPage: React.FC<TabPageProps> = (props) => {
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const { title, content } = props;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/workorder/${encodeParam(displayWo)}/meters`}
              icon={chevronBackOutline}
            />
          </IonButtons>
          <IonTitle class="tab-page-title">{title || ""}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>{content}</IonContent>
    </IonPage>
  );
};

export default withAITracking(reactPlugin, MetersTabPage, "MetersTabPage");
