import React from "react";
import { getAllTranslations } from "../api/Translations";
import { translateMessageList } from "../api/Languages";
import { translatedValues } from "./TranslateMessagesHelper";

interface TranslationsContent {
  translations: any;
  setTranslations?: (translations: any) => void;
}

interface TranslationMessagesContent {
  translatedMessages: any;
  setTranslatedMessages?: (translatedMessages: any) => void;
}

export const TranslationsContext = React.createContext<TranslationsContent>({
  translations: {},
  setTranslations: undefined,
});

export const TranslationMessagesContext =
  React.createContext<TranslationMessagesContent>({
    translatedMessages: {},
    setTranslatedMessages: undefined,
  });

export const getTranslations = async () => {
  const response = await getAllTranslations();
  return response.data?.translation || [];
};

export const getTranslatedMessages = async () => {
  const response = await translateMessageList(translatedValues());
  return response.data || [];
};
