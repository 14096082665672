import { BinDataForAdjustments, MaterialIssueListItem, MaterialsAccount } from "../models/materialissue/MaterialIssue";
import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "Materials";

export interface MaterialIssueBody {
  PlanningRequisition?: string;
  WorkOrder?: string;
  IssueDate?: string;
  IssueToUser?: string;
  Account?: MaterialsAccount;
  MaterialIssueList?: MaterialIssueListItem[];
}

export interface BinCountData {
  Item: string;
  StoresLocation: string;
  BinDataForAdjustments: BinDataForAdjustments[];
}

export interface TransferBinData {
  Item: string;
  SourceBin: string;
  StoresLocation: string;
  TargetBin: string;
  TransactionQuantity: number;
}

export interface PlannedListParams {
  requisition?: string;
  storesLocation: string;
  workOrder: string;
}

export interface ReturnMaterialsBody {
  WorkOrder?: string;
  ReturnDate?: string;
  ReturnFromUser?: string;
  Account?: MaterialsAccount;
  MaterialReturnList?: MaterialIssueListItem[];
}

export const issueMaterials = async (data: MaterialIssueBody) => {
  return await sendRequest(HttpMethods.POST, `${CONTROLLER_NAME}/Issue`, data);
};

export const postMaterialsCount = async (data: BinCountData) => {
  return await sendRequest(HttpMethods.POST, `${CONTROLLER_NAME}/Count`, data);
};

export const adjustTransactionForIssue = async (data: BinCountData) => {
  return await sendRequest(HttpMethods.POST, `${CONTROLLER_NAME}/AdjustTransactionForIssue`, data);
};

export const transferBin = async (data: TransferBinData) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/TransferBin`,
    data
  );
};
export const getPlannedList = async (params: PlannedListParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/PlannedList`,
    undefined,
    params
  );
};

export const returnMaterials = async (data: ReturnMaterialsBody) => {
  return await sendRequest(HttpMethods.POST, `${CONTROLLER_NAME}/Return`, data);
};
