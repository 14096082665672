import { IonButton, IonIcon } from "@ionic/react";
import { barcode, barcodeOutline, barcodeSharp } from "ionicons/icons";
import React, { useContext, useEffect } from "react";
import { useBarcodeManager } from "../hooks/useBarcodeManager";
import { TranslationsContext } from "../util/Translations";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";

export interface BarcodeInputProps {
  onScan: (code?: string) => void;
  size?: "small" | "default" | "large";
  textButton?: boolean;
  expand?: "block" | "full";
}

const BarcodeScannerButton: React.FC<BarcodeInputProps> = (props) => {
  const { onScan, size, textButton, expand } = props;
  const { stopScan } = useBarcodeManager();
  const { translations } = useContext(TranslationsContext);

  const startScan = async () => {
    await BarcodeScanner.checkPermission({ force: true });
    await BarcodeScanner.prepare();
    document.body.classList.add("qrscanner");
    const result = await BarcodeScanner.startScan();
    if (result.hasContent) {
      onScan(result.content);
    }
    stopScan();
  };

  useEffect(() => {
    window.addEventListener("stop-barcode-scanning", () => {
      stopScan();
    });
  }, []);

  return (
    <IonButton
      onClick={startScan}
      size={size || "default"}
      color="primary"
      fill={textButton ? "outline" : "solid"}
      expand={expand}
    >
      <IonIcon
        slot={textButton ? "start" : "icon-only"}
        ios={textButton ? barcodeOutline : barcode}
        md={textButton ? barcodeSharp : barcode}
        size={size || "default"}
      />
      {textButton && (translations["lbl_mob_scanbarcode"] || "Scan Barcode")}
    </IonButton>
  );
};

export default BarcodeScannerButton;
