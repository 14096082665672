import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ValidValue } from "../models/common/ValidValue";
import { useContext, useEffect, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import "./SelectWithAllOption.css";

interface SelectWithAllOptionProps {
  title: string;
  validValues?: ValidValue[];
  comparisonExpression?: string;
  onConfirm: (comparisonExpression: string) => void;
}

const SelectWithAllOption: React.FC<SelectWithAllOptionProps> = (props) => {
  const { title, validValues, comparisonExpression, onConfirm } = props;
  const { translations } = useContext(TranslationsContext);
  const [isOpen, setIsOpen] = useState(false);
  // What the user is planning on selecting.
  const [stateSelected, setStateSelected] = useState<string[]>([]);
  // What stateSelected will be reverted to if the user cancels.
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      // Selects default values/previously confirmed selections whenever the modal is opened.
      resetStateSelected();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!comparisonExpression || !validValues) {
      setSelected([]);
    } else {
      const comparisonExpressionToArray = comparisonExpression.split(',');
      // Reading the ComparisonExpression to record any selected valid values.
      const selectedValues = validValues.filter((value) => {
        // Because the comparison expression separates codes by comma, we need to account for codes potentially having commas.
        if (value.Code.includes(',')) {
          return comparisonExpression.includes(value.Code);
        }
        return comparisonExpressionToArray.includes(value.Code);
      });
      const codes = selectedValues.map((value) => value.Code);
      setSelected(codes);
    }
  }, [comparisonExpression]);

  const resetStateSelected = () => {
    setStateSelected(selected);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onCancel = () => {
    setIsOpen(false);
    resetStateSelected();
  };

  const onConfirmClick = () => {
    onConfirm(stateSelected.toString());
    setIsOpen(false);
  };

  const onSelectAllClick = () => {
    if (stateSelected.length === validValues?.length) {
      setStateSelected([]);
    } else {
      const allCodes = validValues?.map((value) => value.Code);
      setStateSelected(allCodes || []);
    }
  };

  const onValueClick = (code: string, isChecked: boolean) => {
    if (!isChecked) {
      setStateSelected([...stateSelected, code]);
    } else {
      const newArray = stateSelected.filter((value) => {
        return value !== code;
      });
      setStateSelected(newArray);
    }
  };

  return (
    <>
      <IonButton
        onClick={toggleModal}
        fill="clear"
        size="default"
        style={{ maxWidth: "50%" }}
      >
        <span className="select-with-all-option-button">
          {selected.length > 0
            ? selected.join(", ")
            : translations["lbl_btn_select"] || "Select"}
        </span>
      </IonButton>

      <IonModal isOpen={isOpen} onIonModalWillDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                {translations["lbl_btn_cancel"] || "Cancel"}
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onConfirmClick}>
                {translations["lbl_confirm"] || "Confirm"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonItem onClick={onSelectAllClick} lines="none">
              <IonCheckbox
                slot="start"
                checked={stateSelected.length === validValues?.length}
              />
              <IonLabel>
                {translations["lbl_btn_selAll"] || "Select All"}
              </IonLabel>
            </IonItem>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {validValues &&
              validValues.map((value) => {
                const checked = stateSelected.includes(value.Code);
                return (
                  <IonItem
                    key={value.Code}
                    onClick={() => {
                      onValueClick(value.Code, checked);
                    }}
                  >
                    <IonCheckbox slot="start" checked={checked} />
                    <IonLabel>{`${value.Code} - ${value.Description}`}</IonLabel>
                  </IonItem>
                );
              })}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default SelectWithAllOption;
