import React, { useContext, useEffect, useState } from "react";
import {
  GetColumnsWithGroupResponse,
  GetColumnsWithGroupField,
} from "../models/windows/GetColumnsWithGroup";
import {
  IonCard,
  IonCardContent,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { Employee } from "../models/employees/Employees";
import { TranslationsContext } from "../util/Translations";
import EmployeeSearch from "./EmployeeSearch";
import CardExpandableHeader from "./CardExpandableHeader";

interface MaterialIssueDetailsProps {
  columns: GetColumnsWithGroupResponse;
  storeLocation?: string;
  setStoreLocation: (store: string) => void;
  employeeId?: Employee;
  setEmployeeId: (id: Employee) => void;
  employees?: Employee[];
  date?: string;
  setDate: (date: string) => void;
  mode: "issue" | "return";
  displayWo?: string;
  displayReq?: string;
}

const MaterialIssueDetails: React.FC<MaterialIssueDetailsProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const {
    columns,
    storeLocation,
    setStoreLocation,
    employeeId,
    setEmployeeId,
    employees,
    date,
    setDate,
    mode,
    displayWo,
    displayReq,
  } = props;
  const [storeLocationField, setStoreLocationField] =
    useState<GetColumnsWithGroupField>();
  const [employeeIdField, setEmployeeIdField] =
    useState<GetColumnsWithGroupField>();
  const [dateField, setDateField] = useState<GetColumnsWithGroupField>();
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const issueDetailText =
    translations["lbl_mob_issuedetails"] || "Issue Details";

  useEffect(() => {
    if (columns) {
      const storeLocationField: GetColumnsWithGroupField =
        columns?.fields?.["in_log_material.stores_location"];
      storeLocationField && setStoreLocationField(storeLocationField);
      // Default to only store location
      if (
        storeLocationField?.ValidValues &&
        storeLocationField.ValidValues.length === 1
      ) {
        const store = storeLocationField.ValidValues[0];
        setStoreLocation(store.Code);
      }
      const employeeIdField = columns?.fields?.["in_log_material.employee_id"];
      employeeIdField && setEmployeeIdField(employeeIdField);

      const dateField = columns?.fields?.["in_log_material.date_charged"];
      dateField && setDateField(dateField);
    }
  }, [columns]);

  const onStoreLocationSelect = (value?: string) => {
    value && setStoreLocation(value);
  };
  const onEmployeeIdSelect = (value?: Employee) => {
    value && setEmployeeId(value);
    setUserSearchOpen(false);
  };
  const onDateSelect = (value?: string) => {
    value && setDate(value);
  };

  return (
    <>
      <IonCard class="ion-no-margin">
        <CardExpandableHeader
          expanded={expanded}
          onExpandClick={setExpanded}
          title={
            mode === "issue"
              ? !!displayWo
                ? issueDetailText + " for Work Order"
                : !!displayReq
                ? issueDetailText + " for Requisition"
                : issueDetailText + " for Account"
              : translations["lbl_mob_returndetails"] || "Return Details"
          }
        />
        <IonCardContent
          style={{ padding: 16, paddingTop: 0 }}
          hidden={!expanded}
        >
          <IonItem
            lines="full"
            button
            onClick={() => {
              setUserSearchOpen(true);
            }}
          >
            <IonLabel>{employeeIdField?.TranslatedIdText + "*"}</IonLabel>
            <div slot="end">{employeeId?.EmployeeId}</div>
          </IonItem>

          <IonItem lines="full">
            <IonLabel>{dateField?.TranslatedIdText + "*"}</IonLabel>
            <IonModal
              keepContentsMounted
              key={dateField?.ColumnName + "-modal"}
            >
              <IonDatetime
                id={`criteria-date-${dateField?.ColumnName}`}
                presentation="date"
                value={date}
                onIonChange={(e) => {
                  onDateSelect(String(e.detail.value));
                }}
              />
            </IonModal>
            <IonDatetimeButton
              datetime={`criteria-date-${dateField?.ColumnName}`}
              key={dateField?.ColumnName + "-button"}
            ></IonDatetimeButton>
          </IonItem>

          <IonItem lines="full">
            <IonLabel>{storeLocationField?.TranslatedIdText + "*"}</IonLabel>
            <IonSelect
              value={storeLocation}
              onIonChange={(ev) => {
                onStoreLocationSelect(ev.detail.value);
              }}
              interface="popover"
            >
              {storeLocationField?.ValidValues?.map((value) => {
                return (
                  <IonSelectOption
                    value={value.Code}
                    key={`store-location-${value.Code}-${value.Description}`}
                  >
                    {value.Code}
                    {value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonCardContent>
      </IonCard>
      <EmployeeSearch
        isOpen={userSearchOpen}
        employees={employees || []}
        onSelect={onEmployeeIdSelect}
        onCancel={() => {
          setUserSearchOpen(false);
        }}
        title={employeeIdField?.TranslatedIdText}
      />
    </>
  );
};

export default MaterialIssueDetails;
