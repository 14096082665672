import {
  IonButton,
  IonCard,
  IonCardContent,
  IonList,
  IonSearchbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import React, { useContext, useState, useRef } from "react";
import { findRequisitions } from "../api/Requisitions";
import { encodeParam } from "../util/ApiHelper";
import { TranslationsContext } from "../util/Translations";
import CardExpandableHeader from "../components/CardExpandableHeader";
import BarcodeScannerButton from "./BarcodeScannerButton";
import { StoresProps } from "../models/stores/Stores";
import StoresPopover from "./StoresPopover";

const StoresRequisition: React.FC<StoresProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { expanded, setExpanded, storesTab } = props;
  const popover = useRef<HTMLIonPopoverElement>(null);
  const router = useIonRouter();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [presentAlert] = useIonAlert();

  const handleExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const handleRequisitionsSearch = (e: CustomEvent) => {
    let newVal = e.detail.value;
    setSearchValue(newVal);
    if (newVal.length >= 2) {
      findRequisitions(newVal).then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            popover.current!.event = e;
            setPopoverOpen(true);
          } else if (popoverOpen) {
            setPopoverOpen(false);
          }
          setSearchResults(res.data);
        }
      });
    } else {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    }
  };

  const onBarcodeScan = (text?: string) => {
    if (text) {
      router.push(
        `/Stores/Requisitions/${encodeParam(text)}/${storesTab}`
      );
    } else {
      presentAlert({
        message: "Requisition could not be found",
        buttons: ["OK"],
      });
    }
  };

  return (
    <>
      <IonCard>
        <CardExpandableHeader
          expanded={expanded}
          onExpandClick={handleExpand}
          title={translations["lbl_reqs"] || "Requisitions"}
        />
        <IonCardContent hidden={!expanded} style={{ paddingTop: 0 }}>
          <IonList>
            {/**Regular search */}
            <IonSearchbar
              placeholder={
                translations["lbl_mob_enterrequisition"] ||
                "Enter Requisition Number"
              }
              showClearButton="focus"
              inputMode="text"
              type="text"
              id="req-searchbar"
              value={searchValue}
              onIonChange={handleRequisitionsSearch}
              debounce={500}
            ></IonSearchbar>
            {/**Run Query */}
            <IonButton
              color="primary"
              expand="full"
              routerLink={`/Stores/Requisitions/${storesTab}`}
            >
              {translations["lbl_btn_mob_advancedsearch"] || "Advanced Search"}
            </IonButton>
            {/**Barcode Scan */}
            <BarcodeScannerButton
              textButton
              onScan={onBarcodeScan}
              expand="block"
            />
          </IonList>
        </IonCardContent>
      </IonCard>
      <StoresPopover
        popover={popover}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        storesTab={storesTab}
      />
    </>
  );
};

export default StoresRequisition;
