import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonToggle,
  useIonAlert,
  IonicSafeString,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { completeWO, getCompletionDataFieldDef } from "../api/CompletionData";
import {
  GetColumnsWithGroupField,
  ValidValue,
} from "../models/windows/GetColumnsWithGroup";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import { getUserId } from "../util/UserHelper";
import { WorkOrder } from "../models/workorders/WorkOrder";
import { TranslationsContext } from "../util/Translations";
import { getWorkOrder } from "../api/WorkOrders";
import ApiError from "./ApiError";
import { Employee } from "../models/employees/Employees";
import { getEmployeesLiteList } from "../api/Employees";
import { getOptionByIdWithCORP } from "../api/TabwareOptions";
import { getExistingCodes, getCCAFieldDefinitions } from "../api/AnalysisCodes";

const CompletionData: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  const history = useHistory();
  const displayWo = decodeParam(workOrderId);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [toastStatus, setToastStatus] = useState<"success" | "danger">();
  const [workOrder, setWorkOrder] = useState<WorkOrder | undefined>();
  const [fields, setFields] = useState<GetColumnsWithGroupField[]>([]);
  const [validStatusValues, setValidStatusValues] = useState<ValidValue[]>([]);
  const [status, setStatus] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const [startDateTime, setStartDateTime] = useState<string>();
  const [endDateTime, setEndDateTime] = useState<string>();
  const [lostProduction, setLostProduction] = useState<string>();
  const [productionUom, setProductionUom] = useState<string>();
  const [failure, setFailure] = useState<boolean>(false);
  const [followUpFlag, setFollowUpFlag] = useState<boolean>(false);
  const [errorData, setErrorData] = useState("");
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [comments, setComments] = useState<string>();
  const commentField = fields.find((field) => field.ColumnName === "remarks");
  const [presentAlert] = useIonAlert();
  
  useEffect(() => {
    if (displayWo) {
      setup(displayWo);
    }
  }, [displayWo]);

  const setup = async (workOrderId: string) => {
    setShowLoading(true);
    const [woResponse, metadata, employees, defaultStatus] = await Promise.all([
      getWorkOrder({ woNumber: workOrderId, events: true }),
      getCompletionDataFieldDef(),
      getEmployees(),
      getDefaultStatus(),
    ]);
    if (woResponse.isError) {
      setErrorData(woResponse.data);
    } else {
      const workOrder = woResponse.data as WorkOrder;
      setWorkOrder(workOrder);
      // TODO: Catches for if the metadata doesn't come back properly
      const fields = getFields(metadata.data);
      setFields(fields);
      setEmployees(employees);
      // Narrow down the valid values for status to >= 70 and < 80. These are the only accepted statuses for completion.
      const statusField = fields.find((field) => {
        return field.ColumnName === "status";
      });
      if (statusField && statusField.ValidValues) {
        const values = statusField.ValidValues.filter((value) => {
          return +value.Code >= 70 && +value.Code < 80;
        });
        setValidStatusValues(values);
      }
      workOrder && setExistingValues(workOrder, defaultStatus);
    }
    setShowLoading(false);
  };

  const getFields = (metadata: any) => {
    const fields: GetColumnsWithGroupField[] = [];
    const keys = Object.keys(metadata?.fields);
    for (const key of keys) {
      const field = metadata?.fields[key] as GetColumnsWithGroupField;
      fields.push(field);
    }
    return fields.sort((a, b) => {
      return +a.FieldOrder - +b.FieldOrder;
    });
  };

  const getEmployees = async () => {
    const data = {
      PropertyList: ["EmployeeName", "EmployeeId"],
      Filter: "",
    };
    const response = await getEmployeesLiteList(data);
    return response.data as Employee[];
  };

  const getDefaultStatus = async () => {
    const response = await getOptionByIdWithCORP("WOCompletionStatus");
    const defaultStatus =
      response.data?.PlantValue || response.data?.DefaultValue;
    return defaultStatus as string | undefined;
  };

  const setExistingValues = async (
    workOrder: WorkOrder,
    defaultStatus?: string
  ) => {
    const status = workOrder.Status;
    if (status && status >= 70 && status < 80) {
      setStatus(String(status));
    } else {
      setStatus(defaultStatus);
    }

    const events = workOrder.Events;
    const alreadyCompleted = !!events?.WoCompleteFlag;
    const userId = alreadyCompleted ? events.UserId : await getUserId();
    if (userId) {
      setUserId(userId);
      if (workOrder && workOrder.Events) {
        workOrder.Events.EmployeeId = userId;
      }
    } else {
      setUserId(undefined);
    }

    const startDate = moment.utc(events?.StartDate);
    if (startDate.isValid() && events?.StartDate !== "0001-01-01T00:00:00") {
      setStartDateTime(
        startDate.local().format(moment.HTML5_FMT.DATETIME_LOCAL)
      );
    } else {
      setStartDateTime(undefined);
    }

    const endDate = moment.utc(events?.EventEndDate);
    if (
      endDate.isValid() &&
      events?.EventEndDate !== "0001-01-01T00:00:00" &&
      alreadyCompleted
    ) {
      setEndDateTime(endDate.local().format(moment.HTML5_FMT.DATETIME_LOCAL));
    } else {
      setEndDateTime(moment().local().format(moment.HTML5_FMT.DATETIME_LOCAL));
    }

    const lostProduction = events?.ProductionLost;
    if (lostProduction && alreadyCompleted) {
      setLostProduction(String(lostProduction));
    } else {
      setLostProduction(undefined);
    }

    const productionUom = workOrder.EquipmentMaster?.ProductionUom;
    if (productionUom && alreadyCompleted) {
      setProductionUom(String(productionUom));
    } else {
      setProductionUom(undefined);
    }

    const failure = events?.FailureFlag;
    setFailure(!!failure && alreadyCompleted);

    const followUpFlag = events?.FollowupRequiredFlag;
    setFollowUpFlag(!!followUpFlag && alreadyCompleted);
  };

  const onSubmit = async () => {
    const Status = !!status ? +status : null;
    const UserId = !!userId ? userId : null;
    const StartDate = !!startDateTime
      ? moment(startDateTime).utc().toISOString()
      : null;
    const StartTime = !!startDateTime
      ? moment(startDateTime).utc().format("hh:mm")
      : null;
    const EventEndDate = !!endDateTime
      ? moment(endDateTime).utc().toISOString()
      : null;
    const EventEndTime = !!endDateTime
      ? moment(endDateTime).utc().format("hh:mm")
      : null;
    const EventStatus = "Closed";
    const ProductionLost = !!lostProduction ? +lostProduction : null;
    const ProductionUom = !!productionUom ? productionUom : null;
    const FailureFlag = String(failure);
    const FollowupRequiredFlag = String(followUpFlag);
    const Remarks = comments;
    const body = {
      Confirmation: {
        RuleConfirmationDetailsList: [],
      },
      RequestObject: {
        WorkOrder: {
          ...workOrder,
          Status,
          Events: {
            ...workOrder?.Events,
            UserId,
            StartDate,
            StartTime,
            EventStatus,
            EventEndDate,
            EventEndTime,
            ProductionLost,
            FailureFlag,
            FollowupRequiredFlag,
            Comment: {
              Remarks,
            },
          },
          EquipmentMaster: {
            ...workOrder?.EquipmentMaster,
            ProductionUom,
          },
        },
      },
    };
    setShowLoading(true);
    const fields = await getCCAFieldDefinitions(workOrder?.Equipment);
    if (fields.componentField?.IsRequired || fields.actionField?.IsRequired || fields.conditionField?.IsRequired) {
      const dateCreated = workOrder?.Events?.DateCreated;
      const timeCreated = workOrder?.Events?.TimeCreated;
      if (dateCreated && timeCreated) {
        const existingCodes = await getExistingCodes(dateCreated, timeCreated);
        if (existingCodes && existingCodes.length > 0) {
          completeWo(body);
        }
        else {
          presentAlert(`CCA is Required`);
          setShowLoading(false);
          return;
        }
      }
    }
    else {
      completeWo(body);
    }
  };
 
  const completeWo = async (body: any) => {
    const response = await completeWO(body);
    if (response.data && response.data.MessageList) {
      const messages: string[] = response.data?.MessageList?.map(
        (message: { Text: string }) => {
          return message.Text;
        }
      );
      setToastStatus(response.data.Success ? "success" : "danger");
      setToastMessage(
        `<span style="overflow-wrap:anywhere">${messages
          .join(" ")
          .replace(/\r\n/g, "<br>")}</span>`
      );
      setShowToast(true);
      if (response.data.Success && followUpFlag) {
        //Navigate to create work order if the follow up required flag is true.
        history.push(
          `/quick-work-order/equipment/${encodeParam(
            `${workOrder?.Equipment}`
          )}/${encodeParam(workOrderId)}/create`
        );
      }
    }
    const woResponse = await getWorkOrder({ woNumber: displayWo });
    if (woResponse.isError) {
      setErrorData(woResponse.data);
    } else {
      const updatedWorkOrder = woResponse.data as WorkOrder;
      updatedWorkOrder && setExistingValues(updatedWorkOrder);
    }
    setShowLoading(false);
  };

  return (
    <>
      <ApiError errorData={errorData} />
      <IonLoading isOpen={showLoading} />
      <IonToast
        isOpen={showToast}
        duration={5000}
        position="top"
        onDidDismiss={() => {
          setShowToast(false);
        }}
        color={toastStatus}
        message={new IonicSafeString(`${toastMessage}`)}
      />
      <IonList lines="full">
        {fields.map((field, index) => {
          if (
            field.ColumnName === "start_time" ||
            field.ColumnName === "event_end_time"
          ) {
            return undefined;
          }
          return (
            <IonItem key={index}>
              <IonLabel
                position={
                  field.ColumnName === "failure_flag" ||
                  field.ColumnName === "followup_required_flag"
                    ? undefined
                    : "stacked"
                }
              >
                {field.TranslatedIdText}
                {field.IsRequired && "*"}
              </IonLabel>
              {field.ColumnName === "status" && (
                <IonSelect
                  interface="popover"
                  value={status}
                  onIonChange={(ev) => {
                    setStatus(ev.detail.value);
                  }}
                >
                  {validStatusValues.map((value, index) => {
                    return (
                      <IonSelectOption key={index} value={value.Code}>
                        {value.Code}
                        {!!value.Description && ` - ${value.Description}`}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
              {field.ColumnName === "user_id" && (
                <IonSelect
                  interface="popover"
                  value={userId}
                  onIonChange={(ev) => {
                    setUserId(ev.detail.value);
                    if (workOrder) {
                      workOrder.Events.EmployeeId = ev.detail.value;
                    }
                  }}
                >
                  {employees.map((employee, index) => {
                    return (
                      <IonSelectOption key={index} value={employee.EmployeeId}>
                        {employee.EmployeeId}
                        {!!employee.Properties?.EmployeeName &&
                          ` - ${employee.Properties.EmployeeName}`}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
              {field.ColumnName === "start_date" && (
                <IonInput
                  type="datetime-local"
                  value={startDateTime}
                  onIonChange={(ev) => {
                    setStartDateTime(ev.detail.value || undefined);
                  }}
                />
              )}
              {field.ColumnName === "event_end_date" && (
                <IonInput
                  type="datetime-local"
                  value={endDateTime}
                  onIonChange={(ev) => {
                    setEndDateTime(ev.detail.value || undefined);
                  }}
                />
              )}
              {field.ColumnName === "production_lost" && (
                <IonInput
                  type="number"
                  value={lostProduction}
                  onIonChange={(ev) => {
                    setLostProduction(ev.detail.value || undefined);
                  }}
                  placeholder={field.TranslatedIdText}
                />
              )}
              {field.ColumnName === "production_uom" && (
                <IonInput
                  maxlength={field.MaxSize}
                  value={productionUom}
                  onIonChange={(ev) => {
                    setProductionUom(ev.detail.value || undefined);
                  }}
                  placeholder={field.TranslatedIdText}
                />
              )}
              {field.ColumnName === "failure_flag" && (
                <IonToggle
                  slot="end"
                  checked={failure}
                  onIonChange={(ev) => {
                    setFailure(ev.detail.checked);
                  }}
                />
              )}
              {field.ColumnName === "followup_required_flag" && (
                <IonToggle
                  slot="end"
                  checked={followUpFlag}
                  onIonChange={(ev) => {
                    setFollowUpFlag(ev.detail.checked);
                  }}
                />
              )}
              {field.ColumnName === "remarks" && (
                <IonInput
                  value={comments}
                  onIonChange={(ev) => {
                    setComments(String(ev.detail.value));
                  }}
                  placeholder={field.TranslatedIdText}
                />
              )}
            </IonItem>
          );
        })}
        <IonButton
          expand="full"
          color="primary"
          disabled={
            !status || !startDateTime || (commentField?.IsRequired && !comments)
          }
          onClick={onSubmit}
        >
          {translations["lbl_btn_complete"] || "Complete"}
        </IonButton>
      </IonList>
    </>
  );
};

export default CompletionData;
