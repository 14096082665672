import { IonActionSheet } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import { useHistory } from "react-router";
import { encodeParam } from "../util/ApiHelper";
import { getBinAccess, getItemAdjustAccess } from "../util/UserHelper";

interface InventoryActionSheetToggle {
  openStoresActionSheet: boolean;
  setOpenStoresActionSheet: (openStoresActionSheet: boolean) => void;
  cardHeader: string;
}

const InventoryActionSheet: React.FC<InventoryActionSheetToggle> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { openStoresActionSheet, setOpenStoresActionSheet, cardHeader } = props;
  const history = useHistory();
  const [itemAdjAccess, setItemAdjAccess] = useState(false);
  const [binAccess, setBinAccess] = useState(false);

  const checkItemAdjAccess = async () => {
    if (await getItemAdjustAccess()) {
      setItemAdjAccess(true);
    }
  };

  const checkBinAccess = async () => {
    if (await getBinAccess()) {
      setBinAccess(true);
    }
  };

  useEffect(() => {
    checkItemAdjAccess();
    checkBinAccess();
  }, []);

  return (
    <IonActionSheet
      isOpen={openStoresActionSheet}
      onDidDismiss={() => {
        setOpenStoresActionSheet(false);
      }}
      buttons={[
        {
          text: translations["lbl_mob_itemadjustment"] || "Item Adjustment",
          cssClass: !itemAdjAccess ? "disable-action-sheet-button" : "",
          handler: () => {
            history.push(`/ItemAdjustment/${encodeParam(cardHeader)}`);
          },
        },
        {
          text: translations["lbl_mob_bintransfer"] || "Bin Transfer",
          cssClass: !binAccess ? "disable-action-sheet-button" : "",
          handler: () => {
            history.push(`/BinTransfer/${encodeParam(cardHeader)}`);
          },
        },
        {
          text: translations["lbl_btn_cancel"] || "Cancel",
          role: "cancel",
        },
      ]}
    ></IonActionSheet>
  );
};

export default InventoryActionSheet;
