import { HttpMethods, sendRequest } from "../util/ApiHelper"

const CONTROLLER_NAME = "QuerySetUp";

export interface GetListOfQueriesBasedOnModuleParams {
    moduleName: string;
    forQueryListing: boolean;
    forQueryExecution: boolean;
    includeQwColumns?: boolean;
};

export const getListOfQueriesBasedOnModule = async (params: GetListOfQueriesBasedOnModuleParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetListOfQueriesBasedOnModule`,
        {},
        {
            ModuleName: params.moduleName,
            forQueryListing: params.forQueryListing ? "true" : "false",
            forQueryExecution: params.forQueryExecution ? "true" : "false",
            includeQwColumns: params.includeQwColumns ? "true" : "false"
        }
    );
};