import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext } from "react";
import {
  WorkOrderActivity,
  WorkOrderMetadata,
} from "../models/workorders/WorkOrder";
import "./WOApprovalModal.css";
import { TranslationsContext } from "../util/Translations";

interface LogActivityDisplay {
  isOpen: boolean;
  dismiss: () => void;
  activity: WorkOrderActivity[];
  metadata: WorkOrderMetadata;
}

const ApprovalLogActivityComp: React.FC<LogActivityDisplay> = (props) => {
  const { isOpen, dismiss, activity, metadata } = props;
  const { translations } = useContext(TranslationsContext);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={dismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {translations["lbl_log_activity"] || "Log Activity"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                dismiss();
              }}
            >
              {translations["lbl_btn_close"] || "Close"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {activity.map((card, index) => {
          return (
            <IonCard key={index}>
              <IonCardHeader>
                <IonCardTitle>{card.Activity}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["wo_log_activity.activity_date"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>
                    {new Date(card.ActivityDate).toLocaleDateString()}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["wo_log_activity.activity_time"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.ActivityTime}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["wo_log_activity.activity_user"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.ActivityUser}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["wo_log_activity.planned_reviewer"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.PlannedReviewer}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["wo_log_activity.comments"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.Comments}</IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          );
        })}
      </IonContent>
    </IonModal>
  );
};

export default ApprovalLogActivityComp;
