import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "Labors";

export interface LaborParams {
    woNumber: string;
    appName?: string;
    translations?: boolean;
}

export const getLaborsByWorkOrder = async (laborParams: LaborParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/ByWorkOrder`,
        {},
        {
            woNumber: laborParams.woNumber,
            appName: laborParams.appName ?? "",
            translations: !!laborParams.translations ? "true" : "false"
        }
    );
};

export const postLabor = async (data: any) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/Post`,
        data
    );
};

export const putLabor = async (data: any) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/Put`,
        data
    );
};

export const deleteLabor = async (data: any) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/LaborDelete`,
        data
    );
};