import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeParam } from "../util/ApiHelper";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import "./ResourcesTab.css";
import "./TableListItem.css";
import { getColumnsWithGroup } from "../api/Windows";
import { getWorkOrder } from "../api/WorkOrders";
import { TranslationMessagesContext } from "../util/Translations";
import { formatCurrencyUICurrency } from "../util/UIHelper";
import { getCurrency } from "../util/PlantHelper";
import { PlantCurrency } from "../models/plant/Plant";

const ResourcesTab: React.FC = () => {
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);

  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [resourceFields, setResourceFields] = useState<any[]>([]);
  const [fieldsErrorState, setFieldsErrorState] = useState(false);
  const [resources, setResources] = useState<any[]>([]);
  const [resourcesErrorState, setResourcesErrorState] = useState(false);
  const [currency, setCurrency] = useState<PlantCurrency | undefined>(undefined);

  const [showLoading, setShowLoading] = useState(false);

  const createRequestData = async () => {
      const data = {
        IncludeValidValues: "true",
        IncludeValidValuesExceptions: ["Equipment"],
        IsReadOnly: "true",
        ValidValueFilters: [],
        WindowName: "mob_wo_planning",
        ContextPKey: {
          Plant: await getCurrentPlant(),
          WoBase: "",
          WoTask: "  ",
          WoSubtask: "  ",
        },
      };
      return data;
  };

  useEffect(() => {
    setResourceFields([]);
    setFieldsErrorState(false);
    // Show loading symbol
    setShowLoading(true);

    // Get groups
    createRequestData().then((data) => {
      getColumnsWithGroup(data).then((response) => {
        if (response.status !== 200) {
          setResourceFields([]);
          setFieldsErrorState(true);
        } else if (response.data) {
          let data = response.data;
          if (data.fields && Object.keys(data.fields).length > 0) {
            let fields = [] as any[];
            const keys = Object.keys(data.fields);
            keys.forEach((key) => {
              if (key.includes("wo_resources.")) {
                fields.push(data.fields[key]);
              }
            });
            fields.sort((a, b) => {
              return Number(a.FieldOrder) - Number(b.FieldOrder);
            });
            setResourceFields(fields);
            setFieldsErrorState(false);
          }
        }
      });

      setResources([]);
      setResourcesErrorState(false);
      // Get work order's resources
      getWorkOrder({
        woNumber: displayWo,
        resources: true,
        appName: "Mobile",
        translations: true,
      }).then((response) => {
        if (response.status !== 200) {
          setResources([]);
          setResourcesErrorState(true);
        } else if (response.data) {
          setResources(response.data.Resources);
          setResourcesErrorState(false);
        }
        getCurrency().then((curr) => {
          setCurrency(curr);

          // Hide loading symbol once all the calls are done
          setShowLoading(false);
        });
      });
    });
  }, [workOrderId]);

  const createGridColumn = (resource: any, field: any) => {
    let label = field.TranslatedIdText;
    let value = resource[field.PropertyName];
    let format = field.FormatId;

    return (
      <IonCol size="6" key={`resource-field-${field.IdText}-col`}>
        <IonItem
          class="list-item-grid-col-item"
          lines="none"
          key={`resource-field-${field.IdText}`}
        >
          <IonLabel className="list-item-field-label">{label}</IonLabel>
          <IonLabel className="list-item-field-value">{format === "currency" ? formatCurrencyUICurrency(Number(value), currency) : value}</IonLabel>
        </IonItem>
      </IonCol>
    );
  };

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      {!!resources && resources.length > 0 ? (
        <IonList lines="full" class="table-list">
          {resources.map((resource: any, index) => {
            return (
              <IonItem key={index} class="list-item">
                <IonGrid class="list-item-grid">
                  {resourceFields.map((field, index, arr) => {
                    if (Number(field.FieldOrder) === 1) {
                      return (
                        <IonRow class="table-list-item-row">
                          <IonCol size="12">
                            <IonItem
                              lines="none"
                              class="resource-item-grid-col-title-item"
                            >
                              <IonTitle class="resource-item-title">
                                {resource[field.PropertyName]}
                              </IonTitle>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      );
                    } else if (index % 2 === 0) {
                      // Skip the even numbers to avoid duplicate columns
                      return <></>;
                    }
                    return (
                      <IonRow class="table-list-item-row">
                        {createGridColumn(resource, field)}
                        {index + 1 !== arr.length &&
                          createGridColumn(resource, arr[index + 1])}
                      </IonRow>
                    );
                  })}
                </IonGrid>
              </IonItem>
            );
          })}
        </IonList>
      ) : (
        <IonText
          color={fieldsErrorState || resourcesErrorState ? "danger" : "default"}
          style={{ padding: "16px", display: "inline-block" }}
        >
          {fieldsErrorState || resourcesErrorState ? (
            <span>
              {fieldsErrorState ? (
                <p>
                  {translatedMessages["ErrFetchingRes"]?.MessageText ||
                    "Error fetching the resource table fields"}
                </p>
              ) : (
                ""
              )}
              {resourcesErrorState ? (
                <p>
                  {translatedMessages["ErrFetchingWORes"]?.MessageText ||
                    "Error fetching the resources associated with this work order"}
                </p>
              ) : (
                ""
              )}
            </span>
          ) : (
            translatedMessages["NoResultsFound"]?.MessageText ||
            "No results found."
          )}
        </IonText>
      )}
    </>
  );
};

export default ResourcesTab;
