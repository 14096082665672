//********************************************************************************
//* This file defines the Application Insights configuration for the application.
//* and global object to use when tracking events.
//********************************************************************************
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString:'InstrumentationKey=a053e9b4-0b92-4418-b3af-67270b7d69f0;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/',
        instrumentationKey: 'a053e9b4-0b92-4418-b3af-67270b7d69f0',
        enableAutoRouteTracking: true, // adding track user navigation through the application
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
