import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonTitle,
  IonLoading,
  RefresherEventDetail,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryExec } from "../hooks/useQueryExec";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import {
  formatData,
  getAllFields,
  MeterReadings,
  MeterReadingsMetadata,
} from "../models/workorders/MeterReading";
import OnlineStatus from "./OnlineStatus";
import { TranslationsContext } from "../util/Translations";
import { getWorkOrder } from "../api/WorkOrders";
import { getMeters } from "../api/Meters";
import { getColumnsWithGroup } from "../api/Windows";

const Meters: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { errorState } = useQueryExec(
    "Work Order",
    "Work Order",
    "cf_work_order"
  );
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const [metadata, setMetadata] = useState<MeterReadingsMetadata>();
  const [meterReading, setMeterReading] = useState<MeterReadings[]>([]);
  const [equipSerial, setEquipSerial] = useState<string>("");
  const [isSerial, setIsSerial] = useState<string>("");

  const [showLoading, setShowLoading] = useState(false);
  const [inRefresh, setInRefresh] = useState(false);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setInRefresh(true);
    event.detail.complete();
  }

  const getEquipSerial = async () => {
    getWorkOrder({
      woNumber: decodeParam(workOrderId),
    }).then((response) => {
      if (response.data?.WriteWoToSerialFlag === true) {
        getMetersList(response.data?.Equipment, "s");
      } else {
        getMetersList(response.data?.Equipment, "e");
      }
    });
  };

  const getMetersList = async (equipSerial: string, isSerial: string) => {
    const meterData = {
      equipSerialId: equipSerial,
      equipSerialInd: isSerial,
    };
    handleMeterData(equipSerial, isSerial);
    getMeters(meterData).then((response) => {
      setMeterReading(response.data);
    });
  };

  const handleMeterData = async (
    equipSerialInput: string,
    isSerialInput: string
  ) => {
    setEquipSerial(equipSerialInput);
    setIsSerial(isSerialInput);
  };

  const metadataQuery = async () => {
    setShowLoading(true);
    const data = {
      IncludeValidValues: false,
      IncludeValidValuesExceptions: [],
      IsReadOnly: true,
      ValidValueFilters: [],
      WindowName: "mob_meter",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: "",
        WoTask: "  ",
        WoSubtask: "  ",
      },
    };
    getColumnsWithGroup(data).then((response) => {
      setMetadata(response.data);
      setShowLoading(false);
    });
  };

  useEffect(() => {
    metadataQuery().then(() => {
      getEquipSerial();
    });
  }, [workOrderId, inRefresh]);

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <OnlineStatus />
      {isSerial === "s" ? (
        <IonTitle
          style={{ padding: "12px", margin: "5px", display: "inline-block" }}
        >
          {`${
            translations["lbl_wologlabor_serialid"] || "Serial ID"
          }: ${equipSerial}`}
        </IonTitle>
      ) : (
        <IonTitle
          style={{ padding: "12px", margin: "5px", display: "inline-block" }}
        >
          {translations["lbl_btn_equipment"] || "Equipment"}: {equipSerial}
        </IonTitle>
      )}
      {!!metadata &&
        !!meterReading &&
        meterReading.map((meter: any, index) => {
          return (
            <IonCard
              key={index}
              routerLink={`/workorder/${encodeParam(
                displayWo
              )}/meterReadingHistory/${encodeParam(meter.Meter)}`}
            >
              <IonCardContent>
                <IonList lines="full">
                  {getAllFields(metadata).map((meterField: any) => {
                    const data = meter[meterField.PropertyName];
                    return (
                      <IonItem key={meterField.IdText}>
                        <IonLabel>{meterField.TranslatedIdText}</IonLabel>
                        {formatData(data, meterField)}
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>
          );
        })}
      <IonLabel hidden={errorState === ""} color={"danger"}>
        {errorState}
      </IonLabel>
    </>
  );
};

export default Meters;
