import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { useState } from "react";

export function useBarcodeManager() {
  const [scanText, setScanText] = useState<string>("");

  function scanClick(): void {
    startScan();
  }
  const startScan = async () => {
    await BarcodeScanner.checkPermission({ force: true });
    await BarcodeScanner.prepare();
    document.body.classList.add("qrscanner");
    const result = await BarcodeScanner.startScan();
    if (result.hasContent) {
      setScanText(result.content);
    }
    stopScan();
  };
  const stopScan = () => {
    BarcodeScanner.stopScan();
    document.body.classList.remove("qrscanner");
  };
  return {
    scanText,
    setScanText,
    scanClick,
    stopScan,
  };
}
