import { SplashScreen } from '@capacitor/splash-screen';

interface HideOptions {
  fadeOutDuration?: number;
};

interface ShowOptions {
  autoHide?: boolean, 
  fadeInDuration?: number, 
  fadeOutDuration?: number, 
  showDuration?: number
};

export const hideSplashScreen = async (options?: HideOptions) => {
  return await SplashScreen.hide(options);
};

export const showSplashScreen = async (options?: ShowOptions) => {
  return await SplashScreen.show(options);
};

export const onInitSplashScreen = async (options?: { hide?: HideOptions, show?: ShowOptions}) => {
  return hideSplashScreen(options?.hide).then(() => {
    return showSplashScreen(options?.show);
  });
};