import { Photo } from "@capacitor/camera";
import { isPlatform } from "@ionic/core";
import { useIonAlert } from "@ionic/react";
import { WoDocument } from "../models/workorders/Documents";
import {
  convertBlobToBase64,
  downloadAndView,
  readDataAsBase64,
  upload,
} from "../util/FileHelper";
import { useContext } from "react";
import { TranslationMessagesContext } from "../util/Translations";

export interface FileOptions {
  WoNumber: string;
  File: File;
  FileName: string;
  showLoadingCallback: (showYn: boolean) => void;
  uploadDoneCallback: (result: UploadResult) => void;
}

export interface PhotoOptions {
  WoNumber: string;
  Photo: Photo;
  FileName: string;
  showLoadingCallback: (showYn: boolean) => void;
  uploadDoneCallback: (result: UploadResult) => void;
}

export interface UploadResult {
  Success: boolean;
  Response: any;
}

export interface ViewOptions {
  WoNumber: string;
  Document: WoDocument;
}

export function useDocumentManager() {
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [presentAlert] = useIonAlert();

  const uploadFile = async (options: FileOptions) => {
    options.showLoadingCallback(true);
    let contentType: string;
    let content: string;
    if (isPlatform("hybrid")) {
      const reader = new FileReader();
      contentType = `image/${options.File.type}`;
      content = String(reader.result);
    } else {
      const base64Data = (await convertBlobToBase64(options.File)) as string;
      const base64Array = base64Data.split(";base64,");
      contentType = base64Array[0].split("data:")[1];
      content = base64Array[1];
    }
    uploadDocument(options, contentType, content);
  };

  const uploadPhoto = async (options: PhotoOptions) => {
    options.showLoadingCallback(true);
    const base64Data = await readDataAsBase64(options.Photo);
    let contentType: string;
    let content: string;
    if (isPlatform("hybrid")) {
      contentType = `image/${options.Photo.format}`;
      content = base64Data;
    } else {
      //split to get mimetype and base64string for web
      const imageData = base64Data.split(";base64,");
      contentType = imageData[0].split("data:")[1];
      content = imageData[1];
    }
    uploadDocument(options, contentType, content);
  };

  const uploadDocument = (
    options: FileOptions | PhotoOptions,
    contentType: string,
    content: string
  ) => {
    //upload the file to DMS
    upload(options.FileName, contentType, content, options.WoNumber)
      .then((response) => {
        options.uploadDoneCallback({ Success: true, Response: response });
      })
      .catch((reason) => {
        console.log("Upload error: " + reason);
        options.uploadDoneCallback({ Success: false, Response: reason });
      })
      .finally(() => {
        options.showLoadingCallback(false);
      });
  };

  const isApiCall = (document: WoDocument) => {
    const docProviderType = document.DocumentProviderType;
    switch (docProviderType) {
      case "Aptean DMS":
      case "Local File":
      case "Aptean ADP":
        return true;
      case "Link External URL":
      case "Microsoft One Drive":
      default:
        return false;
    }
  };

  const viewDocument = async (options: ViewOptions) => {
    if (options.Document.DownloadURL !== null) {
      if (!isApiCall(options.Document)) {
        console.log("Download URL: ", options.Document.DownloadURL);

        window.open(options.Document.DownloadURL);
      } else {
        const message = await downloadAndView(options);
        if (!!message) {
          showAlert(message);
        }
      }
    } else {
      showAlert(
        translatedMessages["ErrDocViewing"]?.MessageText ||
          "This document cannot be viewed on this device"
      );
    }
  };

  const showAlert = (message: string) => {
    presentAlert({
      message: message,
      buttons: ["OK"],
    });
  };

  return {
    uploadFile,
    uploadPhoto,
    viewDocument,
  };
}
