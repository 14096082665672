import {
  IonFab,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  IonText,
  useIonAlert,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import { RefresherEventDetail } from "@ionic/core";
import { WoDocument } from "../models/workorders/Documents";
import FileUploadFAB from "./FileUploadFAB";
import { useDocumentManager } from "../hooks/useDocumentManager";
import { appInsights } from "../util/AppInsights";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import ApiError from "./ApiError";
import OnlineStatus from "./OnlineStatus";
import { getLinkedDocInfoList } from "../api/WorkOrders";

const DocumentsTab: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  appInsights.trackPageView({ name: "DocumentsTab" });
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const { viewDocument } = useDocumentManager();
  const [documents, setDocuments] = useState<WoDocument[]>([]);
  const [documentsErrorState, setDocumentsErrorState] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [presentAlert] = useIonAlert();
  let inRefresh = false;
  const [errorData, setErrorData] = useState("");

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    inRefresh = true;
    await loadWoDocuments();
    event.detail.complete();
    inRefresh = false;
  };

  useEffect(() => {
    setShowLoading(true);
    // Get documents tab data
    loadWoDocuments().finally(() => {
      setShowLoading(false);
    });
  }, [workOrderId]);

  const loadWoDocuments = async () => {
    await getLinkedDocInfoList(displayWo)
      .then((response) => {
        if (response.isError) {
          setErrorData(response.data?.toString());
          setDocuments([]);
          setDocumentsErrorState(true);
        } else if (response.data) {
          if (response.data && response.data.length > 0) {
            setDocuments(response.data);
            setDocumentsErrorState(false);
          } else {
            setDocuments([]);
            setDocumentsErrorState(false);
          }
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const onDocumentClick = async (document: WoDocument) => {
    if (document && !!document.DownloadURL) {
      setShowLoading(true);

      await viewDocument({ WoNumber: displayWo, Document: document });

      setShowLoading(false);
    } else {
      showAlert(
        translatedMessages["ErrDocViewing"]?.MessageText ||
          "This document cannot be viewed on this device"
      );
    }
  };

  const showAlert = (message: string) => {
    presentAlert({
      message: message,
      buttons: ["OK"],
    });
  };

  const uploadCompleted = async () => {
    //refresh documents
    await loadWoDocuments();
  };

  return (
    <>
      <IonHeader>
        <OnlineStatus />
      </IonHeader>
      <ApiError errorData={errorData} />
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading && !inRefresh}
        onDidDismiss={() => setShowLoading(false)}
        duration={2000}
      />
      {!!documents && documents.length > 0 ? (
        <IonList>
          {documents.map((doc, index) => {
            let lastModified = doc.LastModified.endsWith("Z")
              ? new Date(doc.LastModified)
              : new Date(doc.LastModified + "Z");
            return (
              <IonItem key={index} onClick={() => onDocumentClick(doc)}>
                <IonLabel>
                  <h2>{doc.Description}</h2>
                  <p>
                    {translations["lbl_ememployeedocuments_lastmodifiedby"] ||
                      "Last Modified By"}{" "}
                    {doc.LastModifiedBy} on {lastModified.toLocaleString()}
                  </p>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      ) : (
        <IonText
          hidden={showLoading}
          color={errorData ? "danger" : "default"}
          style={{ padding: "16px", display: "inline-block" }}
        >
          {errorData
            ? `${
                translatedMessages["ErrDocRetrieving"]?.MessageText ||
                "Error retrieving documents"
              }`
            : `${
                translatedMessages["NoResultsFound"]?.MessageText ||
                "No results found."
              }`}
        </IonText>
      )}
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <FileUploadFAB
          WoNumber={displayWo}
          cameraEnabled={true}
          fileEnabled={true}
          isSmall={false}
          processCompletedCallback={uploadCompleted}
        />
      </IonFab>
    </>
  );
};

export default DocumentsTab;
