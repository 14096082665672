import { IonSelectCustomEvent } from "@ionic/core";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
  useIonAlert,
  IonLoading,
  IonToast,
  useIonToast,
} from "@ionic/react";
import { useContext, useRef, useState } from "react";
import { ApiSession, Application, Plant } from "../models/api/ApiOptions";
import {
  clearApiOptionsData,
  clearSiteData,
  getApiOptionsData,
  getApiSession,
  getApplication,
  mobileBootstrap,
  setApiSession,
} from "../util/ApiOptionsHelper";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { initializeAbpSession } from "../api/abpLogin";
import {
  getPortalBearerToken,
  signin,
  signinPortal,
  signoutPortal,
  validateBearerToken,
} from "../util/OAuth";

export interface ApiOptionsParams {
  isOpen: boolean;
  isInit: boolean;
  confirmCallback: (cancel: boolean, relogin?: boolean) => void;
}

const ApiOptionsModal: React.FC<ApiOptionsParams> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { isOpen, isInit, confirmCallback } = props;
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const [applications, setApplications] = useState<Application[]>();
  const [plants, setPlants] = useState<Plant[]>();
  const [currentApiSession, setCurrentApiSession] = useState<ApiSession>();
  const [databaseName, setDatabaseName] = useState("");
  const [plantCode, setPlantCode] = useState("");
  const optionsModal = useRef<HTMLIonModalElement>(null);
  const [headerText, setHeaderText] = useState("");

  const modalWillPreset = async () => {
    setShowLoading(true);

    setHeaderText(
      isInit
        ? translations["lbl_select_database"] || "Select Plant/Database"
        : translations["lbl_change_database"] || "Change Plant/Database"
    );

    const apiOptionsData = await getApiOptionsData();
    setApplications(apiOptionsData.Applications);
    setPlants(apiOptionsData.Plants);

    const apiSession = await getApiSession();
    setCurrentApiSession(apiSession);
    setDatabaseName(apiSession?.DatabaseName);
    setPlantCode(apiSession?.PlantCode);

    setShowLoading(false);
  };

  const onDatabaseSelect = async (
    event: IonSelectCustomEvent<SelectChangeEventDetail<any>>
  ) => {
    if (event.detail.value !== undefined && event.detail.value !== null) {
      setDatabaseName(event.detail.value);
    }
  };

  const onPlantSelect = async (
    event: IonSelectCustomEvent<SelectChangeEventDetail<any>>
  ) => {
    if (event.detail.value !== undefined && event.detail.value !== null) {
      setPlantCode(event.detail.value);
    }
  };

  const confirm = async () => {
    if (!databaseName) {
      presentAlert({
        message:
          translations["lbl_database_required"] || "Database is required!",
        buttons: ["OK"],
      });
      return false;
    }
    if (!plantCode) {
      presentAlert({
        message: translations["lbl_plant_required"] || "Plant is required!",
        buttons: ["OK"],
      });
      return false;
    }

    if (
      databaseName === currentApiSession?.DatabaseName &&
      plantCode === currentApiSession?.PlantCode
    ) {
      //no changes to db/plant - cancel
      optionsModal.current?.dismiss();
      return confirmCallback(true);
    }

    //if not init, alert - change in these values will reload the data
    if (!isInit) {
      presentAlert({
        message:
          translatedMessages["ChangeDatabasePlant"]?.MessageText ||
          "Changing Database and Plant values will reload the data. Do you wish to continue?",
        backdropDismiss: false,
        buttons: [
          {
            text: translations["lbl_btn_cancel"] || "Cancel",
            role: "cancel",
          },
          {
            text: translations["lbl_btn_ok"] || "OK",
            role: "confirm",
          },
        ],
        async onDidDismiss(event) {
          if (event.detail.role === "confirm") {
            return await processOptionsChange();
          } else {
            return cancel();
          }
        },
      });
    } else {
      return await processOptionsChange();
    }
  };

  const processOptionsChange = async () => {
    let reloginYn = false;
    //close modal
    const apiSession = await getApiSession();
    //update application
    apiSession.DatabaseName = databaseName;
    //get Application object to update backendapiurl
    const currentApplication = await getApplication(databaseName);
    if (currentApplication !== null) {
      //check if b2cscope is different
      reloginYn =
        apiSession.B2CUserFlowScope !== currentApplication.B2CUserFlowScope;

      apiSession.BackendApiUrl = currentApplication.BackendApiUrl;
      apiSession.B2CUserFlowScope = currentApplication.B2CUserFlowScope;
    }

    //update plant
    apiSession.PlantCode = plantCode;
    //store the updated options
    await setApiSession(apiSession);

    //try abplogin
    const eamSession = await validateEAMSession();
    //const eamSession = await initializeAbpSession();
    if (!!eamSession.validLogin) {
      return confirmCallback(false, reloginYn);
    } else {
      console.log(
        "ApiOptionsModal - processOptionsChange: ",
        eamSession.message === "" ? "No ABP error message" : eamSession.message
      );

      const errorMessage =
        eamSession.message === ""
          ? translatedMessages["LoginFailed"]?.MessageText ||
            "Login failed, try again after restarting the app."
          : eamSession.message;
      presentAlert({
        message: errorMessage,
        backdropDismiss: false,
        buttons: [
          {
            text: translations["lbl_btn_ok"] || "OK",
            role: "confirm",
          },
        ],
        async onDidDismiss(event) {
          if (event.detail.role === "confirm") {
            //reset values in the dropdowns
            setDatabaseName(currentApiSession?.DatabaseName!);
            setPlantCode(currentApiSession?.PlantCode!);

            //reset apisession in the storage
            if (currentApiSession) setApiSession(currentApiSession);
          }
        },
      });
    }
  };

  const validateEAMSession = async () => {
    //check b2c on eam
    const tokenValid = await validateBearerToken();
    if (!tokenValid) {
      //b2c authentication on eam
      if (!(await signin())) {
        return { validLogin: false, message: "" };
      }
    }
    //abplogin
    return await validateABPLicense();
  };

  const validateABPLicense = async () => {
    return await initializeAbpSession();
  };

  const cancel = async () => {
    //reset changes
    setDatabaseName(currentApiSession?.DatabaseName!);
    setPlantCode(currentApiSession?.PlantCode!);
    optionsModal.current?.dismiss();
    return confirmCallback(true);
  };

  const clearSiteCache = async () => {
    setShowLoading(true);
    await clearSiteData();
    setShowLoading(false);
    presentToast({
      message:
        translatedMessages["CacheCleared"]?.MessageText || "Cache cleared",
      duration: 1000,
      position: "top",
    });
  };

  const refreshDatabase = async () => {
    clearApiOptionsData();
    const portalToken = await getPortalBearerToken();
    if (!!!portalToken) {
      //authenticate user in portal
      await signinPortal();
    }
    await mobileBootstrap();
    //logout of portal
    await signoutPortal();
    confirmCallback(true);
  };

  return (
    <IonModal
      backdropDismiss={false}
      isOpen={isOpen}
      ref={optionsModal}
      onWillPresent={modalWillPreset}
    >
      <IonToast
        isOpen={applications?.length === 0 || plants?.length === 0}
        message={
          translations["lbl_mob_login_clearcache"] ||
          "If you are unable to select your plant or database, please clear cache and try again."
        }
        duration={5000}
        position="top"
      ></IonToast>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{headerText}</IonTitle>
          <IonButtons slot="start">
            <IonButton strong={true} onClick={cancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={confirm}>
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonContent className="ion-padding">
        <IonItem>
          <IonLabel position="stacked">
            {translations["lbl_database"] || "Database"}
          </IonLabel>
          <IonSelect
            interface="popover"
            placeholder={
              translations["lbl_select_name"] || "Select Database Name"
            }
            value={databaseName}
            onIonChange={(event) => {
              onDatabaseSelect(event);
            }}
          >
            {applications?.map((app, index) => (
              <IonSelectOption key={index} value={app?.DatabaseName}>
                {app.Description}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            {translations["lbl_auauditchange_plant"] || "Plant"}
          </IonLabel>
          <IonSelect
            interface="popover"
            placeholder={translations["lbl_select_plant"] || "Select Plant ID"}
            value={plantCode}
            onIonChange={(event) => {
              onPlantSelect(event);
            }}
          >
            {plants?.map((plant, index) => (
              <IonSelectOption key={index} value={plant?.PlantCode}>
                {plant?.PlantCode} - {plant.Description}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        {isInit && (
          <IonButton
            className="ion-padding"
            expand="block"
            color="medium"
            onClick={() => {
              refreshDatabase();
            }}
          >
            {translations["lbl_refresh_database"] ||
              "Refresh database selection"}
          </IonButton>
        )}
      </IonContent>
    </IonModal>
  );
};

export default ApiOptionsModal;
