import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  useIonActionSheet,
} from "@ionic/react";
import {
  chevronDownOutline,
  chevronUpOutline,
  ellipsisVertical,
} from "ionicons/icons";
import {
  BinData,
  PlannedListItem,
} from "../models/materialissue/MaterialIssue";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";

interface MaterialIssueItemProps {
  selected: boolean;
  onSelect: (checked: boolean) => void;
  item: PlannedListItem;
  onBinAmountChange: (bin: string, amount: number) => void;
  binData: BinData[];
  issued: boolean;
  issueError?: string;
  mode: "issue" | "return";
  comments: string;
  handleCommentButton: (comment: string, item: string) => void;
  enableRemoval?: boolean;
  handleRemoval?: (item: any) => void;
  negativeInvCtrlVal?: string;
}

const MaterialIssueItem: React.FC<MaterialIssueItemProps> = (props) => {
  const {
    selected,
    onSelect,
    item,
    onBinAmountChange,
    binData,
    issued,
    issueError,
    mode,
    comments,
    handleCommentButton,
    handleRemoval,
    negativeInvCtrlVal,
  } = props;
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [expanded, setExpanded] = useState(false);
  const [present] = useIonActionSheet();
  const inputItem = useRef<HTMLIonInputElement>(null);

  const presentActions = () => {
    let buttons = [
      {
        text: "Comment",
        handler: () => {
          handleCommentButton(comments, item.Item);
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        data: { action: "cancel" },
      },
    ];
    if (!!handleRemoval && !issued) {
      buttons.splice(buttons.length - 1, 0, {
        text: `${translations["lbl_btn_remove"] || "Remove"}`,
        handler: () => {
          handleRemoval(item);
        },
      });
    }
    present({
      buttons: buttons,
    });
  };

  const getTotal = () => {
    return binData.reduce(
      (sum, bin) =>
        sum + (!bin.TransactionQuantity ? 0 : Number(bin.TransactionQuantity)),
      0
    );
  };

  const getNegativeQtyMessage = () => {
    if (!negativeInvCtrlVal || negativeInvCtrlVal === "n") {
      return undefined;
    }
    let isNegativeQty = false;
    if (mode === "issue") {
      for (const bin of binData) {
        if (
          bin.TransactionQuantity &&
          bin.TransactionQuantity > bin.QtyOnHand
        ) {
          isNegativeQty = true;
          break;
        }
      }
    }
    if (isNegativeQty) {
      if (negativeInvCtrlVal === "e" || negativeInvCtrlVal === "w") {
        return (
          <IonGrid class="ion-no-padding">
            <IonRow>
              <IonCol>
                <IonLabel
                  color={negativeInvCtrlVal === "e" ? "danger" : "warning"}
                >
                  <h2 className="ion-no-margin">
                    {translatedMessages["NegativeQtyOnHandError"]
                      ?.MessageText ||
                      "The current Bin Quantity is not sufficient to issue the chosen quantity of this item."}
                  </h2>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        );
      }
    }
    return undefined;
  };

  useEffect(() => {
    setTimeout(() => {
      inputItem.current?.setFocus();
    }, 0);
  }, []);

  return (
    <IonCard class="ion-no-margin ion-margin-bottom">
      <IonCardContent>
        <IonGrid class="ion-no-padding">
          <IonRow class="ion-no-padding ion-align-items-center">
            <IonCol>
              <IonCheckbox
                checked={selected}
                onClick={() => {
                  onSelect(!selected);
                }}
              />
            </IonCol>
            {issued && (
              <IonCol size="auto">
                <IonLabel color="success">
                  {mode === "issue"
                    ? translations["lbl_issued"] || "Issued"
                    : translations["lbl_returned"] || "Returned"}
                </IonLabel>
              </IonCol>
            )}
            <IonCol size="auto" class="ion-no-padding">
              <IonButton
                size="small"
                fill="clear"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <IonIcon
                  size="small"
                  slot="icon-only"
                  icon={expanded ? chevronUpOutline : chevronDownOutline}
                ></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol size="auto" class="ion-no-padding">
              <IonButton fill="clear" size="small" onClick={presentActions}>
                <IonIcon
                  size="small"
                  slot="icon-only"
                  icon={ellipsisVertical}
                ></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow class="ion-no-padding">
            <IonCol>
              <IonCardTitle class="ion-no-padding">{item.Item}</IonCardTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h6 className="ion-no-margin">{item.ItemDescription}</h6>
            </IonCol>
          </IonRow>
          <IonRow class="ion-align-items-center ion-no-padding ion-padding-top">
            <IonCol>
              <IonLabel>
                {mode === "issue" && (
                  <>
                    <p style={{ margin: 0 }}>{`${
                      translations["lbl_available"] || "Available"
                    }: ${item.LocationQuantityAvailable}`}</p>
                    <p style={{ margin: 0 }}>{`${
                      translations["lbl_requested"] || "Requested"
                    }: ${item.LineQuantityRequested}`}</p>
                  </>
                )}
                <p style={{ margin: 0 }}>
                  {`${translations["lbl_issued"] || "Issued"}: ${
                    item.LineQuantityIssued
                  }`}
                </p>
                {mode === "return" && (
                  <>
                    <p style={{ margin: 0 }}>
                      {`${translations["lbl_returned"] || "Returned"}: ${
                        item.LineQuantityReturned
                      }`}
                    </p>
                    <p style={{ margin: 0 }}>
                      {`${
                        translations["lbl_initemlocation_unitofissue"] || "U/M"
                      }: ${item.UnitOfMeasure}`}
                    </p>
                    <p style={{ margin: 0 }}>
                      {`${
                        translations["lbl_reqline"] || "Requisition Line"
                      }: ${item.RequisitionLine}`}
                    </p>
                  </>
                )}
              </IonLabel>
            </IonCol>
            <IonCol size="auto">
              <IonItem fill="outline" style={{ maxWidth: 84 }} lines="full">
                <IonInput
                  inputMode="decimal"
                  ref={inputItem}
                  type="number"
                  value={getTotal()}
                  onIonChange={(ev) => {
                    if (!Number.isNaN(Number(ev.detail.value))) {
                      onBinAmountChange(
                        "",
                        !ev.detail.value ? 0 : +ev.detail.value
                      );
                    }
                  }}
                  min={0}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          class={`ion-no-padding ${!expanded ? "ion-hide" : "ion-show"}`}
        >
          {binData.map((bin, index) => {
            return (
              <IonRow
                class="ion-align-items-center ion-padding-vertical"
                key={`bin-${bin.Bin}-${index}`}
              >
                <IonCol>
                  <IonLabel>
                    <p className="ion-no-margin">{bin.Bin}</p>
                  </IonLabel>
                </IonCol>
                <IonCol size="auto">
                  <IonItem fill="outline" style={{ maxWidth: 84 }} lines="full">
                    {
                      <IonInput
                        inputMode="decimal"
                        type="number"
                        value={bin.TransactionQuantity}
                        onIonChange={(ev) => {
                          if (!Number.isNaN(Number(ev.detail.value))) {
                            onBinAmountChange(
                              bin.Bin,
                              !ev.detail.value ? 0 : +ev.detail.value
                            );
                          }
                        }}
                        disabled={issued}
                        min={0}
                      ></IonInput>
                    }
                  </IonItem>
                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid>
        {issueError && (
          <IonGrid class="ion-no-padding">
            <IonRow>
              <IonCol>
                <IonLabel color="danger">
                  <h2 className="ion-no-margin">{issueError}</h2>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {getNegativeQtyMessage()}
      </IonCardContent>
    </IonCard>
  );
};

export default MaterialIssueItem;
