import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { createPortal } from "react-dom";

const BarcodeScannerHeader: React.FC = () => {
  const handleClick = () => {
    window.dispatchEvent(new Event("stop-barcode-scanning"));
  };

  const scannerUi = (
    <div className="scanner-ui" id="scanner-ui">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton size="large" onClick={handleClick}>
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </div>
  );
  // Barcode Scanner ui. This element needs to be rendered outside of the ion-app as that is being hidden to show the camera.
  return <>{createPortal(scannerUi, document.body)}</>;
};

export default BarcodeScannerHeader;
