import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "MeterReadings";

export interface MeterReadingsParams {
    equipSerialId: string;
    equipSerialInd: string;
    meters: string;
};

export const getMeterReadings = async (params: MeterReadingsParams) => {
    return sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/Get`,
        {},
        params
    )
};

export const postMeterReadings = async (data: any) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/Post`,
        data,
        {}
    );
};

export const putMeterReadings = async (data: any) => {
    return await sendRequest(
        HttpMethods.PUT,
        `${CONTROLLER_NAME}/Put`,
        data,
        {}
    );
};
