import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import PageHeader from "../components/PageHeader";
import { useParams } from "react-router";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import { useEffect, useState } from "react";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import {
  GetColumnsWithGroupField,
  GetColumnsWithGroupResponse,
} from "../models/windows/GetColumnsWithGroup";
import { getEquipment } from "../api/EquipmentMasters";
import { Equipment } from "../models/equipment/Equipment";
import { getAllFields } from "../models/workorders/WorkOrder";
import { getUserRequesterAccess, getUserTechnicianAccess } from "../util/UserHelper";
import ApiError from "../components/ApiError";

const EquipmentOverview: React.FC = () => {
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const displayEquipment = decodeParam(equipmentId);
  const [columns, setColumns] = useState<GetColumnsWithGroupResponse>();
  const [equipment, setEquipment] = useState<Equipment>();
  const [fields, setFields] = useState<GetColumnsWithGroupField[]>();
  const [showLoading, setShowLoading] = useState(false);
  const [isRequester, setIsRequester] = useState(false);
  const [errorData, setErrorData] = useState("");

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (columns) {
      const fields = getAllFields(columns);
      setFields(fields as GetColumnsWithGroupField[]);
    }
  }, [columns]);

  const getColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_equipment",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };

  const getEquipmentDetails = async (name: string) => {
    const response = await getEquipment({ equipment: name });
    if(response.isError){
      setErrorData(response.data?.toString());
    }
    return response.data as Equipment;
  };

  const setup = async () => {
    setShowLoading(true);
    const [columns, equipment] = await Promise.all([
      getColumns(),
      getEquipmentDetails(displayEquipment),
    ]);
    setColumns(columns);
    setEquipment(equipment);
    if (!(await getUserTechnicianAccess()) && await getUserRequesterAccess()) {
      setIsRequester(true);
    }
    setShowLoading(false);
  };

  const getCreateWorkOrderRoute = (isRequester: boolean) => {
    if (isRequester) {
      return `/requester/create-request/equipment/${encodeParam(displayEquipment)}/create`;
    }
    return `/quick-work-order/equipment/${encodeParam(
      displayEquipment
    )}/create`;
  };

  const getOpenWorkOrdersRoute = (isRequester: boolean) => {
    if (isRequester) {
      return `/requester/create-request/equipment/${encodeParam(displayEquipment)}`;
    }
    return `/quick-work-order/equipment/${encodeParam(displayEquipment)}`;
  };

  return (
    <IonPage>
      <IonLoading isOpen={showLoading} />
      <PageHeader title={displayEquipment} showBack />
      <ApiError errorData={errorData} />
      <IonContent>
        <IonCard>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                {fields?.map((field) => {
                  let data = equipment?.[field.PropertyName as keyof Equipment];
                  if (field.NetType === "DateTime") {
                    const formattedString = new Date(data).toLocaleDateString();
                    data = formattedString;
                  }
                  return (
                    <IonCol key={field.ColumnName} size="6">
                      <IonRow>{field.TranslatedIdText}</IonRow>
                      <IonRow>
                        <IonLabel
                          style={{ fontWeight: "bold", wordBreak: "break-all" }}
                          class="ion-text-wrap"
                        >
                          {data || "-"}
                        </IonLabel>
                      </IonRow>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonList lines="full">
          <IonItem
            button
            detail
            routerLink={getCreateWorkOrderRoute(isRequester)}
          >
            Create Work Order/Request
          </IonItem>
          <IonItem
            button
            detail
            routerLink={getOpenWorkOrdersRoute(isRequester)}
          >
            Open Orders
          </IonItem>
          <IonItem
            button
            detail
            routerLink={`/Equipment/${encodeParam(
              displayEquipment
            )}/hierarchy`}
          >
            Hierarchy
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default EquipmentOverview;
