import { ItemListEntry, PlannedListFilterType } from "../pages/MaterialIssue";

export const matchesItemNotFullyIssued = (
  entry: ItemListEntry,
  currentFilter?: PlannedListFilterType
) => {
  return (
    currentFilter === PlannedListFilterType.ItemsNotFullyIssued &&
    entry.item?.LineQuantityRequested - entry.item?.LineQuantityIssued >= 0
  );
};

export const matchesAvailableItemsNotFullyIssued = (entry: ItemListEntry, currentFilter?: PlannedListFilterType) => {
  return (
    currentFilter === PlannedListFilterType.AvailableItemsNotFullyIssued &&
    entry.item?.LineQuantityRequested - entry.item?.LineQuantityIssued >= 0 &&
    entry.item?.LocationQuantityAvailable >= 0
  );
};
