import {
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonFabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import {
  checkmark,
  speedometer,
  time,
  cog,
  chatbubbleEllipses,
  documentText,
  returnDownBackOutline,
  sync,
} from "ionicons/icons";
import { useContext, useState } from "react";
import { encodeParam } from "../util/ApiHelper";
import {
  ScreenOrientation,
  ScreenOrientationChange,
} from "@capawesome/capacitor-screen-orientation";
import { isLandscapeMode, setScreenOrientation } from "../util/Device";
import FileUploadFAB from "./FileUploadFAB";
import "./WorkOrderActionBar.css";
import TimeEntry from "../pages/TimeEntry";
import EquipmentSearch from "./EquipmentSearch";
import { TranslationsContext } from "../util/Translations";
import Status from "./Status";

interface WorkOrderActionBarProps {
  woNumber: string;
  isOpen: boolean;
  woDate: any;
  woTime: any;
  inspectionId: any;
  dismiss: () => void;
  openLoading: () => void;
  closeLoading: () => void;
  refreshScreen: () => void;
  meterCount: number;
  sectionDisplay: {
    Comment: boolean;
    TimeEntry: boolean;
    Meter: boolean;
    Inspections: boolean;
    IssueMaterial: boolean;
    ReturnMaterial: boolean;
  };
}

const WorkOrderActionBar: React.FC<WorkOrderActionBarProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const {
    woNumber,
    isOpen,
    woDate,
    woTime,
    inspectionId,
    dismiss,
    refreshScreen,
    meterCount,
    sectionDisplay,
  } = props;
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isAddTimeModalOpen, setIsAddTimeModalOpen] = useState(false);
  const [isEquipmentModalOpen, setIsEquipmentModalOpen] = useState(false);
  const [equipmentChange, setEquipmentChange] = useState(false);
  const [landscapeMode, setLandscapeMode] = useState(false);
  const [listnerAdded, setListnerAdded] = useState(false);
  const [timeButtonClicked, setTimeButtonClicked] = useState(false);

  const onStatusModalCancel = () => {
    setIsStatusModalOpen(false);
  };

  const onStatusModalConfirm = () => {
    setIsStatusModalOpen(false);
    refreshScreen();
    dismiss();
  };

  const onAddTimeModalCancel = () => {
    setIsAddTimeModalOpen(false);
  };

  const onAddTimeModalConfirm = () => {
    setIsAddTimeModalOpen(false);
    refreshScreen();
    dismiss();
  };

  const onEquipmentModalCancel = () => {
    setIsEquipmentModalOpen(false);
  };

  const onEquipmentModalConfirm = () => {
    setIsEquipmentModalOpen(false);
    refreshScreen();
    dismiss();
  };

  const uploadCompleted = async () => {
    //no action needed
  };

  const onWillPresent = async () => {
    if (!listnerAdded) {
      ScreenOrientation.addListener(
        "screenOrientationChange",
        async (orientationChange: ScreenOrientationChange) => {
          setListnerAdded(true);
          await setScreenOrientation(orientationChange.type);
          const isLandscape = await isLandscapeMode(orientationChange.type);
          setLandscapeMode(isLandscape);

          //update the breakpoint to reflect the change in orientation
          (
            document.getElementById("actionBarModal") as HTMLIonModalElement
          ).setCurrentBreakpoint(isLandscape ? 0.8 : 0.4);
        }
      );
    }
  };

  // Mapping the grid out so that we don't have wild looking columns depending on what buttons are available to the user.
  const makeButtonGrid = () => {
    let enabledButtons = [
      "Status",
      "Complete",
      "Comment",
      "TimeEntry",
      "Meter",
      "Upload",
      "Camera",
      "Equipment",
      "Inspections",
      "Material Issue",
      "Material Return",
    ];
    let rowCount = Math.ceil(enabledButtons.length / 4);
    let rowColArray = [];
    for (let i = 0; i < rowCount; i++) {
      // Push a 'row' array of up to 4 columns into the rowColArray
      rowColArray.push(enabledButtons.splice(0, 4));
    }
    return (
      <IonGrid class="action-button-grid">
        {rowColArray.map((columnArray, index) => {
          return (
            <IonRow key={`action-bar-${index}`}>
              {columnArray.map((buttonLabel) => {
                let column;
                switch (buttonLabel) {
                  case "Status":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={() => {
                            setIsStatusModalOpen(true);
                            dismiss();
                          }}
                        >
                          <IonIcon icon={sync}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_woworkorder_status"] || "Status"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Complete":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={dismiss}
                          routerLink={`/workorder/${encodeParam(
                            woNumber
                          )}/completiondata`}
                        >
                          <IonIcon icon={checkmark}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_btn_complete"] || "Complete"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Comment":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={dismiss}
                          routerLink={`/workorder/${encodeParam(
                            woNumber
                          )}/remarks/${encodeParam(woDate)}/${encodeParam(
                            woTime
                          )}`}
                          disabled={!sectionDisplay.Comment}
                        >
                          <IonIcon icon={chatbubbleEllipses}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_incyclecountbatch_comment"] ||
                            "Comment"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "TimeEntry":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={() => {
                            setTimeButtonClicked(true);
                            setIsAddTimeModalOpen(true);
                            dismiss();
                          }}
                          disabled={!sectionDisplay.TimeEntry}
                        >
                          <IonIcon icon={time}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_time_entry"] || "Time Entry"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Meter":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={dismiss}
                          routerLink={`/workorder/${encodeParam(
                            woNumber
                          )}/meters`}
                          disabled={!sectionDisplay.Meter || meterCount === 0}
                        >
                          <IonIcon icon={speedometer}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_btn_meter"] || "Meter"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Upload":
                    column = (
                      <>
                        <FileUploadFAB
                          WoNumber={woNumber}
                          cameraEnabled={false}
                          fileEnabled={true}
                          isSmall={true}
                          processCompletedCallback={uploadCompleted}
                        />
                        <IonLabel color="primary">
                          {translations["lbl_btn_upload"] || "Upload"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Camera":
                    column = (
                      <>
                        <FileUploadFAB
                          WoNumber={woNumber}
                          cameraEnabled={true}
                          fileEnabled={false}
                          isSmall={true}
                          processCompletedCallback={uploadCompleted}
                        />
                        <IonLabel color="primary">
                          {translations["lbl_camera"] || "Camera"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Equipment":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={() => {
                            setIsEquipmentModalOpen(true);
                            setEquipmentChange(true);
                            dismiss();
                          }}
                        >
                          <IonIcon icon={cog}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_btn_equipment"] || "Equipment"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Inspections":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={dismiss}
                          disabled={
                            !sectionDisplay.Inspections ||
                            !!!inspectionId ||
                            inspectionId === 0
                          }
                          routerLink={`/workorder/${encodeParam(
                            woNumber
                          )}/inspection/${inspectionId}`}
                        >
                          <IonIcon icon={documentText}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_category_inspection"] ||
                            "Inspection"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Material Issue":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={dismiss}
                          disabled={!sectionDisplay.IssueMaterial}
                          routerLink={`/workorder/${encodeParam(
                            woNumber
                          )}/issuematerial`}
                        >
                          <IonIcon icon={checkmark}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_btn_issue_material"] ||
                            "Issue Material"}
                        </IonLabel>
                      </>
                    );
                    break;
                  case "Material Return":
                    column = (
                      <>
                        <IonFabButton
                          size="small"
                          onClick={dismiss}
                          disabled={!sectionDisplay.ReturnMaterial}
                          routerLink={`/workorder/${encodeParam(
                            woNumber
                          )}/returnmaterial`}
                        >
                          <IonIcon icon={returnDownBackOutline}></IonIcon>
                        </IonFabButton>
                        <IonLabel color="primary">
                          {translations["lbl_btn_return_material"] ||
                            "Return Material"}
                        </IonLabel>
                      </>
                    );
                    break;
                  default:
                    break;
                }
                return <IonCol key={buttonLabel}>{column}</IonCol>;
              })}
            </IonRow>
          );
        })}
      </IonGrid>
    );
  };

  return (
    <>
      <IonModal
        id="actionBarModal"
        isOpen={isOpen}
        backdropDismiss={true}
        handle={true}
        breakpoints={[0, 0.4, 0.6, 0.8, 1]}
        initialBreakpoint={landscapeMode ? 0.8 : 0.4}
        onDidDismiss={dismiss}
        onWillPresent={onWillPresent}
      >
        {makeButtonGrid()}
      </IonModal>
      <Status
        isOpen={isStatusModalOpen}
        cancel={onStatusModalCancel}
        confirm={onStatusModalConfirm}
      />
      {timeButtonClicked && (
        <TimeEntry
          isOpen={isAddTimeModalOpen}
          cancel={onAddTimeModalCancel}
          confirm={onAddTimeModalConfirm}
        />
      )}
      <EquipmentSearch
        modalProps={{
          isOpen: isEquipmentModalOpen,
          onModalCancel: onEquipmentModalCancel,
          onModalConfirm: onEquipmentModalConfirm,
        }}
        woNumber={woNumber}
        equipmentChange={equipmentChange}
      />
    </>
  );
};

export default WorkOrderActionBar;
