import { IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import PageHeader from "../components/PageHeader";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import SingleQueryPage from "../components/SingleQueryPage";
import { useContext, useEffect, useState } from "react";
import { add } from "ionicons/icons";
import {
  getApprovalAccess,
  getStoresAccess,
  getUserRequesterAccess,
  getUserTechnicianAccess,
} from "../util/UserHelper";
import { TranslationsContext } from "../util/Translations";
import { AuthContext } from "../util/OAuth";
import { useHistory } from "react-router-dom";

const Home: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const [title, setTitle] = useState("");
  const [queryName, setQueryName] = useState("");
  const [fabUrl, setFabUrl] = useState("");
  const { loggedIn } = useContext(AuthContext);
  const history = useHistory();

  const setOptionsByAccess = async () => {
    if (await getUserTechnicianAccess()) {
      setTitle(
        translations["lbl_my_assigned_wos"] || "My Assigned Work Orders"
      );
      setQueryName("My Assigned Work Orders");
      setFabUrl("/quick-work-order/equipment");
    } else if (await getUserRequesterAccess()) {
      setTitle(translations["lbl_my_recent_wos"] || "My Recent WOs");
      setQueryName("My Recent WOs");
      setFabUrl("/requester/create-request/equipment");
    } else if (await getStoresAccess()) {
      history.push("/Stores");
    } else if (await getApprovalAccess()) {
      history.push("/WOApproval");
    }
    //to do: renders empty page if no access - add message
  };

  useEffect(() => {
    setOptionsByAccess();
  }, [queryName, translations, loggedIn]);

  return (
    <IonPage>
      <PageHeader title={title} />
      {!!queryName ? (
        <>
          <SingleQueryPage queryName={queryName} />
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton routerLink={fabUrl}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
        </>
      ) : (
        <></>
      )}
    </IonPage>
  );
};

export default withAITracking(reactPlugin, Home, "Home");
