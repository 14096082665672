import { IonLoading, IonText } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./InstructionsTab.css";
import { getWorkOrder } from "../api/WorkOrders";
import { TranslationMessagesContext } from "../util/Translations";

const InstructionsTab: React.FC = () => {
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);

  const [instructions, setInstructions] = useState<string | undefined>();
  const [instructionsErrorState, setInstructionsErrorState] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setInstructions(undefined);
    setInstructionsErrorState(false);
    // Show loading symbol
    setShowLoading(true);

    // Get work order
    getWorkOrder({
      woNumber: displayWo,
      instruction: true,
    }).then((response) => {
      if (response.status !== 200) {
        setInstructions(undefined);
        setInstructionsErrorState(true);
      } else if (response.data) {
        let instructionsString = response.data.Instruction
          ?.Instructions as string;
        if (instructionsString) {
          // Remove the font tag and replace it with span, as the html tag "font" is no longer supported in html5
          let correctedInstructions = instructionsString
            .replace(/<font color="/gi, '<span style="color:')
            .replace(/<\/font/gi, "</span");
          setInstructions(correctedInstructions);
          setInstructionsErrorState(false);
        }
      }
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
    });

    // Get requisition
  }, [workOrderId]);

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      {instructionsErrorState ? (
        <IonText
          color="danger"
          style={{ padding: "16px", display: "inline-block" }}
        >
          {translatedMessages["ErrFetchingWOInst"]?.MessageText ||
            "Error fetching this work order's instructions"}
        </IonText>
      ) : (
        <ReactQuill
          readOnly
          className="ql-read-only" // If we ever want the editor to be editable, add logic to remove this
          theme="snow"
          value={instructions}
          modules={{
            toolbar: [
              [
                "bold",
                "italic",
                "underline",
                "strike",
                { list: "ordered" },
                { list: "bullet" },
                "clean",
                { align: [] },
                { indent: "-1" },
                { indent: "+1" },
                { color: [] },
                { background: [] },
              ],
            ],
            history: {
              userOnly: true,
              maxStack: 50,
            },
          }}
        />
      )}
    </>
  );
};

export default InstructionsTab;
