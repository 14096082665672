import { useContext, useState } from "react";
import { ApiResponse } from "../models/api/ApiResponse";
import { getListOfQueriesBasedOnModule } from "../api/QuerySetUp";
import { getListofFilters } from "../api/QueryDesigner";
import { getSyCode30Records } from "../api/SyCodes";
import { getCodeValues } from "../api/ValidationCodes";
import { executeQuery, getQueryCriteriaData } from "../api/QueryExecution";
import { getOptionByIdWithCORP } from "../api/TabwareOptions";
import { TranslationMessagesContext } from "../util/Translations";
import Fuse from "fuse.js";

export interface FilterParam {
  ColumnName: string;
  TableName: string;
  TranslatedIdText: string;
  FormatString: string;
  ParameterValue: string | number | null | undefined;
  AdditionalInfo: string;
  Requirements: any[];
  MaxSize: number;
}
export interface ExecParam {
  TableAlias: string;
  ColumnAlias: string;
  Operator: string;
  ParameterValue: string | number | null | undefined;
}
export interface CriteriaDef {
  AskAtExecutionFlag: boolean;
  CaseIndicator: boolean;
  Category: any;
  CloseParensCount: number;
  ColumnName: string;
  CompanionElement: any;
  CompanionExpression: any;
  ComparisonColumn: any;
  ComparisonExpression: string;
  ComparisonOperator: string;
  ComparisonTable: any;
  DataType: string;
  DbColumnName: string;
  IdText: string;
  IsDefault: boolean;
  JoinId: number;
  LogicalOperator: string;
  ModuleName: string;
  OpenParensCount: number;
  Plant: string;
  ProhibitEdit: boolean;
  QueryName: string;
  Sequence: number;
  TZComparisionElement: string;
  TZDatatype: number;
  TableAlias: string;
  TableName: string;
  TextCaseType: string;
  TranslatedIdText: string;
  UdCategory: any;
  UdElement: any;
  UdSubcategory: any;
  Userid: string;
  isResourceColumn: boolean;
  isSearchableCriteriaColumn: boolean;
}
export interface CriteriaParameter {
  CriteriaSequence: number;
  TableAlias: string;
  ColumnAlias: string;
  JoinID: number;
  ComparisonExpression: string;
  CompanionElement: any;
  CompanionExpression: any;
}
export interface QueryResult {
  HeaderRow: number;
  HeaderName: string;
  HeaderType: string;
  HeaderText: string;
  HeaderValue: string;
  HeaderColor: string;
  Columns: ColumnDef[];
}
export interface ColumnDef {
  Order: number;
  Name: string;
  Text: string;
  Value: string;
  Type: string;
  Hidden: boolean;
}
export const maxResults = 100;

export function useQueryExec(
  moduleName: string,
  windowName: string,
  keyFieldName: string,
  loadCodes?: boolean,
  includeQwColumns?: boolean
) {
  const { translatedMessages } = useContext(TranslationMessagesContext);

  async function processError(errorData: any) {
    if (!!errorData) {
      if (errorData.includes("relogin")) {
        setErrorSate(
          translatedMessages["SessionExpired"]?.MessageText ||
          "Your session has expired. Please re-login."
        );
      } else {
        setErrorSate(errorData);
      }
    }
  }
  const [queryList, setQueryList] = useState<string[]>([]);
  const [queryUserList, setQueryUserList] = useState<{ queryName: string, userId: string }[]>([]);

  async function getQueryList() {
    let response: ApiResponse = await getListOfQueriesBasedOnModule({
      moduleName: moduleName,
      forQueryListing: true,
      forQueryExecution: false,
      includeQwColumns: includeQwColumns ?? false,
    });
    const qryNames: string[] = [];
    const qryUserNames: { queryName: string, userId: string }[] = [];
    if (response.status !== 200) {
      processError(response.data);
    } else if (!!response.data) {
      response.data.QueryList.forEach((qry: { QueryName: string }) => {
        qryNames.push(qry.QueryName);
      });
      response.data.QueryList.forEach((qry: { QueryName: string, Userid: string }) => {
        qryUserNames.push({ queryName: qry.QueryName, userId: qry.Userid });
      });
    }
    setQueryList(qryNames);
    setQueryUserList(qryUserNames);
  }

  const [filterParams, setFilterParams] = useState<FilterParam[]>([]);

  async function getFilterParams() {
    const listParams: FilterParam[] = [];
    let response: ApiResponse = await getListofFilters({
      includeValidValues: false,
      moduleName: moduleName,
      windowName: windowName,
    });
    if (response.status !== 200) {
      processError(response.data);
    } else if (response.data != null) {
      response.data.forEach((result: FilterParam) => {
        result.ParameterValue = "";
        listParams.push(result);
      });
    }
    setFilterParams(listParams);
  }

  async function setParameter(
    paramKey: string,
    paramValue: string | number | null | undefined
  ) {
    if (filterParams) {
      const listParams = filterParams;
      const res = listParams.find((p) => {
        return p.ColumnName === paramKey;
      });
      if (res != null) res.ParameterValue = paramValue;
      setFilterParams(listParams);
    }
  }
  interface FieldDef {
    DisplayOrder: number;
    TranslatedIdText: string;
    ColumnName: string;
    DataType: string;
    JoinId: number;
  }

  interface ColorCodes {
    Code: string;
    Color: string;
  }
  const [priorityCodes, setPriorityCodes] = useState<ColorCodes[]>([]);
  async function getPriorityCodes(): Promise<ColorCodes[]> {
    let response: ApiResponse = await getSyCode30Records({
      subCategory: "Priority",
      isDataTransNeeded: true,
    });
    let pCodes: ColorCodes[] = [];
    if (response.status !== 200) {
      processError(response.data);
    } else if (response.data?.Code30UdValues != null) {
      response.data.Code30UdValues.forEach((result: any) => {
        if (result.Element === "Mobile Color" && result.StringValue !== null) {
          pCodes.push({ Code: result.Code, Color: `${result.StringValue}` });
        }
      });
      setPriorityCodes(pCodes);
    }
    return pCodes;
  }

  const [statusCodes, setStatusCodes] = useState<ColorCodes[]>([]);
  async function getStatusCodes(): Promise<ColorCodes[]> {
    let response: ApiResponse = await getCodeValues({
      tableName: "sy_wo_status",
      fieldName: "",
    });
    let pCodes: ColorCodes[] = [];
    if (response.status !== 200) {
      processError(response.data);
    } else if (response.data != null) {
      response.data.forEach((result: any) => {
        if (result.MobileColor !== null) {
          pCodes.push({ Code: result.Code, Color: `${result.MobileColor}` });
        }
      });
      setStatusCodes(pCodes);
    }
    return pCodes;
  }
  const [queryCriteria, setQueryCriteria] = useState<CriteriaDef[]>([]);
  async function getQueryCriteria(queryName: string) {
    const queryUser = queryUserList.find(q => q.queryName === queryName);
    setShowLoading(true);
    let response: ApiResponse = await getQueryCriteriaData({
      QueryName: queryName,
      ModuleName: moduleName,
      IsPublicQuery: queryUser?.userId == 'Public Query',
      DefaultQuery: false,
    });
    const newCriteria: CriteriaDef[] = [];
    if (response.status !== 200) {
      processError(response.data);
    } else if (response.data.QwCriteria) {
      response.data.QwCriteria.forEach((criteria: CriteriaDef) => {
        if (criteria.AskAtExecutionFlag) {
          newCriteria.push(criteria);
        }
      });
    }
    setQueryCriteria(newCriteria);
    setShowLoading(false);
  }

  const [queryRows, setQueryRows] = useState<QueryResult[]>([]);
  const [filterRows, setFilterRows] = useState<QueryResult[]>([]);
  const [resultsFilter, setResultsFilter] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorState, setErrorSate] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0);

  async function executeSearch(
    queryName: string,
    parameters?: CriteriaParameter[]
  ) {
    setErrorSate("");
    setShowLoading(true);
    setQueryRows([]);
    setResultsFilter("");
    let fields: FieldDef[] = [];
    const execParams: ExecParam[] = [];
    if (filterParams != null) {
      filterParams.forEach((p) => {
        if (p.ParameterValue !== "")
          execParams.push({
            TableAlias: p.TableName,
            ColumnAlias: p.ColumnName,
            Operator: p.AdditionalInfo,
            ParameterValue: p.ParameterValue,
          });
      });
    }
    const queryUser = queryUserList.find(q => q.queryName === queryName);
    var isPublicQuery = queryUser?.userId == 'Public Query';
    const publicQueries = ["My Recent WOs", "My Assigned Work Orders", "SYS_Weekly Time Entries", "Mobile Approvals", "My Inspections"];

    if (publicQueries.includes(queryName)) {
      isPublicQuery = true;
    }
    if (queryName === "SYS_Weekly Time Entries") {
      moduleName = "Labor Activity";
    }
    let response: ApiResponse = await executeQuery({
      QueryName: queryName,
      ModuleName: moduleName,
      EnableMultiPlantExecution: false,
      IsPublicQuery: isPublicQuery,
      FilterParameters: execParams,
      AskAtExecutionCriterias: parameters,
    });
    if (response.status !== 200) {
      setErrorSate(response.data);
    } else {
      let counter = 0;
      let queryData: QueryResult[] = [];
      if (response.data && !response.data.Success) {
        processError(response.data.Messages[0].Text);
      }
      if (response.data?.Success) {
        setTotalCount(Number.parseInt(response.data.Count));
        if (response.data?.QueryData?.QwColumn) {
          response.data.QueryData.QwColumn.forEach((field: FieldDef) => {
            fields.push(field);
          });
        }
        response.data.QueryResult.forEach((res: any) => {
          queryData.push({
            HeaderRow: counter,
            HeaderName: keyFieldName,
            HeaderText: keyFieldName,
            HeaderType: "String",
            HeaderValue: "",
            HeaderColor: "",
            Columns: [],
          });
          let cols = Object.entries(res);
          cols.forEach((col, index) => {
            //get the label from field def
            let field = fields.find((f) => {
              return col[0] === `${f.ColumnName}_${f.JoinId}`;
            });
            queryData[counter].Columns.push({
              Order: field !== undefined ? field.DisplayOrder : index,
              Name: col[0],
              Text: field !== undefined ? field.TranslatedIdText : col[0],
              Value: col[1] as string,
              Type: field !== undefined ? field.DataType : "String",
              Hidden: false,
            });
            if (field !== undefined && field.ColumnName === keyFieldName) {
              queryData[counter].HeaderText = field.TranslatedIdText;
              queryData[counter].HeaderValue = col[1] as string;
              queryData[counter].Columns[index].Hidden = true;
            }
          });
          counter++;
        });
        let pf = fields.find((f) => {
          return f.ColumnName === "priority";
        });
        let sf = fields.find((f) => {
          return f.ColumnName === "status";
        });
        let pc: ColorCodes[] = [];
        let sc: ColorCodes[] = [];
        if (
          loadCodes &&
          priorityCodes.length === 0 &&
          statusCodes.length === 0
        ) {
          pc = await getPriorityCodes();
          sc = await getStatusCodes();
        } else {
          pc = priorityCodes;
          sc = statusCodes;
        }
        if (pc.length > 0 && pf) {
          queryData.forEach((r) => {
            let pCode = r.Columns.find((c) => {
              return c.Name === `${pf?.ColumnName}_${pf?.JoinId}`;
            });
            r.HeaderColor =
              pc.find((c) => {
                return c.Code === pCode?.Value;
              })?.Color ?? "";
          });
        } else if (sc.length > 0 && sf) {
          queryData.forEach((r) => {
            let pCode = r.Columns.find((c) => {
              return c.Name === `${sf?.ColumnName}_${sf?.JoinId}`;
            });
            r.HeaderColor =
              statusCodes.find((c) => {
                return c.Code === pCode?.Value;
              })?.Color ?? "";
          });
        }
      }
      if (response.data.QueryData.QueryName === "SYS_Weekly Time Entries") {
        noOfFieldsToDisplay = fields.length;
      }
      if (noOfFieldsToDisplay) {
        for (const item of queryData) {
          item.Columns.sort((a, b) => {
            return a.Order - b.Order;
          }); //sort explicitly for iOS bug
          item.Columns = item.Columns.slice(0, noOfFieldsToDisplay);
        }
      }
      setQueryRows(queryData);
      setFilterRows(queryData.slice(0, maxResults));
    } //else
    setShowLoading(false);
  }

  const fuse = new Fuse(queryRows, {
    keys: ["Columns.Value"],
    includeScore: true,
    minMatchCharLength: 2,
    threshold: 0.5,
    shouldSort: true,
  });

  async function execFilter(queryString: string) {
    if (queryString) {
      const res = fuse.search(queryString);
      setFilterRows(res.map((r) => r.item).slice(0, maxResults));
    } else {
      setFilterRows(queryRows.slice(0, maxResults));
    }
    setResultsFilter(queryString);
  }

  let [noOfFieldsToDisplay, setNoOfFieldsToDisplay] = useState<number>();
  async function getNoOfFieldsToDisplay(option: string) {
    const response = await getOptionByIdWithCORP(option);
    const number = !!response.data.PlantValue
      ? response.data.PlantValue
      : response.data.DefaultValue;
    if (number && !Number.isNaN(+number)) {
      setNoOfFieldsToDisplay(+number);
    }
  }

  const [searchYn, setSearchYn] = useState(false);
  const [queryName, setQueryName] = useState("");
  const [criteriaParams, setCriteriaParams] = useState<CriteriaParameter[]>();
  async function confirmSearch(
    currentQuery: string,
    filterParams?: FilterParam[],
    parameters?: CriteriaParameter[]
  ) {
    setCriteriaParams(parameters);
    setQueryName(currentQuery);
    setSearchYn(true);
  }

  return {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    showLoading,
    setShowLoading,
    showToast,
    setShowToast,
    executeSearch,
    execFilter,
    queryRows,
    filterRows,
    resultsFilter,
    setQueryRows,
    getPriorityCodes,
    getStatusCodes,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    noOfFieldsToDisplay,
    getNoOfFieldsToDisplay,
    searchYn,
    setSearchYn,
    queryName,
    setQueryName,
    criteriaParams,
    confirmSearch,
  };
}
