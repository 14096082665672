import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import OnlineStatus from "../components/OnlineStatus";
import React from "react";
import StartStopComponent from "./StartStopComponent";
import { chevronBackOutline } from "ionicons/icons";

interface HeaderPageProps {
  title: string;
  showTimer?: boolean;
  timeAccess?: boolean;
  showBack?: boolean;
  subtitle?: string;
  altBackUrl?: string;
}
const PageHeader: React.FC<HeaderPageProps> = (props) => {
  const { title, showTimer, timeAccess, showBack, subtitle, altBackUrl } =
    props;
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <>
            <IonMenuButton hidden={showBack} />
            {showBack &&
              (!!altBackUrl ? (
                <IonButton routerDirection="back" routerLink={altBackUrl}>
                  <IonIcon slot="icon-only" icon={chevronBackOutline} />
                </IonButton>
              ) : (
                <IonBackButton defaultHref="/home" icon={chevronBackOutline} />
              ))}
          </>
        </IonButtons>
        <IonTitle style={{ paddingInline: "20px" }}>{title}</IonTitle>
        {showTimer && <StartStopComponent timeAccess={timeAccess} />}
      </IonToolbar>
      {subtitle && (
        <IonToolbar>
          <IonTitle>{subtitle}</IonTitle>
        </IonToolbar>
      )}
      <OnlineStatus />
    </IonHeader>
  );
};

export default PageHeader;
