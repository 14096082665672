import { HttpMethods, getRequestApiUrl, getApiRequestHeaders, sendRequest, sendRequestWithOptions } from "../util/ApiHelper";

const CONTROLLER_NAME = "Documents";

export interface DocumentData {
    DocContentBase64?: string;
    Filename?: string;
    FileModifiedDate?: string;
    Tags?: string[];
    ContentType?: string;
};

export const uploadDocument = async (data: DocumentData) => {
    return await sendRequest(
        HttpMethods.POST,
        `${CONTROLLER_NAME}/Post`,
        data,
        {}
    );
};

export interface LinkDocToWorkOrderData {
    DocId: string;
    PrintWithWoFlag: boolean;
    WoNumber: string;
    ProviderId: number;
}

export const linkDocToWorkOrder = async (data: LinkDocToWorkOrderData) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/LinkDocToWorkOrder`,
        {},
        {
            docId: data.DocId,
            printWithWoFlag: String(data.PrintWithWoFlag),
            woNumber: data.WoNumber,
            providerId: String(data.ProviderId)
        }
    );
};

export const downloadDocument = async (downloadUrl: string) => {
    return await sendRequestWithOptions(
        HttpMethods.GET,
        {
            url: await getRequestApiUrl(downloadUrl),
            headers: await getApiRequestHeaders(),
            responseType: "blob"
        }
    );
};
