import {
  IonButton,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import React from "react";

interface CardExpandableHeaderProps {
  expanded: boolean;
  onExpandClick: (expanded: boolean) => void;
  title?: string;
  subtitle?: string;
}

const CardExpandableHeader: React.FC<CardExpandableHeaderProps> = (props) => {
  const { expanded, onExpandClick, title, subtitle } = props;
  const handleClick = () => {
    onExpandClick(!expanded);
  };

  return (
    <IonGrid style={{ padding: 16, paddingRight: 8 }}>
      <IonRow class="ion-align-items-center">
        <IonCol class="ion-no-padding">
          <IonCardTitle>
            <h3 className="ion-no-margin">{title}</h3>
          </IonCardTitle>
          <IonCardSubtitle>{subtitle}</IonCardSubtitle>
        </IonCol>
        <IonCol size="auto" class="ion-no-padding">
          <IonButton
            class="ion-no-margin"
            fill="clear"
            size="small"
            onClick={handleClick}
          >
            <IonIcon
              color="primary"
              icon={expanded ? chevronUpOutline : chevronDownOutline}
            ></IonIcon>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CardExpandableHeader;
