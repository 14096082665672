import { getColumnsWithGroup } from "./Windows";
import { completeWorkOrder } from "./WorkOrders";

export const getCompletionDataFieldDef = async () => {
  return await getColumnsWithGroup({
    WindowName: "mob_completion",
    IncludeValidValues: "true",
  });
};

export const completeWO = async (body: any) => {
  return await completeWorkOrder(body);
}