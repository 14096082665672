import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "UserSetups";

export const getUserSessionSettings = async () => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetUserSessionSettings`,
    {},
    {}
  );
};

export const getUserSetups = async (httpParams: any) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Get`,
    {},
    httpParams
  );
};

export const saveUserValue = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/SaveUserValue`,
    data,
    {}
  );
};

export const getUserSetupValues = async (data: any) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/GetUserSetupValues`,
    data,
    {}
  );
};

export const getUserSetupData = async () => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetUserSetupData`
  );
};

export const putUserSetupValue = async (httpParams: any) => {
  return await sendRequest(
    HttpMethods.PUT,
    `${CONTROLLER_NAME}/Put`,
    {},
    httpParams
  );
};
