import {
  IonBadge,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  RefresherEventDetail,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getLinkedDocInfoList,
  getWorkOrder,
  WorkOrderParams,
} from "../../api/WorkOrders";
import ApiError from "../../components/ApiError";
import PageHeader from "../../components/PageHeader";
import { WorkOrder } from "../../models/workorders/WorkOrder";
import { decodeParam, encodeParam } from "../../util/ApiHelper";
import { TranslationsContext } from "../../util/Translations";
import WOContent from "../../components/WOContent";

const RequesterWOOverview: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [wOrderDate, setWorkOrderDate] = useState<any>();
  const [wOrderTime, setWorkOrderTime] = useState<any>();
  const [documentsCount, setDocumentsCount] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [errorData, setErrorData] = useState("");

  let inRefresh = false;

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    inRefresh = true;
    loadData();
    event?.detail.complete();
    inRefresh = false;
    setRefreshState(false);
  };

  useEffect(() => {
    setShowLoading(true);

    loadData().then(() => {
      setShowLoading(false);
      setRefreshState(false);
    });
  }, [workOrderId, refreshState]);

  const loadData = async () => {
    await loadWoDetails();
    await loadWoDocuments();
  };

  useEffect(() => {
    setShowLoading(true);

    // Get Work order;
    // Gets Permits info
    loadWoDetails();

    // get documents list count
    loadWoDocuments();

    //loadCompWizardSections();

    setRefreshState(false);
  }, [workOrderId, refreshState]);

  const loadWoDetails = async () => {
    const woParams: WorkOrderParams = {
      woNumber: displayWo,
      equipmentMaster: true,
      comment: true,
      events: true,
    };
    await getWorkOrder(woParams).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
      } else {
        setWorkOrder(response.data);
        if (response.data.Events) {
          setWorkOrderDate(response.data.Events?.DateCreated);
          setWorkOrderTime(response.data.Events?.TimeCreated);
        }
      }
    });
  };

  const loadWoDocuments = async () => {
    await getLinkedDocInfoList(displayWo).then((response) => {
      if (response.isError) {
        setErrorData(response.data?.toString());
        setDocumentsCount(0);
      } else if (response.data) {
        setDocumentsCount(response.data.length);
      }
    });
  };
  return (
    <IonPage>
      <PageHeader title={displayWo} showTimer={false} showBack={true} />
      <ApiError errorData={errorData} />
      <IonContent fullscreen>
        <WOContent
          handleRefresh={handleRefresh}
          inRefresh={inRefresh}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          workOrder={workOrder}
        />
        <IonList lines="full">
          <IonItem
            routerLink={`/requester/workorder/${encodeParam(
              displayWo
            )}/details`}
            detail
          >
            {translations["lbl_grp_wo_details"] || "Work Order Details"}
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/requester/workorder/${encodeParam(
              displayWo
            )}/long-description`}
          >
            <IonLabel>
              {translations["lbl_grp_long_description"] || "Long Description"}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/requester/workorder/${encodeParam(
              displayWo
            )}/remarks/${encodeParam(wOrderDate)}/${encodeParam(wOrderTime)}`}
          >
            <IonLabel>
              {translations["lbl_category_comments"] || "Comments"}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            routerLink={`/requester/workorder/${encodeParam(
              displayWo
            )}/documents`}
          >
            <IonLabel>{translations["lbl_documents"] || "Documents"}</IonLabel>
            <IonBadge slot="end">
              {documentsCount > 0 ? documentsCount : ""}
            </IonBadge>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default RequesterWOOverview;
