import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "Meters";

export interface MeterParams {
  equipSerialId: string;
  equipSerialInd: string;
  meter?: string;
}

export const getMeters = async (meterParams: MeterParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/Get`,
    undefined,
    meterParams
  );
};
