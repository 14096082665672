import { get, remove, set } from "./Preferences";

const _requestedURLKey = "RequestedURL";

export const setRequestedURL = async (pathname: string) => {
    //store URL provided or the current URL
    if (!!!pathname) {
        pathname = window.location.pathname;
    }
    //extract path if its a full url
    if (pathname.startsWith("http")) {
        pathname = getRoutePath(pathname);
    }
    //skip storing the url, if logout
    if (!pathname.includes("/logout")) {
        await set(_requestedURLKey, pathname);
    }
};

export const getRequestedURL = async () => {
    //get stored URL
    let pathname = await get(_requestedURLKey);
    if (!!pathname) {
        //clear requested url
        remove(_requestedURLKey);

        return pathname;
    }
    return "/home";
};

const getRoutePath = (path: string) => {
    if (!!path) {
        const urlObj = new URL(path);
        return `${urlObj.pathname}/${urlObj.search}`;
    }
    return path;
};