/**
 * This class handles custom error messages for the Aptean EAM application.
 */
export class ErrorHandler {
  //TODO: Extend this class to also log error messages to the server.
  static handleError(eamError: EAMError, throwError = true): void {
    if (console?.log) {
      console.log(eamError);
    }
    if (throwError) {
      throw eamError;
    }
  }
}

export class EAMError extends Error {
  public errorCode: number;
  public date: Date;
  public originalError?: Error;
  constructor(errorCode: number, originalError?: Error) {
    super();
    this.errorCode = errorCode;
    this.date = new Date();
    if (originalError) {
      this.originalError = originalError;
    }
  }
}
