import { useContext, useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonLoading,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { getUserSetups, saveUserValue } from "../api/UserSetups";
import { getLanguageList } from "../api/Languages";
import {
  TranslationMessagesContext,
  TranslationsContext,
  getTranslatedMessages,
  getTranslations,
} from "../util/Translations";

const Languages: React.FC = () => {
  const { translations, setTranslations } = useContext(TranslationsContext);
  const { setTranslatedMessages } = useContext(TranslationMessagesContext);
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);

  const getLanguages = async () => {
    getLanguageList().then((response) => {
      Array.isArray(response.data) && setLanguageList(response.data);
    });
  };

  const setLanguage = async () => {
    const httpParams = {
      userSection: "options",
      userParameter: "language",
    };
    getUserSetups(httpParams).then((response) => {
      setCurrentLanguage(response.data.UserValue);
    });
  };

  const saveLanguage = async (lang: string) => {
    setCurrentLanguage(lang);
    const data = {
      UserParameter: "language",
      UserSection: "options",
      UserValue: lang === "" ? "American English" : lang,
    };
    setShowLoading(true);
    await saveUserValue(data);
    await saveTranslations();
    setShowLoading(false);
  };

  const saveTranslations = async () => {
    const translations = await getTranslations();
    setTranslations && setTranslations(translations);
    const translatedMessages = await getTranslatedMessages();
    setTranslatedMessages && setTranslatedMessages(translatedMessages);
  };

  useEffect(() => {
    setShowLoading(true);
    Promise.all([setLanguage(), getLanguages()]).finally(() => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonItem>
        <IonLabel position="stacked">
          {translations["lbl_language"] || "Language"}
        </IonLabel>
        <IonSelect
          interface="alert"
          placeholder={translations["lbl_btn_select"] || "Select"}
          value={currentLanguage}
          onIonChange={(ev) => {
            if (ev.detail.value && languageList.includes(ev.detail.value)) {
              saveLanguage(ev.detail.value);
            }
          }}
        >
          {languageList.map((lang, index) => {
            return (
              <IonSelectOption key={`${index}-${lang}`}>{lang}</IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </>
  );
};
export default Languages;
