import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext } from "react";
import { TranslationsContext } from "../util/Translations";

interface MaterialIssueCommentsProps {
  comments?: string;
  onSave: () => void;
  onCancel: () => void;
  isOpen: boolean;
  onChange: (comment: string) => void;
}

const MaterialIssueComments: React.FC<MaterialIssueCommentsProps> = (props) => {
  const { comments, onSave, onCancel, isOpen, onChange } = props;
  const { translations} = useContext(TranslationsContext);

  return (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={onCancel}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>{translations["lbl_btn_cancel"] || "Cancel"}</IonButton>
          </IonButtons>
          <IonTitle>{translations["lbl_btn_comments"] || "Comments"}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onSave} color="primary">{translations["lbl_btn_save"] || "Save"}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <IonItem lines="full" fill="outline">
          <IonTextarea
            value={comments}
            onIonChange={(ev) => {
              onChange(ev.detail.value || "");
            }}
            maxlength={255}
            autoGrow
            placeholder={translations["lbl_enter comments"] || "Enter Comments"}
          ></IonTextarea>
        </IonItem>
      </IonContent>
    </IonModal>
  );
};

export default MaterialIssueComments;
