import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./LongDescriptionTab.css";
import { useLocation, useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import { getColumnsWithGroup } from "../api/Windows";
import {
  WorkOrderParams,
  getWorkOrder,
  PutPartialWorkOrderParams,
  putPartialWorkOrder,
} from "../api/WorkOrders";
import { chatboxEllipsesOutline } from "ionicons/icons";
import OnlineStatus from "./OnlineStatus";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import {
  ScreenOrientation,
  ScreenOrientationChange,
} from "@capawesome/capacitor-screen-orientation";
import { isLandscapeMode, setScreenOrientation } from "../util/Device";

interface DescriptionMetadata {
  Groups?: any[];
  fields?: any;
  Actions?: any[];
  IsAutoPO?: boolean;
}

interface DescriptionProtected {
  IsProtected?: boolean;
  FieldOrder: string;
}

const LongDescriptionTab: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  let isRequester = useLocation().pathname.includes("requester");
  const [presentAlert] = useIonAlert();

  const [metadata, setMetadata] = useState<DescriptionMetadata>();
  const [metadataError, setMetadataError] = useState(false);
  const [longDescription, setLongDescription] = useState<string>("");
  const [longDescErrorState, setLongDescErrorState] = useState(false);

  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [landscapeMode, setLandscapeMode] = useState(false);
  const [listnerAdded, setListnerAdded] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [longDescComment, setLongDescComment] = useState<any>();

  const [showLoading, setShowLoading] = useState(false);

  const metadataQuery = async () => {
      const data = {
        IncludeValidValues: false,
        IncludeValidValuesExceptions: [],
        IsReadOnly: true,
        ValidValueFilters: [],
        WindowName: isRequester
          ? "mob_requester_wo_nb_comments"
          : "mob_wo_nb_comments",
        ContextPKey: {
          Plant: await getCurrentPlant(),
          WoBase: "",
          WoTask: "  ",
          WoSubtask: "  ",
        },
      };
      getColumnsWithGroup(data).then((response) => {
        if (response.status !== 200) {
          setMetadataError(true);
        } else if (response.data) {
          setMetadata(response.data);
          setMetadataError(false);
        }
      });
  };

  const getLongDescription = async () => {
    const httpParams: WorkOrderParams = {
      woNumber: displayWo,
      comment: true,
      appName: "Mobile",
      translations: true,
    };
    getWorkOrder(httpParams).then((response) => {
      if (response.status !== 200) {
        setLongDescription("");
        setLongDescErrorState(true);
      } else if (response.data) {
        let longDescString = response.data.Comment.LongDescription as string;
        // Remove the font tag and replace it with span, as the html tag "font" is no longer supported in html5
        let correctedLongDesc = longDescString
          .replace(/<font color="/gi, '<span style="color:')
          .replace(/<\/font/gi, "</span");
        setLongDescription(correctedLongDesc);
        setLongDescErrorState(false);
      }
    });
  };

  useEffect(() => {
    setMetadataError(false);
    setLongDescErrorState(false);
    // Show loading symbol
    setShowLoading(true);
    metadataQuery().then(() => {
      getLongDescription();
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
    });
  }, []);

  const getFieldsFromMetadata = (metadata: DescriptionMetadata) => {
    const fields: DescriptionProtected[] = [];
    const metadataFields = metadata.fields;
    const keys = Object.keys(metadataFields);
    keys.forEach((key) => {
      const field = metadataFields[key];
      fields.push(field);
    });
    return fields;
  };

  const getAllFields = (metadata: DescriptionMetadata) => {
    const fields = getFieldsFromMetadata(metadata);
    return [...fields].sort((a, b) => {
      return +a.FieldOrder - +b.FieldOrder;
    });
  };

  const onWillPresent = async () => {
    if (!listnerAdded) {
      ScreenOrientation.addListener(
        "screenOrientationChange",
        async (orientationChange: ScreenOrientationChange) => {
          setListnerAdded(true);
          await setScreenOrientation(orientationChange.type);
          const isLandscape = await isLandscapeMode(orientationChange.type);
          setLandscapeMode(isLandscape);

          //update the breakpoint to reflect the change in orientation
          (
            document.getElementById("actionBarModal") as HTMLIonModalElement
          ).setCurrentBreakpoint(isLandscape ? 0.8 : 0.6);
        }
      );
    }
  };

  function onCommentChange(event: any) {
    setSaveButtonDisabled(true);
    if (event.length > 0) {
      setSaveButtonDisabled(false);
    }
    setLongDescComment(event);
  }

  function saveNewComment() {
    const currentDate = new Date();
    const commentDate = `${currentDate.getFullYear()}-${currentDate.toLocaleDateString(
      undefined,
      { month: "2-digit" }
    )}-${currentDate.toLocaleDateString(undefined, {
      day: "2-digit",
    })} ${currentDate.toLocaleTimeString(undefined, {
      timeZoneName: "short",
    })}`;
    let data = {
      WorkOrderNumber: displayWo,
      Updates: [
        {
          AppendMode: true,
          AppendType: "prefix",
          ColumnName: "long_description",
          PropertyName: "LongDescription",
          TableName: "wo_comments",
          UserDateTime: commentDate,
          DataValue: longDescComment,
        },
      ],
    } as PutPartialWorkOrderParams;
    setShowLoading(true);
    putPartialWorkOrder(data).then((response) => {
      setShowLoading(false);
      if (response.status === 200 && response.data.Success) {
        presentAlert({
          header: `${translations["lbl_success"] || "Success"}!`,
          message:
            translatedMessages["DescriptionSaveSuccess"]?.MessageText ||
            "Description saved successfully.",
          onDidDismiss: () => {
            setShowLoading(true);
            setLongDescComment("");
            setCommentModalOpen(false);
            setSaveButtonDisabled(true);
            metadataQuery().then(() => {
              getLongDescription();
              // Hide loading symbol once all the calls are done
              setShowLoading(false);
            });
          },
          buttons: ["OK"],
        });
      } else {
        // Display failure modal
        let errors = [] as string[];
        response.data.MessageList.forEach((message: any) => {
          errors.push(message.Text);
        });
        let message = "";
        if (errors.length > 0) {
          message = errors.join("\n");
        }
        presentAlert({
          header:
            translatedMessages["DescriptionSaveError"]?.MessageText ||
            "Error saving description.",
          message: message,
          buttons: [
            {
              text: "OK",
            },
          ],
        });
      }
    });
  }

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      {(metadataError || longDescErrorState) && (
        <IonLabel color="danger">
          {translatedMessages["ErrLongDesc"]?.MessageText ||
            "Error retrieving long description"}
        </IonLabel>
      )}
      {!!metadata &&
        getAllFields(metadata).map((field, index) => {
          return (
            <ReactQuill
              key={index}
              readOnly
              className={field.IsProtected ? "ql-read-only" : "ql-editable"}
              theme="snow"
              value={longDescription}
              modules={{
                toolbar: [
                  [
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    { list: "ordered" },
                    { list: "bullet" },
                    "clean",
                    { align: [] },
                    { indent: "-1" },
                    { indent: "+1" },
                    { color: [] },
                    { background: [] },
                  ],
                ],
                history: {
                  userOnly: true,
                  maxStack: 50,
                },
              }}
            />
          );
        })}
      {isRequester && (
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton
            onClick={() => {
              setCommentModalOpen(true);
            }}
          >
            <IonIcon icon={chatboxEllipsesOutline}></IonIcon>
          </IonFabButton>
        </IonFab>
      )}
      {isRequester && (
        <IonModal
          id="longDescCommentModal"
          isOpen={commentModalOpen}
          backdropDismiss={true}
          handle={true}
          breakpoints={[0, 0.6, 0.8, 1]}
          initialBreakpoint={landscapeMode ? 0.8 : 0.6}
          onDidDismiss={() => setCommentModalOpen(false)}
          onWillPresent={onWillPresent}
          keepContentsMounted
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    setCommentModalOpen(false);
                    setLongDescComment("");
                    setSaveButtonDisabled(true);
                  }}
                >
                  {translations["lbl_btn_cancel"] || "Cancel"}
                </IonButton>
              </IonButtons>
              <IonTitle>
                {translations["lbl_add_comment"] || "Add Comment"}
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="solid"
                  color="primary"
                  disabled={saveButtonDisabled}
                  onClick={saveNewComment}
                >
                  {translations["lbl_btn_save"] || "Save"}
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <OnlineStatus />
          </IonHeader>
          <IonContent id="add-comment-container">
            <ReactQuill
              id={"add-long-desc-comment"}
              key={"add-long-desc-comment"}
              className={"ql-editable"}
              theme="snow"
              onChange={onCommentChange}
              value={longDescComment}
              modules={{
                toolbar: [
                  [
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    { list: "ordered" },
                    { list: "bullet" },
                    "clean",
                    { align: [] },
                    { indent: "-1" },
                    { indent: "+1" },
                    { color: [] },
                    { background: [] },
                  ],
                ],
                history: {
                  userOnly: true,
                  maxStack: 50,
                },
              }}
            />
          </IonContent>
        </IonModal>
      )}
    </>
  );
};

export default LongDescriptionTab;
