import { sendRequest, HttpMethods } from "../util/ApiHelper";

const CONTROLLER_NAME = "SyCodes";

export interface GetSyCode30RecordsParams {
    subCategory: string;
    isDataTransNeeded: boolean;
};

export const getSyCode30Records = async (params: GetSyCode30RecordsParams) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/GetSyCode30Records`,
        {},
        {
            subCategory: params.subCategory,
            isDataTransNeeded: params.isDataTransNeeded ? "true" : "false"
        }
    );
};