import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonAlert,
  useIonViewDidLeave,
} from "@ionic/react";
import OnlineStatus from "./OnlineStatus";
import {
  GetColumnsWithGroupField,
  GetColumnsWithGroupResponse,
} from "../models/windows/GetColumnsWithGroup";
import { useContext, useEffect, useState } from "react";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { adjustTransactionForIssue } from "../api/Materials";
import { getInventoryControl } from "../api/PlantSetups";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { useStoresManager } from "../hooks/useStoresManager";
import {
  barcodeOutline,
  barcodeSharp,
  chevronBackOutline,
} from "ionicons/icons";
import { ApiResponse } from "../models/api/ApiResponse";
import {
  performBarCodeShimProcess,
  findObjectByElementType,
} from "../util/BarCodeHelper";
import {
  BinInfo,
  formatData,
  getAllFields,
} from "../models/materialissue/MaterialIssue";
import { useBarcodeManager } from "../hooks/useBarcodeManager";

const ItemAdjustment: React.FC = () => {
  const {
    isCommentsRequired,
    item,
    description,
    unitOfIssue,
    binInfo,
    results,
    multiWarehouseCheck,
    storeLocation,
    itemFromInventory,
    setStoreLocation,
    checkMultiWarehouseOption,
    getDefaultStoreLocation,
    onStoreLocationSelect,
    getGeneralInfo,
    getSearchParamters,
    handleItemClick,
    checkRequireComments,
    resetFields,
  } = useStoresManager();
  const [presentAlert] = useIonAlert();
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [showLoading, setShowLoading] = useState(false);
  const { scanText, setScanText, scanClick } = useBarcodeManager();
  const [storeLocationField, setStoreLocationField] =
    useState<GetColumnsWithGroupField>();
  const [itemField, setItemField] = useState<GetColumnsWithGroupField>();
  const [descriptionField, setDescriptionField] =
    useState<GetColumnsWithGroupField>();
  const [unitOfIssueField, setUnitOfIssueField] =
    useState<GetColumnsWithGroupField>();
  const [qtyOnHandSetting, setQtyOnHandSetting] = useState<string>();
  const [showToast, setShowToast] = useState(false);
  let [count, setCount] = useState<number>();
  const [comments, setComments] = useState<string>();
  const [adjustColumns, setAdjustColumns] =
    useState<GetColumnsWithGroupResponse>();
  const [adjustDetails, setAdjustDetails] =
    useState<GetColumnsWithGroupResponse>();
  const commentTranslation =
    translations["lbl_enter comments"] || "Enter Comments";

  const getAdjustColumns = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_adjust",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };
  const [itemVal, setItemVal] = useState<string>("");
  const [storesLocationList, setStoresLocationList] = useState<any[]>([]);

  const getAdjustDetails = async () => {
    const data: ColumnsWithGroupData = {
      WindowName: "mob_inventory_adjust_details",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    return response.data as GetColumnsWithGroupResponse;
  };

  const setup = async () => {
    setShowLoading(true);
    const adjustColumns = await getAdjustColumns();
    setAdjustColumns(adjustColumns);
    const adjustDetails = await getAdjustDetails();
    setAdjustDetails(adjustDetails);
    const defaultStore = await getDefaultStoreLocation();
    defaultStore && setStoreLocation(defaultStore);
    checkMultiWarehouseOption();
    checkRequireComments();
    setShowLoading(false);
  };

  const checkQtyOnHandSetting = async () => {
    getInventoryControl().then((response) => {
      setQtyOnHandSetting(response.data?.NegativeQtyOnHandMessage);
    });
  };

  const setCountValue = async (countValue: number, index: number) => {
    binInfo[index].TransactionQuantity = countValue;
    setCount(binInfo[index]?.TransactionQuantity);
  };

  const handleComment = async (comment: string, index: number) => {
    if (comment) {
      binInfo[index].Comment = comment;
      setComments(binInfo[index]?.Comment);
    }
  };

  const saveAdjustments = async () => {
    let binDataArray = [];
    for (let bin of binInfo) {
      if (bin.TransactionQuantity) {
        const binData = {
          Bin: bin.Bin,
          Comment: comments ? bin.Comment : "",
          StoresLocation: bin.StoresLocation,
          TransactionQuantity: bin.TransactionQuantity,
        };
        binDataArray.push(binData);
      }
    }
    const data = {
      Item: item,
      StoresLocation: storeLocation,
      BinDataForAdjustments: binDataArray,
    };
    for (let i = 0; i < binDataArray.length; i++) {
      if (qtyOnHandSetting === "e" && binDataArray[i].TransactionQuantity < 0) {
        presentAlert({
          header:
            translatedMessages["NegativeQtyOnHandError"]?.MessageText ||
            "The current Bin Quantity is not sufficient to issue the chosen quantity of this item.",
          buttons: [
            {
              text: `${translations["lbl_btn_ok"] || "OK"}`,
            },
          ],
        });
        break;
      } else if (
        qtyOnHandSetting === "w" &&
        binInfo[i].TransactionQuantity < 0
      ) {
        setShowToast(true);
      }
    }
    let foundNegative = binDataArray.find((bin) => bin.TransactionQuantity < 0);
    if (
      !foundNegative ||
      qtyOnHandSetting === "w" ||
      qtyOnHandSetting === "n"
    ) {
      adjustTransactionForIssue(data).then((response) => {
        onHandSettingCheck(response);
      });
    }
  };

  const onHandSettingCheck = async (response: ApiResponse) => {
    if (response.data.Success) {
      presentAlert({
        header:
          translatedMessages["ItemSavedSuccesfully"]?.MessageText ||
          "Item Saved Successfully",
        buttons: [
          {
            text: `${translations["lbl_btn_ok"] || "OK"}`,
          },
        ],
      });
      resetFields();
      setScanText("");
      setComments("");
      setCount(undefined);
    } else {
      presentAlert({
        header: response.data.MessageList[0]?.Text,
        buttons: [
          {
            text: `${translations["lbl_btn_ok"] || "OK"}`,
          },
        ],
      });
    }
  };

  async function onScanTextEnter(ev: any): Promise<void> {
    let res = String(ev.target.value);
    setItemVal(res);
    if (itemVal === res) return;
    const barCodeFieldData = findObjectByElementType(adjustColumns?.fields);
    const barcodeResult = await performBarCodeShimProcess(
      barCodeFieldData,
      res
    );
    if (barcodeResult) {
      if (barcodeResult.NoMatch) {
        setItemVal(res);
        setScanText(res);
        return;
      }
      let capturingGroups = barcodeResult.CapturingGroupValues;
      if (capturingGroups?.Item || barcodeResult.FirstCapturingGroup) {
        res = capturingGroups?.Item || barcodeResult.FirstCapturingGroup;
        setItemVal(res);
        let storesLocation = capturingGroups?.StoresLocation;
        if (storesLocation) {
          let storesLocationFilter = storesLocationList.filter(
            (x) => x.Code === storesLocation
          );
          if (storesLocationFilter.length > 0) {
            setStoreLocation(storesLocation);
          } else {
            presentAlert(
              `User doesn't have access to the stores location ${storesLocation}.`
            );
            setScanText("");
            setItemVal("");
            return;
          }
        }
      }
    }
    setScanText(res);
    handleItemClick(res);
  }

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    checkQtyOnHandSetting();
    if (adjustColumns) {
      const storeLocationField: GetColumnsWithGroupField =
        adjustColumns?.fields?.["in_log_material.stores_location"];
      storeLocationField && setStoreLocationField(storeLocationField);
      // Default to only store location
      if (
        storeLocationField?.ValidValues &&
        storeLocationField.ValidValues.length === 1
      ) {
        const store = storeLocationField.ValidValues[0];
        setStoreLocation(store.Code);
      }
      if (storeLocationField && storeLocationField.ValidValues)
        setStoresLocationList(storeLocationField.ValidValues);
      const itemField = adjustColumns?.fields?.["in_log_material.item"];
      itemField && setItemField(itemField);
      setShowLoading(false);

      const descriptionField =
        adjustColumns?.fields?.["in_item_location.description"];
      descriptionField && setDescriptionField(descriptionField);

      const unitOfIssueField =
        adjustColumns?.fields?.["in_item_location.unit_of_issue"];
      unitOfIssueField && setUnitOfIssueField(unitOfIssueField);
      if (itemFromInventory) {
        getGeneralInfo(itemFromInventory);
      }
    }
  }, [
    storeLocation,
    adjustColumns,
    qtyOnHandSetting,
    itemFromInventory,
    description,
    unitOfIssue,
  ]);

  useIonViewDidLeave(() => {
    resetFields();
    setScanText("");
    setComments("");
    setCount(undefined);
  }, []);

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        duration={5000}
        position="top"
        onDidDismiss={() => {
          setShowToast(false);
        }}
        message={`${translations["lbl_warning"] || "Warning"}:
            ${
              translatedMessages["NegativeQtyOnHandWarning"]?.MessageText ||
              "The current Bin Quantity is not sufficient to issue the chosen quantity of this item."
            }`}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {itemFromInventory ? (
              <IonButton routerDirection="back" routerLink="/Inventory">
                <IonIcon slot="icon-only" icon={chevronBackOutline} />
              </IonButton>
            ) : (
              <IonMenuButton />
            )}
          </IonButtons>
          <IonTitle>
            {translations["lbl_mob_itemadjustment"] || "Item Adjustments"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={!count} onClick={() => saveAdjustments()}>
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <OnlineStatus />
      </IonHeader>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={3000}
      />
      <IonContent>
        <IonItem lines="full">
          <IonLabel>
            <h1>{translations["lbl_item_search"] || "Item Search"}</h1>
          </IonLabel>
        </IonItem>
        {!!multiWarehouseCheck && multiWarehouseCheck === true && (
          <IonItem>
            <IonLabel>{storeLocationField?.TranslatedIdText}*</IonLabel>
            <IonSelect
              value={storeLocation}
              onIonChange={(ev) => {
                onStoreLocationSelect(ev.detail.value);
              }}
              interface="popover"
            >
              {storeLocationField?.ValidValues?.map((value) => {
                return (
                  <IonSelectOption
                    value={value.Code}
                    key={`store-location-${value.Code}-${value.Description}`}
                  >
                    {value.Code}
                    {value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        )}
        <IonItem>
          <IonLabel>{itemField?.TranslatedIdText}*</IonLabel>
          {itemFromInventory ? (
            <IonInput
              class="ion-text-end"
              value={itemFromInventory}
              readonly
            ></IonInput>
          ) : (
            <IonInput
              class="ion-text-end"
              value={scanText}
              placeholder={`${
                translations["lbl_search_item"] || "Search Item"
              }...`}
              debounce={1500}
              onIonChange={(ev) => {
                if (ev.detail.value === undefined || ev.detail.value === null)
                  return;
                setScanText(ev.detail.value);
                getSearchParamters(ev.detail.value);
                onScanTextEnter(ev);
              }}
            ></IonInput>
          )}
          <IonIcon
            slot="end"
            ios={barcodeOutline}
            md={barcodeSharp}
            onClick={() => scanClick()}
          ></IonIcon>
        </IonItem>
        {results.length > 0 && (
          <IonList lines="full">
            {results.map((result, index) => {
              return (
                <IonItem
                  button
                  key={index}
                  onClick={() => {
                    handleItemClick(result.item);
                  }}
                >
                  <IonLabel>{`${result.item} - ${result.description}`}</IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        )}
        <IonItem hidden={description === ""}>
          <IonLabel>{descriptionField?.TranslatedIdText}</IonLabel>
          <IonText slot="end">{description}</IonText>
        </IonItem>
        <IonItem hidden={unitOfIssue === ""}>
          <IonLabel>{unitOfIssueField?.TranslatedIdText}</IonLabel>
          <IonText slot="end">{unitOfIssue}</IonText>
        </IonItem>
        <IonItem lines="full" hidden={!description && !unitOfIssue}>
          <IonLabel>
            <h1>{translations["lbl_mob_adjustcounts"] || "Adjust Counts"}</h1>
          </IonLabel>
        </IonItem>
        {!!adjustDetails &&
          !!binInfo &&
          binInfo.map((bin, index) => {
            return (
              <IonCard key={index}>
                {getAllFields(adjustDetails).map((field) => {
                  const data = bin[field.PropertyName as keyof BinInfo];
                  return (
                    <>
                      {field.ElementType !== "textArea" && (
                        <IonItem>
                          <IonLabel>{field.TranslatedIdText}</IonLabel>
                          <IonText slot="end">
                            {formatData(data, field)}
                          </IonText>
                        </IonItem>
                      )}
                    </>
                  );
                })}
                <IonItem>
                  <IonLabel>
                    {translations[
                      "lbl_initemlocation_qtyonhand_INV_QTY_GLANCE"
                    ] || "On Hand"}
                  </IonLabel>
                  <IonInput
                    class="ion-text-end"
                    inputMode="decimal"
                    type="number"
                    placeholder={translations["lbl_mob_count"] || "Enter Count"}
                    onIonInput={(ev) => {
                      setCountValue(Number(ev.target.value), index);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    {translations["lbl_incyclecountbatch_comment"] || "Comment"}
                    {isCommentsRequired ? "*" : ""}
                  </IonLabel>
                  <IonInput
                    class="ion-text-end"
                    placeholder={
                      isCommentsRequired
                        ? `${commentTranslation}`
                        : `${commentTranslation} ${
                            translations["lbl_optional_brackets"] ||
                            "(Optional)"
                          }`
                    }
                    onIonInput={(ev) => {
                      handleComment(String(ev.target.value), index);
                    }}
                  ></IonInput>
                </IonItem>
              </IonCard>
            );
          })}
      </IonContent>
    </IonPage>
  );
};

export default ItemAdjustment;
