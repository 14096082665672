import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "EquipmentHierarchys"; //Api has a typo

export interface GetHierarchyListParams {
  equipment: string
}

export const getEquipmentHierarchyList = async (params: GetHierarchyListParams) => {
  return await sendRequest(
    HttpMethods.GET,
    `${CONTROLLER_NAME}/GetHierarchyList`,
    undefined,
    params
  );
};
