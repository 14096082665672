import { HttpMethods, sendRequest } from "../util/ApiHelper";

const CONTROLLER_NAME = "Languages";

export const translateMessageList = async (
  data: { MessageId: string; MessageParameters: any }[]
) => {
  return await sendRequest(
    HttpMethods.POST,
    `${CONTROLLER_NAME}/TranslateMessageList`,
    data
  );
};

export const getLanguageList = async () => {
  return await sendRequest(HttpMethods.GET, `${CONTROLLER_NAME}/Get`);
};
