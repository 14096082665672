import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { searchOutline, searchSharp } from "ionicons/icons";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { CriteriaDef, CriteriaParameter } from "../hooks/useQueryExec";
import { TranslationsContext } from "../util/Translations";
import { ApiResponse } from "../models/api/ApiResponse";
import { getValueSearchDetails } from "../api/QueryDesigner";
import FilterModal from "./FilterModal";
import SelectWithAllOption from "./SelectWithAllOption";

interface CriteriaModalProps {
  isOpen: boolean;
  queryCriteria: CriteriaDef[];
  loggedInEmployee: string;
  cancel: () => void;
  confirm: (parameters: CriteriaParameter[]) => void;
}
interface ValueSearchDetail {
  Code: string;
  Description: string;
}
interface SearchableField {
  columnName: string;
  values: ValueSearchDetail[];
}

const CriteriaModal: React.FC<CriteriaModalProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { isOpen, queryCriteria, loggedInEmployee, cancel, confirm } = props;
  const [parameters, setParameters] = useState<CriteriaParameter[]>([]);
  const [oldParameters, setOldParameters] = useState<CriteriaParameter[]>([]);
  const [fieldValues, setFieldValues] = useState<SearchableField[]>([]);
  const [queryFields, setQueryFields] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterModalCriteriaName, setFilterModalCriteriaName] =
    useState<CriteriaDef>();
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const [filterModalPairValue, setFilterModalPairValue] = useState<
    { value?: string; placement: "front" | "back" } | undefined
  >();

  const getValidValuesForCriteria = async (criteria: CriteriaDef) => {
    const response: ApiResponse = await getValueSearchDetails({
      tableName: criteria.TableName,
      columnName: criteria.ColumnName,
    });
    if (
      response.data.Data.length === 0 &&
      !!response.data.QueryWizardModuleName
    ) {
      return {
        IsQueryWizard: true,
        moduleName: response.data.QueryWizardModuleName,
      };
    }
    return response.data.Data as ValueSearchDetail[];
  };

  // Some default comparison operaterators for dates will be "Today" or "Today + 365" or etc...
  const parseDateString = (str: string) => {
    if (str === "Today") {
      return moment().toISOString();
    }
    let date = moment(new Date(str));
    if (str.includes("Today - ") || str.includes("Today + ")) {
      const days = Number(str.slice(8));
      if (str.includes("-")) {
        date = moment().subtract(days, "days");
      } else {
        date = moment().add(days, "days");
      }
    }
    return date.isValid() ? date.toISOString() : moment().toISOString();
  };

  const setDefaultParameters = () => {
    const parameters: CriteriaParameter[] = [];
    for (const criteria of queryCriteria) {
      let value = criteria.ComparisonExpression;
      if (criteria.DataType === "DateTime") {
        if (value.includes(",")) {
          let dateArray = value.split(",");
          for (let i = 0; i < dateArray.length; i++) {
            dateArray[i] = parseDateString(dateArray[i]);
          }
          value = dateArray.toString();
        } else {
          value = parseDateString(value);
        }
      }
      if (!!value) {
        parameters.push({
          CriteriaSequence: criteria.Sequence,
          TableAlias: criteria.TableAlias,
          ColumnAlias: criteria.ColumnName,
          JoinID: criteria.JoinId,
          ComparisonExpression: value,
          CompanionElement: criteria.CompanionElement,
          CompanionExpression: criteria.CompanionExpression,
        });
      }
    }
    setParameters(parameters);
    setOldParameters(parameters);
  };

  const setNewValidValues = async () => {
    const fields: SearchableField[] = [];
    const queryFields = [];
    for (const criteria of queryCriteria) {
      if (criteria.isSearchableCriteriaColumn) {
        const values = await getValidValuesForCriteria(criteria);
        if (Array.isArray(values)) {
          fields.push({ columnName: criteria.ColumnName, values: values });
        } else if (values.IsQueryWizard) {
          queryFields.push({
            columnName: criteria.ColumnName,
            moduleName: values.moduleName,
          });
        }
      }
    }
    setFieldValues(fields);
    setQueryFields(queryFields);
  };

  const setup = async () => {
    setShowLoading(true);
    await setNewValidValues();
    setDefaultParameters();
    setShowLoading(false);
  };

  const getValuesForSearchableColumn = (columnName: string) => {
    const field = fieldValues.find((searchableField) => {
      return searchableField.columnName === columnName;
    });
    return field?.values || [];
  };

  const getCurrentValue = (criteria: CriteriaDef) => {
    const field = parameters.find((parameter) => {
      if (parameter.ColumnAlias === "meter") {
        return parameter.TableAlias === criteria.TableName;
      } else return parameter.ColumnAlias === criteria.ColumnName;
    });
    if (
      field?.ComparisonExpression &&
      field?.ComparisonExpression === "LoggedInUser"
    ) {
      field.ComparisonExpression = loggedInEmployee;
    }
    return field?.ComparisonExpression;
  };

  const onValueChange = (criteria: CriteriaDef, value: string) => {
    const doesParameterExist = parameters.find((parameter) => {
      return parameter.ColumnAlias === criteria.ColumnName;
    });
    let newParameters = [...parameters];
    if (!doesParameterExist) {
      newParameters.push({
        CriteriaSequence: criteria.Sequence,
        TableAlias: criteria.TableAlias,
        ColumnAlias: criteria.ColumnName,
        JoinID: criteria.JoinId,
        ComparisonExpression: value,
        CompanionElement: criteria.CompanionElement,
        CompanionExpression: criteria.CompanionExpression,
      });
    } else {
      newParameters = [...parameters].map((parameter) => {
        if (parameter.ColumnAlias !== criteria.ColumnName) {
          return parameter;
        } else if (parameter.TableAlias !== criteria.TableName) {
          return parameter;
        } else {
          return { ...parameter, ComparisonExpression: value };
        }
      });
    }
    setParameters(newParameters);
  };

  const onConfirm = () => {
    setOldParameters(parameters);
    // Stringify multi-select-option parameters.
    const mappedParams = parameters.map((param) => {
      if (Array.isArray(param.ComparisonExpression)) {
        return {
          ...param,
          ComparisonExpression: param.ComparisonExpression.toString(),
        };
      }
      return param;
    });
    confirm(mappedParams);
  };

  const onCancel = () => {
    cancel();
    setParameters(oldParameters);
  };

  useEffect(() => {
    setParameters([]);
    setOldParameters([]);
    setFieldValues([]);
    setQueryFields([]);
    if (queryCriteria && queryCriteria.length > 0) {
      setup();
    }
  }, [queryCriteria]);

  const onFilterModalCancel = () => {
    setIsFilterModalOpen(false);
    setFilterModalCriteriaName(undefined);
    setFilterModalModuleName("");
    setFilterModalPairValue(undefined);
  };

  const onFilterModalConfirm = (selectedItem: string) => {
    if (filterModalCriteriaName) {
      if (filterModalPairValue && filterModalPairValue.placement === "front") {
        onValueChange(
          filterModalCriteriaName,
          `${filterModalPairValue.value || ""},${selectedItem}`
        );
      } else if (
        filterModalPairValue &&
        filterModalPairValue.placement === "back"
      ) {
        onValueChange(
          filterModalCriteriaName,
          `${selectedItem},${filterModalPairValue.value || ""}`
        );
      } else {
        onValueChange(filterModalCriteriaName, selectedItem);
      }
      setIsFilterModalOpen(false);
      setFilterModalCriteriaName(undefined);
      setFilterModalModuleName("");
      setFilterModalPairValue(undefined);
    }
  };

  return (
    <>
      <FilterModal
        isOpen={isFilterModalOpen}
        moduleName={filterModalModuleName}
        cancel={onFilterModalCancel}
        confirm={onFilterModalConfirm}
      />
      <IonModal isOpen={isOpen} onIonModalWillDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                {translations["lbl_btn_cancel"] || "Cancel"}
              </IonButton>
            </IonButtons>
            <IonTitle>
              {translations["lbl_ask_at_execution"] || "Ask At Execution"}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                strong={true}
                onClick={onConfirm}
                disabled={parameters.length < queryCriteria.length}
              >
                {translations["lbl_btn_search"] || "Search"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonLoading
            isOpen={showLoading}
            duration={10000}
            onDidDismiss={() => {
              setShowLoading(false);
            }}
          />
          <IonList lines="full">
            {!showLoading &&
              queryCriteria.map((criteria) => {
                const formDateTimes = () => {
                  if (criteria.ComparisonExpression.includes(",")) {
                    // If there are multiple dates that need to be rendered
                    let dateString = getCurrentValue(criteria);
                    let dateArray = dateString?.split(",");
                    return (
                      <>
                        {dateArray?.map((date, index) => {
                          return (
                            <>
                              <IonModal
                                keepContentsMounted
                                key={`${criteria.ColumnName}-modal-${
                                  index + 1
                                }`}
                              >
                                <IonDatetime
                                  id={`criteria-date-${criteria.ColumnName}-${
                                    index + 1
                                  }`}
                                  presentation="date"
                                  value={date}
                                  onIonChange={(e) => {
                                    let newValue = String(e.detail.value);
                                    let fullString = dateString?.replace(
                                      date,
                                      newValue
                                    );
                                    onValueChange(criteria, String(fullString));
                                  }}
                                />
                              </IonModal>
                              <IonDatetimeButton
                                datetime={`criteria-date-${
                                  criteria.ColumnName
                                }-${index + 1}`}
                                key={`${criteria.ColumnName} + -button-${
                                  index + 1
                                }`}
                              ></IonDatetimeButton>
                            </>
                          );
                        })}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <IonModal
                          keepContentsMounted
                          key={criteria.ColumnName + "-modal"}
                        >
                          <IonDatetime
                            id={`criteria-date-${criteria.ColumnName}`}
                            presentation="date"
                            value={getCurrentValue(criteria)}
                            onIonChange={(e) => {
                              onValueChange(criteria, String(e.detail.value));
                            }}
                          />
                        </IonModal>
                        <IonDatetimeButton
                          datetime={`criteria-date-${criteria.ColumnName}`}
                          key={criteria.ColumnName + "-button"}
                        ></IonDatetimeButton>
                      </>
                    );
                  }
                };
                let queryField = queryFields.find((query) => {
                  return query.columnName === criteria.ColumnName;
                });
                if (!!queryField && criteria.ComparisonOperator === "between") {
                  let splitVal = getCurrentValue(criteria)?.split(",");

                  return (
                    <IonItemGroup
                      key={`${criteria.ColumnName}-${criteria.TableName}`}
                    >
                      <IonItem lines="none">
                        <IonLabel>{`${criteria.TranslatedIdText} ${criteria.ComparisonOperator}`}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonGrid class="ion-no-padding">
                          <IonRow>
                            <IonCol>
                              <IonItem lines="none" class="ion-no-padding">
                                <IonInput
                                  class="ion-text-end"
                                  value={splitVal?.at(0)}
                                  onIonInput={(e) => {
                                    onValueChange(
                                      criteria,
                                      `${String(e.target.value)}${
                                        splitVal ? "," + splitVal[1] : ""
                                      }`
                                    );
                                  }}
                                ></IonInput>
                                <IonIcon
                                  slot="end"
                                  ios={searchOutline}
                                  md={searchSharp}
                                  onClick={async () => {
                                    setFilterModalCriteriaName(criteria);
                                    setFilterModalModuleName(
                                      queryField.moduleName
                                    );
                                    setFilterModalPairValue({
                                      value: splitVal?.at(1),
                                      placement: "back",
                                    });
                                    setIsFilterModalOpen(true);
                                  }}
                                ></IonIcon>
                              </IonItem>
                            </IonCol>
                            <IonCol>
                              <IonItem lines="none" class="ion-no-padding">
                                <IonInput
                                  class="ion-text-end"
                                  value={splitVal?.at(1)}
                                  onIonInput={(e) => {
                                    onValueChange(
                                      criteria,
                                      `${
                                        splitVal ? splitVal[0] + "," : ""
                                      }${String(e.target.value)}`
                                    );
                                  }}
                                ></IonInput>
                                <IonIcon
                                  slot="end"
                                  ios={searchOutline}
                                  md={searchSharp}
                                  onClick={async () => {
                                    setFilterModalCriteriaName(criteria);
                                    setFilterModalModuleName(
                                      queryField.moduleName
                                    );
                                    setFilterModalPairValue({
                                      value: splitVal?.at(0),
                                      placement: "front",
                                    });
                                    setIsFilterModalOpen(true);
                                  }}
                                ></IonIcon>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    </IonItemGroup>
                  );
                } else if (!!queryField) {
                  return (
                    <IonItem
                      key={`${criteria.ColumnName}-${criteria.TableName}`}
                    >
                      <IonLabel>{`${criteria.TranslatedIdText} ${criteria.ComparisonOperator}`}</IonLabel>
                      <IonInput
                        class="ion-text-end"
                        value={getCurrentValue(criteria)}
                        onIonInput={(e) => {
                          onValueChange(criteria, String(e.target.value));
                        }}
                      ></IonInput>
                      <IonIcon
                        slot="end"
                        ios={searchOutline}
                        md={searchSharp}
                        onClick={async () => {
                          setFilterModalCriteriaName(criteria);
                          setFilterModalModuleName(queryField.moduleName);
                          setIsFilterModalOpen(true);
                        }}
                      ></IonIcon>
                    </IonItem>
                  );
                }
                return (
                  <IonItem key={`${criteria.ColumnName}-${criteria.TableName}`}>
                    <IonLabel>{`${criteria.TranslatedIdText} ${criteria.ComparisonOperator}`}</IonLabel>
                    {criteria.DataType !== "DateTime" &&
                      (!criteria.isSearchableCriteriaColumn ||
                        criteria.ColumnName === "serial_id_installed" || // Temporarily making these input fields because dropdown is empty (Serial #, Spares)
                        criteria.ColumnName ===
                          "site_spares_holding_equip") 
                          && (
                        <IonInput
                          class="ion-text-end"
                          value={getCurrentValue(criteria)}
                          onIonInput={(e) => {
                            onValueChange(criteria, String(e.target.value));
                          }}
                        ></IonInput>
                      )}
                    {criteria.DataType === "DateTime" &&
                      !criteria.isSearchableCriteriaColumn &&
                      formDateTimes()}
                    {criteria.isSearchableCriteriaColumn &&
                      criteria.ComparisonOperator !== "in" && (
                        <IonSelect
                          placeholder={
                            translations["lbl_btn_select" || "Select"]
                          }
                          value={getCurrentValue(criteria)}
                          onIonChange={(e) => {
                            onValueChange(criteria, e.detail.value);
                          }}
                        >
                          {getValuesForSearchableColumn(
                            criteria.ColumnName
                          ).map((value) => {
                            return (
                              <IonSelectOption
                                key={value.Code}
                                value={value.Code}
                              >{`${value.Code} - ${value.Description}`}</IonSelectOption>
                            );
                          })}
                        </IonSelect>
                      )}
                    {criteria.isSearchableCriteriaColumn &&
                      criteria.ComparisonOperator === "in" && (
                        <SelectWithAllOption
                          title={`${criteria.TranslatedIdText} ${criteria.ComparisonOperator}`}
                          validValues={getValuesForSearchableColumn(
                            criteria.ColumnName
                          )}
                          comparisonExpression={getCurrentValue(criteria)}
                          onConfirm={(comparisonExpression) => {
                            onValueChange(criteria, comparisonExpression);
                          }}
                        />
                      )}
                  </IonItem>
                );
              })}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CriteriaModal;
