import React, { useContext, useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  useIonActionSheet,
} from "@ionic/react";
import MaterialIssueItem from "./MaterialIssueItem";
import { checkmarkCircleOutline, filter } from "ionicons/icons";
import { ItemListEntry, PlannedListFilterType } from "../pages/MaterialIssue";
import { TranslationsContext } from "../util/Translations";
import MaterialIssueComments from "./MaterialIssueComments";
import { matchesAvailableItemsNotFullyIssued, matchesItemNotFullyIssued } from "../util/IssueMaterialHelper";

interface MaterialIssueItemListProps {
  itemList: ItemListEntry[];
  onSelect: (checked: boolean, item: string, reqLine: number) => void;
  onSelectAll: (bins: string[]) => void;
  onBinAmountChange: (item: string, bin: string, amount: number, reqLine: number) => void;
  mode: "issue" | "return";
  onFilterChange?: (filter: PlannedListFilterType) => void;
  handleComment: (comment: string, item: string) => void;
  currentFilter?: PlannedListFilterType;
  handleRemoval?: (item: any) => void;
  negativeInvCtrlVal?: string;
}

const MaterialIssueItemList: React.FC<MaterialIssueItemListProps> = (props) => {
  const {
    itemList,
    onSelect,
    onSelectAll,
    onBinAmountChange,
    mode,
    onFilterChange,
    handleComment,
    currentFilter,
    handleRemoval,
    negativeInvCtrlVal,
  } = props;
  const { translations } = useContext(TranslationsContext);
  const [present] = useIonActionSheet();
  const [itemForComments, setItemForComments] = useState<string>();
  const [comments, setComments] = useState<string>();
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);

  const onFilterButtonClick = () => {
    if (!onFilterChange) {
      return;
    }
    present({
      header: `${translations["lbl_filter"]} - ${currentFilter}`,
      buttons: [
        {
          text: PlannedListFilterType.ItemsNotFullyIssued,
          handler: () => {
            onFilterChange(PlannedListFilterType.ItemsNotFullyIssued);
          },
        },
        {
          text: PlannedListFilterType.All,
          handler: () => {
            onFilterChange(PlannedListFilterType.All);
          },
        },
        {
          text: PlannedListFilterType.AvailableItemsNotFullyIssued,
          handler: () => {
            onFilterChange(PlannedListFilterType.AvailableItemsNotFullyIssued);
          },
        },
      ],
    });
  };

  const handleCommentButton = (comment: string, item: string) => {
    setComments(comment);
    setItemForComments(item);
    setCommentsModalOpen(true);
  };
  const handleCommentModalClose = () => {
    setCommentsModalOpen(false);
    setComments(undefined);
    setItemForComments(undefined);
  };
  const handleCommentsSave = () => {
    if (itemForComments) {
      handleComment(comments || "", itemForComments);
    }
    handleCommentModalClose();
  };

  const matchesFilter = (entry: ItemListEntry, currentFilter?: PlannedListFilterType) => {
    if (mode !== "issue" || entry.isAdhoc || currentFilter === PlannedListFilterType.All) {
      return true;
    }
    return matchesItemNotFullyIssued(entry, currentFilter) || matchesAvailableItemsNotFullyIssued(entry, currentFilter);
  };

  const onSelectAllClick = () => {
    const filteredBins = itemList.filter((entry) => {
      return matchesFilter(entry);
    });
    onSelectAll(filteredBins.map((bin) => bin.item?.Bin));
  };

  return (
    <>
      {/* Comments Modal */}
      <MaterialIssueComments
        isOpen={commentsModalOpen}
        onSave={handleCommentsSave}
        onCancel={handleCommentModalClose}
        onChange={setComments}
        comments={comments}
      />

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText>
              <h3 className="ion-no-margin">
                {translations["lbl_item_list"] || "Item List"}
              </h3>
            </IonText>
          </IonCol>

          <IonCol size="auto">
            <IonButton fill="clear" size="small" onClick={onSelectAllClick}>
              <IonIcon
                slot="start"
                icon={checkmarkCircleOutline}
                size="small"
              />
              {translations["lbl_btn_selAll"] || "Select All"}
            </IonButton>
          </IonCol>
          {mode === "issue" && (
            <IonCol size="auto">
              <IonButton
                fill="clear"
                size="small"
                onClick={onFilterButtonClick}
              >
                <IonIcon slot="start" icon={filter} size="small" />
                {translations["lbl_filter"] || "Filter"}
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
      {itemList.map((entry, index) => {
        const onItemSelect = (checked: boolean) => {
          onSelect(checked, entry.item.Item, entry.item.RequisitionLine);
        };
        const onAmountChange = (bin: string, amount: number) => {
          onBinAmountChange(entry.item.Item, bin, amount, entry.item.RequisitionLine);
        };
        // Only render filtered items
        if (!matchesFilter(entry, currentFilter)) {
          return undefined;
        }
        return (
          <MaterialIssueItem
            key={index}
            item={entry.item}
            onSelect={onItemSelect}
            selected={entry.checked}
            onBinAmountChange={onAmountChange}
            binData={entry.binData}
            issued={entry.issued}
            issueError={entry.errorMessage}
            mode={mode}
            comments={entry.comments}
            handleCommentButton={handleCommentButton}
            handleRemoval={handleRemoval}
            negativeInvCtrlVal={negativeInvCtrlVal}
          />
        );
      })}
    </>
  );
};

export default MaterialIssueItemList;
