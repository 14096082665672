import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonModal,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useContext } from "react";
import { maxResults, useQueryExec } from "../hooks/useQueryExec";
import EquipmentResults from "./EquipmentResults";
import QueryFilter from "./QueryFilter";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import SearchFooter from "./SearchFooter";

interface EquipmentSearchProps {
  equipmentClick?: (arg0?: any) => void;
  modalProps?: {
    isOpen: boolean;
    onModalCancel: () => void;
    onModalConfirm: (equipmentId: string) => void; // get Equipment
  };
  woNumber?: string; // If in use on the work order page
  equipmentChange?: boolean;
}

const EquipmentSearch: React.FC<EquipmentSearchProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { equipmentClick, modalProps, woNumber, equipmentChange } = props;
  const {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    showLoading,
    setShowLoading,
    showToast,
    setShowToast,
    queryRows,
    setQueryRows,
    executeSearch,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    getNoOfFieldsToDisplay,
    searchYn,
    setSearchYn,
    queryName,
    setQueryName,
    criteriaParams,
    confirmSearch,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Equipment", "Equipment", "equipment");

  const querySection = "QW--Equipment";

  const onCancel = () => {
    // Clear modal data on cancel
    setQueryName("");
    setQueryRows([]);
    modalProps?.onModalCancel();
  };

  const buildSearchComponents = () => {
    return (
      <>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={5000}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={
            translatedMessages["DisplayFirst"]?.MessageText.replace(
              "@1",
              maxResults
            )
              .replace(
                "@2",
                translations["lbl_category_equipment"] || "Equipment"
              )
              .replace("@3", totalCount) ||
            `Displaying the first ${maxResults} equipment of ${totalCount}.`
          }
          duration={2000}
          position="top"
        />
        <QueryFilter
          setShowLoading={setShowLoading}
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
          getFilterParams={getFilterParams}
          filterParams={filterParams}
          setParameter={setParameter}
          queryCriteria={queryCriteria}
          getQueryCriteria={getQueryCriteria}
          searchCallback={confirmSearch}
        />
        <EquipmentResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          queryParams={filterParams}
          criteria={criteriaParams}
          queryRows={queryRows}
          setQueryRows={setQueryRows}
          filterParams={filterParams}
          setParameter={setParameter}
          executeSearch={executeSearch}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          woNumber={woNumber}
          equipmentClick={equipmentClick}
          modalProps={modalProps}
          filterRows={filterRows}
          filterMethod={execFilter}
          filterString={resultsFilter}
          equipmentChange={equipmentChange}
        />
      </>
    );
  };
  if (!!modalProps) {
    return (
      <IonModal isOpen={modalProps.isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                {translations["lbl_btn_cancel"] || "Cancel"}
              </IonButton>
            </IonButtons>
            <IonTitle>
              {translations["lbl_category_equipment"] || "Equipment"}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>{buildSearchComponents()}</IonContent>
        <SearchFooter totalRows={totalCount} filterRows={filterRows} />
      </IonModal>
    );
  }
  return (
    <>
      <IonContent style={{ flex: "auto" }}>
        {buildSearchComponents()}
      </IonContent>
      <SearchFooter totalRows={totalCount} filterRows={filterRows} />
    </>
  );
};

export default EquipmentSearch;
