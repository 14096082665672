import { PlantCurrency } from "../models/plant/Plant";

export const  getFormattedValue = (type: string, value: string) => {
    if (type !== null && value !== null) {
        if (type === "DateTime") {
            return formatDateUIDate(value);
        }
    }
    return value;
}

export const formatDateUIDate = (dateStr: string | number | undefined): string => {
    if (dateStr === undefined) {
        return ""
    }
    return new Date(dateStr).toLocaleDateString();
}

export const formatCurrencyUICurrency = (currStr: number | undefined, currency: PlantCurrency | undefined) => {
    if (currStr === undefined || currency === undefined) {
        return "";
    }

    //moved magic numbers to constants.
    const DEFAULT_DIGITS_AFTER_DECIMAL = 2;
    const DEFAULT_DIGITS_IN_GROUP = 3;
    const DEFAULT_DECIMAL_SEPARATOR_LENGTH = 1;
    
    let sign = currStr < 0;
    currStr = Math.abs(currStr);
    let temp = currStr.toFixed(currency.DigitsAfterDecimal || DEFAULT_DIGITS_AFTER_DECIMAL);
    if (currency.DecimalSeparator) {
        temp = temp.replace(".", currency.DecimalSeparator);
    }
    let a = Math.floor((temp.length - (currency.DigitsAfterDecimal + (currency.DecimalSeparator.length || DEFAULT_DECIMAL_SEPARATOR_LENGTH) || DEFAULT_DIGITS_AFTER_DECIMAL + DEFAULT_DECIMAL_SEPARATOR_LENGTH)) / (currency.DigitsInGroup || DEFAULT_DIGITS_IN_GROUP));
    let startingPoint = temp.indexOf(currency.DecimalSeparator || ".");
    for (let i = 0; i < a; i++) {
        startingPoint -= currency.DigitsInGroup || DEFAULT_DIGITS_IN_GROUP;
        temp = temp.slice(0, startingPoint) + (currency.DigitGroupingSymbol || ",") + temp.slice(startingPoint, temp.length);
    }
    temp = (currency.CurrencySymbol || "$") + temp;
    return sign ? "-" + temp : temp;
}