import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonGrid,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  RefresherEventDetail,
} from "@ionic/react";
import { useContext } from "react";
import { WorkOrder } from "../models/workorders/WorkOrder";
import { TranslationsContext } from "../util/Translations";

interface WOContentData {
  handleRefresh: (event: CustomEvent<RefresherEventDetail>) => void;
  inRefresh: boolean;
  showLoading: boolean;
  setShowLoading: (showLoading: boolean) => void;
  workOrder?: WorkOrder;
}

const WOContent: React.FC<WOContentData> = (props) => {
  const { handleRefresh, inRefresh, showLoading, setShowLoading, workOrder } =
    props;
  const { translations } = useContext(TranslationsContext);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading && !inRefresh}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>{workOrder?.Description}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol class="col-header">{`${
                translations["lbl_category_equipment"] || "Equipment"
              }:`}</IonCol>
              <IonCol class="col-header">{workOrder?.Equipment}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="col-header">{`${
                translations["lbl_description"] || "Description"
              }:`}</IonCol>
              <IonCol class="col-header">
                {workOrder?.EquipmentMaster?.Description}
              </IonCol>
            </IonRow>
            <IonRow hidden={workOrder?.SerialId === null}>
              <IonCol class="col-header">{`${
                translations["lbl_eqserial_serialid_item"] || "Serial ID"
              }:`}</IonCol>
              <IonCol class="col-header">{workOrder?.SerialId}</IonCol>
            </IonRow>
            <IonRow hidden={workOrder?.SerialDescription === null}>
              <IonCol class="col-header">{`${
                translations["lbl_description"] || "Description"
              }:`}</IonCol>
              <IonCol class="col-header">{workOrder?.SerialDescription}</IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default WOContent;
