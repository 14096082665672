import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLoading,
  IonRow,
  IonText,
} from "@ionic/react";
import { send } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeParam } from "../util/ApiHelper";
import "./Remarks.css";
import { RemarksCollection } from "../models/remarks/RemarksCollection";
import { RemarkSubmissionData } from "../models/remarks/RemarkSubmissionData";
import { RemarkSubmission } from "../models/remarks/RemarkSubmission";
import { appInsights } from "../util/AppInsights";
import { getEvents, putPartial } from "../api/Events";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";

const RemarksTab: React.FC = () => {
  appInsights.trackPageView({ name: "RemarksTab" });
  const { wOrderDate, wOrderTime } = useParams<{
    wOrderDate: string;
    wOrderTime: string;
  }>();
  let wDate = decodeParam(wOrderDate);
  let wTime = decodeParam(wOrderTime);
  let commentField: HTMLInputElement = document.getElementById(
    "comment-input"
  ) as HTMLInputElement;

  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [remarksErrorState, setRemarksErrorState] = useState(false);
  const [hasRemarks, setHasRemarks] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<any[]>([]);
  const [newComment, setNewComment] = useState<boolean>(false);
  const [showFetching, setShowFetching] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  function submitNewCommentClick() {
    const currentDate = new Date();
    const commentDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
    const commentSubmissionData = new RemarkSubmissionData(
      commentField.value,
      commentDate
    );
    const submission = new RemarkSubmission(wDate, wTime);
    try {
      setShowFetching(true);
      setIsSubmitDisabled(true);
      submission.Updates.push(commentSubmissionData);

      putPartial(submission)
        .then((response) => {
          if (response.status !== 200) {
            setRemarksErrorState(true);
          } else {
            commentField.value = "";
            setNewComment(true);
          }
        })
        .finally(() => {
          setShowFetching(false);
        });
    } catch (e) {
      appInsights.trackEvent({
        name:
          translatedMessages["CommentSaveError"]?.MessageText ||
          "Error saving comment.",
        properties: {
          error: e,
          wDate: wDate,
          wTime: wTime,
          comment: submission,
        },
      });
    }
  }

  function onCommentChange(event: any) {
    setIsSubmitDisabled(true);
    const input = event.target as HTMLInputElement;
    if (input.value.length > 0) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }

  useEffect(() => {
    setNewComment(false);
    setShowFetching(true);
    getEvents({
      comment: true,
      completion: false,
      dateCreated: wDate,
      timeCreated: wTime,
    })
      .then((response) => {
        if (response.status !== 200) {
          setRemarksErrorState(true);
        } else {
          const remarksPath = response.data?.Comment?.Remarks;
          setHasRemarks(remarksPath?.length > 0);
          setRemarks(RemarksCollection.fromString(remarksPath));
        }
      })
      .catch((error) => {
        appInsights.trackEvent({
          name:
            translatedMessages["ErrRetrievingComment"]?.MessageText ||
            "Error retrieving Comments information",
          properties: {
            error: error,
            wDate: wDate,
            wTime: wTime,
            comment: "true",
            completion: "true",
          },
        });
      })
      .finally(() => setShowFetching(false));
  }, [newComment]);

  return (
    <>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showFetching}
        onDidDismiss={() => setShowFetching(false)}
        duration={5000}
      />
      <IonGrid class="comments-ion-grid">
        <IonRow class="comment-add-new">
          <IonCol>
            <IonInput
              id="comment-input"
              placeholder={translations["lbl_add_comment"] || "Add a comment"}
              onIonChange={onCommentChange}
              clearInput
            ></IonInput>
          </IonCol>
          <IonCol size="auto">
            <IonButton
              id="comment-btn"
              disabled={isSubmitDisabled}
              fill="clear"
              onClick={submitNewCommentClick}
            >
              <IonIcon
                id="send-icon"
                slot="icon-only"
                size="large"
                icon={send}
              ></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
        {hasRemarks ? (
          remarks.map((item, index) => {
            return (
              <>
                <IonRow key={index} class="comment-listing">
                  <IonCol size="auto">
                    <IonAvatar class="remarks-avatar">
                      <span>{item.user?.charAt(0).toUpperCase()}</span>
                    </IonAvatar>
                  </IonCol>
                  <IonCol>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="auto" class="comment-listing-user">
                          {item.user}
                        </IonCol>
                        <IonCol size="auto" class="ion-text-right">
                          {item.date}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="auto" class="comment-listing-comment">
                          {item.comment}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              </>
            );
          })
        ) : (
          <IonText
            color={remarksErrorState ? "danger" : "default"}
            style={{ padding: "16px", display: "inline-block" }}
          >
            {remarksErrorState
              ? `${
                  translatedMessages["ErrRetrievingComment"]?.MessageText ||
                  "Error retrieving Comments information"
                }`
              : `${
                  translatedMessages["NoResultsFound"]?.MessageText ||
                  "No results found."
                }`}
          </IonText>
        )}
      </IonGrid>
    </>
  );
};

export default RemarksTab;
