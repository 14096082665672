import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import { encodeParam } from "../util/ApiHelper";

interface StoresPopoverParams {
  popover: React.RefObject<HTMLIonPopoverElement>;
  popoverOpen: boolean;
  setPopoverOpen: (popoverOpen: boolean) => void;
  searchResults: string[];
  setSearchResults: (searchResults: string[]) => void;
  searchValue: string;
  setSearchValue: (setSearchValue: string) => void;
  querySection?: string;
  storesTab: string;
}

const StoresPopover: React.FC<StoresPopoverParams> = (props) => {
  const {
    popover,
    popoverOpen,
    setPopoverOpen,
    searchResults,
    setSearchResults,
    searchValue,
    setSearchValue,
    querySection,
    storesTab,
  } = props;

  return (
    <IonPopover
      keyboardClose={false}
      isOpen={popoverOpen}
      dismissOnSelect
      onDidDismiss={() => {
        setPopoverOpen(false);
        setSearchResults([]);
      }}
      alignment="center"
      ref={popover}
      arrow
    >
      <IonContent>
        <IonList>
          {searchResults.map((result: string) => {
            const formatResult = () => {
              let position = result
                .toLowerCase()
                .indexOf(searchValue.toLowerCase());
              return (
                <span>
                  {result.slice(0, position)}
                  <b>{result.slice(position, position + searchValue.length)}</b>
                  {result.slice(position + searchValue.length, result.length)}
                </span>
              );
            };
            return (
              <IonItem
                routerLink={
                  querySection === "QW--Work Order"
                    ? `/workorder/${encodeParam(result)}/${storesTab}material`
                    : `/Stores/Requisitions/${encodeParam(
                        result
                      )}/${storesTab}`
                }
                onClick={() => {
                  setPopoverOpen(false);
                  setSearchResults([]);
                  setSearchValue("");
                }}
              >
                <IonLabel>{formatResult()}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default StoresPopover;
